import React, { forwardRef, useImperativeHandle, useState } from "react"
import { Button } from "reactstrap"
import { useTranslation } from "react-i18next"
import Swal from "sweetalert2"

const ButtonRenderer = forwardRef((props, ref) => {
  const { t } = useTranslation()

  const [isHaveDetail, setIsDetail] = useState(props.value)

  const button = isSend => {
    if (isHaveDetail === true) {
      return (
        <>
          <Button
            color="success"
            onClick={async () => {
              props.setItem(props.data)
              props.toggle()
            }}
          >
            {t("View")}
          </Button>
        </>
      )
    }
    // else if (isHaveDetail === false) {
    //   return (
    //     <>
    //       <Button
    //         onClick={() => {
    //           props.setItem(props.data)
    //           props.toggle()
    //         }}
    //       >
    //         {t("View image")}
    //       </Button>
    //     </>
    //   )
    // }
  }

  useImperativeHandle(ref, () => {
    return {
      refresh(params) {
        setIsDetail(params.value)
      }
    }
  })
  return <>{button(isHaveDetail)}</>
})

ButtonRenderer.displayName = "MyImageRenderer"

export default ButtonRenderer
