import { Col, Row, Modal, ModalBody, Card, CardBody, Container } from "reactstrap"
import React from "react"
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import withRouter from "components/Common/withRouter"

const ModalEvisa = props => {
  let { item, isOpen, toggle } = props

  return (
    <React.Fragment>
      <Modal size="xl" isOpen={isOpen} toggle={toggle} className="modal-dialog">
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
            {props.t("Detail")}
          </h5>
          <button
            onClick={() => {
              toggle()
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>
          <Container>
            <Row>
              <Col xl="12">
                <Card className="w-100">
                  <CardBody className="d-flex justify-content-between p-0">
                    <Col lg="2">
                      <Card className="">
                        <h4 className="pb-3">{props.t("Portrait")}:</h4>
                        <img
                          className="rounded-3 img-fluid"
                          src={item?.payload?.anh_chan_dung_url}
                          alt="Passport Image"
                        />
                      </Card>
                    </Col>
                    <Col lg="7" className="ps-3">
                      <Card className="">
                        <h4 className="pb-3">{props.t("Passport photo")}:</h4>
                        <img
                          className="rounded-3 img-fluid"
                          src={item?.payload?.anh_ho_chieu_url}
                          alt="Passport Image"
                        />
                      </Card>
                    </Col>
                    <Col lg="3" className="ps-4">
                      <h4 className="pb-3"> {props.t("Customer information")}:</h4>
                      <h5>
                        <b> {props.t("Fullname")}: </b> {item?.ho_ten}
                      </h5>
                      <h5>
                        <b>{props.t("Date of birth")}: </b> {item.dob}
                      </h5>
                      <h5>
                        <b>{props.t("Gender")}: </b>{" "}
                        {item?.payload?.gioi_tinh === "M" ? props.t("Male") : props.t("Female")}
                      </h5>
                      <h5>
                        <b>{props.t("Passport number")}: </b> {item?.so_ho_chieu}
                      </h5>
                      <h5>
                        <b>{props.t("Nationality")}: </b> {item?.quoc_tich}
                      </h5>
                      <h5>
                        <b>{props.t("Date of Entry")}: </b> {item?.cap_thi_thuc_tu_ngay}
                      </h5>
                      <h5>
                        <b>{props.t("Departure Date")}: </b> {item?.cap_thi_thuc_den_ngay}
                      </h5>
                      <h5>
                        <b>{props.t("Number of days of stay")}: </b> {item?.stayIn} {props.t("days")}{" "}
                      </h5>
                      <h5>
                        <b>{props.t("Entry purpose")}: </b> {item?.visaType}
                      </h5>
                      <h5>
                        <b>{props.t("Value of visa")}: </b> {item.multipleEntry_title}
                      </h5>
                      <h5>
                        <b>{props.t("Urgent")}: </b> {item?.fee?.title_vn}{" "}
                      </h5>
                      <h5>
                        <b>Vip: </b> {item.vip === true ? props.t("Yes") : props.t("No")}{" "}
                      </h5>
                      <h5>
                        <b>{props.t("Email")}: </b> {item?.payload?.email}{" "}
                      </h5>
                      <h5>
                        <b>{props.t("Customer segmentation")}: </b> {item?.phan_tach_khach_hang}{" "}
                      </h5>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

ModalEvisa.propTypes = {
  t: PropTypes.any
}

export default withRouter(withTranslation()(ModalEvisa))
