import { Row, Container, Form } from "reactstrap"

import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"

//Breadcrumbs
import Breadcrumb from "components/Common/Breadcrumb"

//Modal
import Modal from "./Modal"

//Ag-grid
import { AgGridReact } from "ag-grid-react" // React Grid Logic
import "ag-grid-enterprise"
import { LicenseManager } from "ag-grid-enterprise"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-alpine.css"

import AgButtonDVC from "./AgCellRendering/AgButtonDvc"
import AgText from "./AgCellRendering/AgText"
import AgUrgentText from "./AgCellRendering/AgUrgentText"

//i18n
import { withTranslation } from "react-i18next"
import withRouter from "components/Common/withRouter"
//formik
import * as Yup from "yup"
import { useFormik } from "formik"

//services
import { ToolCheckEvisa } from "services"
import Swal from "sweetalert2"

//config
import { AG_GRID_KEY } from "configs"

//responsive grid
import { getHeight } from "helpers/data_grid_helper"

LicenseManager.setLicenseKey(AG_GRID_KEY)

const ModalSubmitNew = props => {
  const [data, setData] = useState([])
  const gridRef = useRef()
  const [heightTable, setHeightTable] = useState(500)
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    if (isOpen) {
      setIsOpen(false)
    } else {
      setIsOpen(true)
    }
  }

  const validationSubmit = useFormik({
    enableReinitialize: true,
    initialValues: {
      ma_ho_so: ""
    },
    validationSchema: Yup.object({
      ma_ho_so: Yup.string()
        .required()
        .test("check_mahoso", value => {
          if (!value) return true
          let regex = /E.{0,20}/
          return value.match(regex)
        })
    }),
    onSubmit: (values, { resetForm }) => {}
  })

  /**Start ag grid config */
  let gridStyle = { minHeight: 750, marginTop: 10 }
  if (heightTable) {
    gridStyle.minHeight = "auto"
    gridStyle.height = heightTable + "px"
  }
  useEffect(() => {
    const height = getHeight(62)
    setHeightTable(height)
  }, [])

  window.addEventListener("resize", function () {
    const height = getHeight(62)
    setHeightTable(height)
  })

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 150,
      cellStyle: { display: "flex", justifyContent: "center", alignItems: "center" }
    }
  }, [])
  const columnDefs = [
    {
      headerName: props.t("Mã hồ sơ"),
      field: "ma_ho_so",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true,
      pinned: "left",
      minWidth: 280
    },
    {
      headerName: props.t("Profile status"),
      field: "trang_thai_ho_so",
      resizable: true,
      sortable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      pinned: "left",
      cellRendererSelector: params => {
        const detail = {
          component: AgText
        }
        if (params.data) {
          return detail
        }
      }
    },
    {
      headerName: props.t("Số thị thực"),
      field: "soTT",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true
    },
    {
      headerName: props.t("Fullname"),
      field: "fullname",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true
    },
    {
      headerName: props.t("Date of birth"),
      field: "ngay_sinh",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true
    },
    {
      headerName: props.t("Passport number"),
      field: "so_ho_chieu",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true
    },
    {
      headerName: props.t("Urgent"),
      field: "urgent_title",
      resizable: true,
      floatingFilter: true,
      filter: "agMultiColumnFilter",
      sortable: true,
      cellRendererSelector: params => {
        const text = {
          component: AgUrgentText
        }
        if (params.data) {
          return text
        }
      }
    },
    {
      headerName: props.t("Nationality"),
      field: "quoc_tich",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true
    },
    {
      headerName: props.t("Date of Entry"),
      field: "cap_thi_thuc_tu_ngay",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true
    },
    {
      headerName: props.t("Departure Date"),
      field: "cap_thi_thuc_den_ngay",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true
    },
    {
      headerName: props.t("Lý do bổ túc"),
      field: "ly_do_bo_tuc",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true
    },
    {
      headerName: props.t("Detail"),
      field: "link_ket_qua",
      resizable: true,
      cellRendererSelector: params => {
        const detail = {
          component: AgButtonDVC
        }
        if (params.data) {
          return detail
        }
      },
      maxWidth: 150
    }
  ]
  /**End ag grid config */

  const handleCheckMahoso = () => {
    try {
      toggle()
      if (data.length > 0) {
        let so_ho_so = ""
        data.map((item, index) => {
          so_ho_so += item.ma_ho_so
          if (index < data.length - 1) {
            so_ho_so += ","
          }
        })
        Swal.fire({
          title: props.t("Processing"),
          icon: "warning",
          text: props.t("Please waitting..."),
          showConfirmButton: false
          // allowOutsideClick: false
        })
        ToolCheckEvisa({ ma_ho_so: so_ho_so })
          .then(res => {
            Swal.fire({
              title: props.t("Success"),
              icon: "success",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: false
            })
            let temp = []
            let result = res.data.data
            result.map((item, index) => {
              temp.push({
                ma_ho_so: item.maHS,
                trang_thai_ho_so: item.trangThai,
                cap_thi_thuc_tu_ngay: item.tuNgay,
                cap_thi_thuc_den_ngay: item.denNgay,
                ly_do_bo_tuc: item.lyDoBoTuc,
                soTT: item.soTT,
                quoc_tich: item.data.quoc_tich,
                ngay_sinh: item.data.ngay_sinh,
                urgent_title: item.data.fee.title_vn,
                so_ho_chieu: item.data.so_ho_chieu,
                fullname: item.data.ho_ten
              })
            })
            setData(temp)
          })
          .catch(err => {
            let msg_return = ""
            if (err?.response?.data) {
              let data = err.response.data
              let msg = data.msg
              if (msg !== "") {
                switch (msg) {
                  case "Data not exist":
                    msg_return = "Dữ liệu nằm ngoài hệ thống"
                    break
                  case "Error when request":
                    msg_return = "Hệ thống check eVisa lỗi"
                    break
                  default:
                    msg_return = "Lỗi không xác định"
                    break
                }
              } else {
                msg = err
              }
            }
            Swal.fire({
              title: props.t("Error"),
              icon: "error",
              text: msg_return,
              showConfirmButton: false,
              timer: 3000
            })
          })
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (validationSubmit.values.ma_ho_so !== "") {
      let values = validationSubmit.values.ma_ho_so
      values.replace(",", "\n")
      let value = values.split("\n")
      let temp = []
      value.map((item, index) => {
        temp.push({
          ma_ho_so: item
        })
      })
      setData(temp)
    } else {
      setData([])
    }
  }, [validationSubmit.values.ma_ho_so])

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agFilteredRowCountComponent", align: "left" },
        { statusPanel: "agAggregationComponent", align: "left" }
      ]
    }
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title={props.t("Tool")} breadcrumbItem={props.t("Check status EVISA")} />
          <Row>
            <Form
              onSubmit={e => {
                e.preventDefault()
                validationSubmit.handleSubmit()
                return false
              }}
            >
              <Modal
                isOpen={isOpen}
                toggle={toggle}
                validationSubmit={validationSubmit}
                handleCheckMahoso={handleCheckMahoso}
              />
              <div className="d-flex justify-content-between mb-3 js-height">
                <button className="btn btn-secondary" type="button" onClick={toggle}>
                  {props.t("Check")}
                </button>
              </div>
            </Form>
          </Row>
          <div className="ag-theme-alpine" style={gridStyle}>
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowData={data}
              // rowSelection={"multiple"}
              suppressRowClickSelection={true}
              colResizeDefault={true}
              pagination={true}
              // enableRangeSelection={true}
              // onFirstDataRendered={params => {
              //   params.columnApi.autoSizeAllColumns()
              // }}
              enableCellChangeFlash={true}
              statusBar={statusBar}
            />
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

ModalSubmitNew.propTypes = {
  t: PropTypes.any
}

export default withRouter(withTranslation()(ModalSubmitNew))
