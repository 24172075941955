import React, { forwardRef, useImperativeHandle, useState } from "react"
import { Button } from "reactstrap"
import { useTranslation } from "react-i18next"
import { GetPassportByMaHoSo } from "services"

const ButtonRenderer = forwardRef((props, ref) => {
  const { t } = useTranslation()

  const [haveDetail, setHaveDetail] = useState(props.value)

  useImperativeHandle(ref, () => {
    return {
      refresh(params) {}
    }
  })
  return (
    <>
      {haveDetail && (
        <Button
          color={props.color}
          onClick={() => {
            window.open(props.value)
          }}
        >
          {t("Link DVC")}
        </Button>
      )}
    </>
  )
})

ButtonRenderer.displayName = "MyImageRenderer"

export default ButtonRenderer
