import React, { forwardRef, useImperativeHandle, useState } from "react"
import { Button } from "reactstrap"
import { useTranslation } from "react-i18next"
import { CancelDVCHoSo } from "services"
import Swal from "sweetalert2"

const ButtonRenderer = forwardRef((props, ref) => {
  const { t } = useTranslation()
  const gridRef = props.gridRef
  const [haveCancel, sethaveCancel] = useState(props.value === "Không tồn tại" ? true : false)

  useImperativeHandle(ref, () => {
    return {
      refresh(params) {}
    }
  })

  async function cancel() {
    let item = props.data
    CancelDVCHoSo({ so_ho_so: item.so_ho_so })
      .then(res => {
        Swal.fire({
          title: "Thành công",
          icon: "success",
          text: "Hồ sơ đã xoá thành công",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true
        })
        gridRef.current.api.forEachNode(rowNode => {
          if (item._id === rowNode.data._id) {
            let updated = rowNode.data
            updated.trang_thai_ho_so = "Đã xoá"
            updated.da_xu_ly_file = false
            sethaveCancel(false)
          }
        })
      })
      .catch(err => {
        Swal.fire({
          title: "Lỗi",
          icon: "error",
          // text: `Hồ sơ ${values.so_ho_so} đã được tạo`,
          showConfirmButton: false
        })
      })
  }

  return (
    <>
      {haveCancel && (
        <Button
          color={props.color}
          onClick={async () => {
            await cancel()
          }}
        >
          {t("Delete")}
        </Button>
      )}
    </>
  )
})

ButtonRenderer.displayName = "MyImageRenderer"

export default ButtonRenderer
