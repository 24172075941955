import React, { forwardRef, useImperativeHandle, useState } from "react"
import { Button } from "reactstrap"
import { useTranslation } from "react-i18next"
import Swal from "sweetalert2"

const ButtonRenderer = forwardRef((props, ref) => {
  const { t } = useTranslation()

  const [isSend, setIsSend] = useState(props.value)

  const button = isSend => {
    if (isSend === t("Unsent")) {
      return (
        <>
          <Button
            color="success"
            onClick={async () => {
              props.setItem(props.data)
              let passport_img_check = await props.checkImage(props.data.passport_img)
              if (passport_img_check === false) {
                Swal.fire({
                  title: "",
                  text: "System is re-downloading image",
                  icon: "warning",
                  showCancelButton: false,
                  showConfirmButton: false,
                  allowOutsideClick: false
                })
                await props.redownImage(props.data.passport_img)
                //auto open modal when success
              } else {
                props.toggle()
              }
            }}
          >
            {t("Send")}
          </Button>
        </>
      )
    } else if (isSend === t("Sent")) {
      return (
        <>
          <Button
            onClick={() => {
              props.setItem(props.data)
              props.toggle()
            }}
          >
            {t("View image")}
          </Button>
        </>
      )
    }
  }

  useImperativeHandle(ref, () => {
    return {
      refresh(params) {
        setIsSend(params.value)
      }
    }
  })
  return <>{button(isSend)}</>
})

ButtonRenderer.displayName = "MyImageRenderer"

export default ButtonRenderer
