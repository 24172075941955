import React, { useEffect, useState, createRef } from "react"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap"

//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//Import Select
import Select from "react-select"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// Import Image Editor
import "tui-image-editor/dist/tui-image-editor.css"
import ImageEditor from "@toast-ui/react-image-editor"
import { dataURItoBlob } from "../../../helpers/file_helper"

import test_image from "assets/images/anh-chan-dung.jpg"
import default_passport from "assets/images/default_passport_evisa.jpg"
import axios_formdata from "../../../services/axios/axios_formdata"

import diachi_nhapxuatcanh from "./options/cuakhaunhapcanh.json"
import quoctich from "./options/quoctich.json"
import gioitinh_option from "./options/gioitinh.json"
import loaihochieu from "./options/loaihochieu.json"
import yes_no from "./options/yes_no.json"
import mucdichnhapcanh from "./options/mucdichnhapcanh.json"
import giatrithithuc from "./options/giatrithithuc.json"
import thoigiandudinhcutru from "./options/thoigiandudinhcutru.json"
import nghenghiep from "./options/nghenghiep.json"
import nguoidambaokinhphi from "./options/nguoi_dam_bao_kinh_phi.json"

//import Yup and formik
import { useFormik } from "formik"
import * as Yup from "yup"

//import swal
import Swal from "sweetalert2"

import imageCompression from "browser-image-compression"

//config
import { API_URL, EMAIL_DOMAIN_EVISA } from "../../../configs"
import { STORAGE_KEY } from "../../../configs/app.const"

//services
import { GetRandomAddressEvisa, GetRandomFakeInfoEvisa, SendEvisaApi, GetUserFee } from "../../../services"

import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import Cookies from "universal-cookie"
// OptionOCR
import OptionOCR from "components/CommonForBoth/OptionOCR"
const Evisa = props => {
  //meta title
  document.title = "Hệ thống gửi hồ sơ xuất nhập cảnh | Evisa"
  const cookie = new Cookies()
  const [thongtinlienlac, setThongtinlienlac] = useState(false)
  const [thongtinnghenghiep, setThongtinnghenghiep] = useState(false)
  const [diachiovietnam, setDiachiovietnam] = useState(false)
  const [anhChanDung, setAnhChanDung] = useState(null)
  const [anhChanDungValue, setAnhChanDungValue] = useState(null)
  const [anhChanDungUrl, setAnhChanDungUrl] = useState(null)
  const [anhHoChieu, setAnhHoChieu] = useState(null)
  const [anhHoChieuValue, setAnhHoChieuValue] = useState(null)
  const [anhHoChieuUrl, setAnhHoChieuUrl] = useState(null)
  const [thoigiandudinhcutruValue, setThoigiandudinhcutruValue] = useState(thoigiandudinhcutru[0])
  const [dataOcr, setDataOcr] = useState()

  const [thanh_pho_label, setThanhPhoLabel] = useState("")
  const [quoc_tichLabel, setQuoc_tichLabel] = useState("China")
  const [quoc_tichValue, setQuoc_tichValue] = useState(quoctich[0])
  const [gioi_tinhValue, setGioi_tinhValue] = useState(gioitinh_option[0])
  const domain = EMAIL_DOMAIN_EVISA
  const [isSuccess, setSuccess] = useState(false)
  const [optionEmergency, setOptionEmergency] = useState([])

  const [modalImageEditorChanDung, setModalImageEditorChanDung] = useState(false)
  const [modalImageEditorHoChieu, setModalImageEditorHoChieu] = useState(false)

  const [disabledButtonEditChanDung, setDisabledButtonEditChanDung] = useState(true)
  const [disabledButtonEditHoChieu, setDisabledButtonEditHoChieu] = useState(true)

  const refImageEditorChanDung = createRef()
  const refImageEditorHoChieu = createRef()

  const whiteTheme = {
    "common.bi.image": "https://uicdn.toast.com/toastui/img/tui-image-editor-bi.png",
    "common.bisize.width": "251px",
    "common.bisize.height": "21px",
    "common.backgroundImage": "./img/bg.png",
    "common.backgroundColor": "#fff",
    "common.border": "1px solid #c1c1c1",

    // header
    "header.backgroundImage": "none",
    "header.backgroundColor": "transparent",
    "header.border": "0px",

    // load button
    "loadButton.backgroundColor": "#fff",
    "loadButton.border": "1px solid #ddd",
    "loadButton.color": "#222",
    "loadButton.fontFamily": "'Noto Sans', sans-serif",
    "loadButton.fontSize": "12px",

    // download button
    "downloadButton.backgroundColor": "#fdba3b",
    "downloadButton.border": "1px solid #fdba3b",
    "downloadButton.color": "#fff",
    "downloadButton.fontFamily": "'Noto Sans', sans-serif",
    "downloadButton.fontSize": "12px",

    // main icons
    "menu.normalIcon.color": "#8a8a8a",
    "menu.activeIcon.color": "#555555",
    "menu.disabledIcon.color": "#434343",
    "menu.hoverIcon.color": "#e9e9e9",
    "menu.iconSize.width": "24px",
    "menu.iconSize.height": "24px",

    // submenu icons
    "submenu.normalIcon.color": "#8a8a8a",
    "submenu.activeIcon.color": "#555555",
    "submenu.iconSize.width": "32px",
    "submenu.iconSize.height": "32px",

    // submenu primary color
    "submenu.backgroundColor": "transparent",
    "submenu.partition.color": "#e5e5e5",

    // submenu labels
    "submenu.normalLabel.color": "#858585",
    "submenu.normalLabel.fontWeight": "normal",
    "submenu.activeLabel.color": "#000",
    "submenu.activeLabel.fontWeight": "normal",

    // checkbox style
    "checkbox.border": "1px solid #ccc",
    "checkbox.backgroundColor": "#fff",

    // rango style
    "range.pointer.color": "#333",
    "range.bar.color": "#ccc",
    "range.subbar.color": "#606060",

    "range.disabledPointer.color": "#d3d3d3",
    "range.disabledBar.color": "rgba(85,85,85,0.06)",
    "range.disabledSubbar.color": "rgba(51,51,51,0.2)",

    "range.value.color": "#000",
    "range.value.fontWeight": "normal",
    "range.value.fontSize": "11px",
    "range.value.border": "0",
    "range.value.backgroundColor": "#f5f5f5",
    "range.title.color": "#000",
    "range.title.fontWeight": "lighter",

    // colorpicker style
    "colorpicker.button.border": "0px",
    "colorpicker.title.color": "#000"
  }
  useEffect(() => {
    let disabledButton = anhChanDung ? false : true
    setDisabledButtonEditChanDung(disabledButton)
  }, [anhChanDung])

  useEffect(() => {
    let disabledButton = anhHoChieu ? false : true
    setDisabledButtonEditHoChieu(disabledButton)
  }, [anhHoChieu])

  const handleSaveImageEditorChanDung = () => {
    const editorInstance = refImageEditorChanDung.current.getInstance()
    const data = editorInstance.toDataURL()
    var blob = dataURItoBlob(data)
    const file = new File([blob], anhChanDungValue?.name, {
      type: anhChanDungValue?.type,
      lastModified: new Date()
    })
    setAnhChanDung(data)
    setAnhChanDungValue(file)
    setModalImageEditorChanDung(false)
  }

  const handleSaveImageEditorHoChieu = () => {
    const editorInstance = refImageEditorHoChieu.current.getInstance()
    const data = editorInstance.toDataURL()
    var blob = dataURItoBlob(data)
    const file = new File([blob], anhHoChieuValue?.name, {
      type: anhHoChieuValue?.type,
      lastModified: new Date()
    })
    setAnhHoChieu(data)
    setAnhHoChieuValue(file)
    setModalImageEditorHoChieu(false)
  }

  const handleOnChangeAnhChanDung = e => {
    validationSubmitEvisa.handleChange(e)
    e.preventDefault()
    setAnhChanDungValue(e.target.files[0])
    const file = e.target.files[0]
    let reader = new FileReader()
    reader.onloadend = () => {
      setAnhChanDung(reader.result)
    }
    if (file) {
      reader.readAsDataURL(file)
    }
  }

  const handleOnChangeAnhHoChieu = e => {
    validationSubmitEvisa.handleChange(e)
    e.preventDefault()
    setAnhHoChieuValue(e.target.files[0])
    const file = e.target.files[0]
    let reader = new FileReader()
    reader.onloadend = () => {
      setAnhHoChieu(reader.result)
    }
    if (file) {
      reader.readAsDataURL(file)
    }
  }

  async function handleImageUpload(file) {
    const imageFile = file

    const options = {
      maxSizeMB: 1
      // maxWidthOrHeight: 1024
    }
    try {
      const compressedFile = await imageCompression(imageFile, options)
      return compressedFile
    } catch (error) {
      console.log(error)
    }
  }

  const handleOcrPassport = async () => {
    if (!anhChanDungValue) {
      Swal.fire({
        title: "Vui lòng chọn ảnh chân dung",
        icon: "error",
        timer: 5000
      })
      return
    }

    if (!anhHoChieuValue) {
      Swal.fire({
        title: "Vui lòng chọn ảnh hộ chiếu",
        icon: "error",
        timer: 5000
      })
      return
    }
    const formData = new FormData()
    const image = await handleImageUpload(anhHoChieuValue)
    formData.append("image", image, image.name)

    const formData_anhChanDung = new FormData()
    const image_anh_chan_dung = await handleImageUpload(anhChanDungValue)
    formData_anhChanDung.append("image", image_anh_chan_dung, image_anh_chan_dung.name)

    const getOptionOCR = JSON.parse(localStorage.getItem("isCheckOCR"))

    try {
      Swal.fire({
        title: "Đang xử lý",
        text: "",
        icon: "warning",
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false
      })
      const upload_anh_chan_dung = await axios_formdata.post(`${API_URL}/upload/anh_chan_dung`, formData_anhChanDung)
      if (upload_anh_chan_dung.data) {
        let data = upload_anh_chan_dung.data
        setAnhChanDungUrl(data.anh_chan_dung)
      }

      let response
      if (getOptionOCR === true || getOptionOCR === null) {
        response = await axios_formdata.post(API_URL + "/swifttechai", formData)
      } else {
        response = await axios_formdata.post(API_URL + "/mindee", formData)
      }

      if (response.data) {
        let data = response.data
        setAnhHoChieuUrl(data.imageUrl)
        setDataOcr(data)
        Swal.fire({
          title: "Success",
          text: "System OCR successfully!",
          icon: "success",
          timer: 2000 // Autoclose in 3 seconds
        })
      } else {
        throw new Error("Không thành công. Vui lòng thử lại")
      }
    } catch (error) {
      console.log(error)
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "error",
        timer: 5000
      })
    }
  }

  const converDate = date => {
    var day = date.getDate()
    var month = date.getMonth() + 1 // Months are zero-based
    var year = date.getFullYear()

    // Add leading zeros if day or month is less than 10
    if (day < 10) {
      day = "0" + day
    }
    if (month < 10) {
      month = "0" + month
    }

    return day + "/" + month + "/" + year
  }
  //form valid
  const validationSubmitEvisa = useFormik({
    initialValues: {
      ho: "",
      chu_dem_va_ten: "",
      quoc_tich: "",
      noi_sinh: "",
      ngay_thang_nam_sinh: "",
      gioi_tinh: "",
      anh_chan_dung: null,
      anh_ho_chieu: null,
      ngay_cap: "",
      ngay_het_han: "",
      so_cmnd: "",
      ton_giao: "Không",
      da_dung_ho_chieu_khac_nhap_canh_viet_nam: "Không",
      co_nhieu_quoc_tich_khong: "Không",
      co_ho_chieu_con_gia_tri_khac_khong: "Không",
      loai_ho_chieu: "PT",
      so_ho_chieu: "",
      co_quan_cap: "",
      muc_dich_nhap_canh: "2",
      muc_dich_nhap_canh_label: "du lịch",
      gia_tri_thi_thuc_dien_tu: "",
      thoi_gian_nhap_canh_tu_ngay: "",
      thoi_gian_nhap_canh_den_ngay: "",
      thoi_gian_du_dinh_cu_tru: "",
      cua_khau_du_dinh_nhap_canh: "SNB",
      cua_khau_du_dinh_xuat_canh: "SNB",
      ngay_du_dinh_nhap_canh: "",
      co_quan_du_kien_lien_he_khi_vao_viet_nam: "Không",
      nghe_nghiep_hien_tai: "Nhân viên",
      ten_cong_ty: "",
      chuc_vu: "Nhân viên",
      dia_chi_co_quan: "",
      so_dien_thoai_co_quan: "",
      dia_chi_cu_tru_tai_viet_nam_tinh_tp: "",
      dia_chi_cu_tru_tai_viet_nam_quan_huyen: "",
      dia_chi_cu_tru_tai_viet_nam_phuong_xa: "",
      dia_chi_cu_tru_tai_viet_nam_chi_tiet: "",
      dia_chi_cu_tru_tai_viet_nam_so_dien_thoai: "",
      checkbox_cam_doan_khai_bao_tam_tru: "1",
      da_tung_den_viet_nam_trong_1_nam: "Không",
      co_nguoi_than_o_viet_nam: "Không",
      kinh_phi_du_tinh: "2000",
      nguoi_dam_bao_kinh_phi_chuyen_di: "CN",
      da_mua_bao_hiem_chua: "Không",
      hinh_thuc_chi_tra: "Tiền mặt",
      dia_diem_de_nghi: "",
      checkbox_xin_cam_doan: "1",
      email: "",
      otp: "",
      so_dien_thoai: "",
      dia_chi_lien_lac: "",
      noi_o_hien_nay: "",
      lien_lac_khan_cap_ho_va_ten: "",
      lien_lac_khan_cap_noi_o_hien_nay: "",
      lien_lac_khan_cap_so_dien_thoai: "",
      lien_lac_khan_cap_quan_he: "",
      urgent: "1",
      ma_doi_tac: cookie.get(STORAGE_KEY.madoitac),
      phan_tach_khach_hang: "",
      vip: 0
    },
    validationSchema: Yup.object({
      ho: Yup.string().required("Vui lòng nhập họ"),
      chu_dem_va_ten: Yup.string().required("Vui lòng nhập chữ đệm và tên"),
      // noi_sinh: Yup.string().required("Vui lòng nhập nơi sinh"),
      anh_chan_dung: Yup.mixed().required("Vui lòng chọn ảnh"),
      anh_ho_chieu: Yup.mixed().required("Vui lòng chọn ảnh"),
      quoc_tich: Yup.string().required("Vui lòng chọn quốc tịch"),
      gioi_tinh: Yup.string().required("Vui lòng chọn giới tính"),
      ngay_thang_nam_sinh: Yup.string().required("Vui lòng chọn ngày tháng năm sinh"),
      ngay_cap: Yup.string().required("Vui lòng chọn ngày cấp"),
      ngay_het_han: Yup.string().required("Vui lòng chọn ngày hết hạn"),
      so_cmnd: Yup.string().required("Vui lòng nhập số CCCD"),
      loai_ho_chieu: Yup.string().required("Vui lòng chọn loại hộ chiếu"),
      so_ho_chieu: Yup.string().required("Vui lòng nhập số hộ chiếu"),
      co_quan_cap: Yup.string().required("Vui lòng nhập cơ quan nơi cấp"),
      muc_dich_nhap_canh: Yup.string().required("Vui lòng chọn"),
      thoi_gian_nhap_canh_tu_ngay: Yup.string().required("Vui lòng điền thời gian nhập cảnh"),
      thoi_gian_nhap_canh_den_ngay: Yup.string().required("Vui lòng điền thời gian xuất cảnh"),
      thoi_gian_du_dinh_cu_tru: Yup.string().required("Vui lòng chọn số ngày định cư trú"),
      ngay_du_dinh_nhap_canh: Yup.string().required("Vui lòng điền ngày dự định nhập cảnh"),
      cua_khau_du_dinh_nhap_canh: Yup.string().required("Vui lòng chọn cửa khẩu nhập cảnh"),
      cua_khau_du_dinh_xuat_canh: Yup.string().required("Vui lòng chọn cửa khẩu xuất cảnh"),
      dia_diem_de_nghi: Yup.string().required("Vui lòng chọn"),
      dia_chi_cu_tru_tai_viet_nam_so_dien_thoai: Yup.string().required("Vui lòng điền số điện thoại"),
      // email: Yup.string().required("Vui lòng nhập email"),
      // otp: Yup.string().required("Vui lòng kiểm tra hòm thư và nhập mã OTP"),
      so_dien_thoai: Yup.string().required("Vui lòng nhập số điện thoại"),
      dia_chi_lien_lac: Yup.string().required("Vui lòng nhập địa chỉ liên lạc"),
      lien_lac_khan_cap_ho_va_ten: Yup.string().required("Vui lòng nhập"),
      lien_lac_khan_cap_noi_o_hien_nay: Yup.string().required("Vui lòng nhập"),
      lien_lac_khan_cap_so_dien_thoai: Yup.string().required("Vui lòng nhập"),
      ten_cong_ty: Yup.string().required("Vui lòng nhập tên công ty"),
      so_dien_thoai_co_quan: Yup.string().required("Vui lòng nhập số điện thoại công ty"),
      dia_chi_co_quan: Yup.string().required("Vui lòng nhập địa chỉ công ty"),
      nguoi_dam_bao_kinh_phi_chuyen_di: Yup.string().required("Vui lòng chọn"),
      da_mua_bao_hiem_chua: Yup.string().required("Vui lòng chọn"),
      kinh_phi_du_tinh: Yup.number().required("Vui lòng nhập số tiền dự tính"),
      gia_tri_thi_thuc_dien_tu: Yup.string().required("vui lòng chọn giá trị thị thực")
    }),
    onSubmit: async (values, { resetForm }) => {
      if (new Date(values.ngay_cap) >= new Date(values.ngay_het_han)) {
        Swal.fire({
          title: "Lỗi",
          text: "Ngày hết hạn phải lớn hơn ngày cấp",
          icon: "error",
          timer: 3000
        })
        return
      } else if (new Date(values.thoi_gian_nhap_canh_tu_ngay) <= new Date()) {
        Swal.fire({
          title: "Lỗi",
          text: "Ngày nhập cảnh phải lớn hơn ngày hiện tại",
          icon: "error",
          timer: 3000
        })
        return
      }
      values.anh_chan_dung_url = anhChanDungUrl
      values.anh_ho_chieu_url = anhHoChieuUrl
      values.ngay_cap = converDate(values.ngay_cap)
      values.ngay_thang_nam_sinh = converDate(values.ngay_thang_nam_sinh)
      values.ngay_du_dinh_nhap_canh = converDate(values.ngay_du_dinh_nhap_canh)
      values.ngay_het_han = converDate(values.ngay_het_han)
      values.thoi_gian_nhap_canh_den_ngay = converDate(values.thoi_gian_nhap_canh_den_ngay)
      values.thoi_gian_nhap_canh_tu_ngay = converDate(values.thoi_gian_nhap_canh_tu_ngay)
      values.email = values.email.toLowerCase()
      Swal.fire({
        title: "Đang xử lý",
        icon: "warning",
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false
      })
      setSuccess(false)
      SendEvisaApi(values)
        .then(res => {
          Swal.fire({
            title: "Thành công",
            text: "Gửi evisa thành công"
          }).then(prop => {
            if (prop.isConfirmed) {
              window.location.reload()
            }
          })
          setSuccess(true)
        })
        .catch(error => {
          console.error(error)
        })
      setAnhChanDung(anhChanDung)
      setAnhHoChieu(anhHoChieu)
      resetForm()
    }
  })

  const handleCounterNgayNhapXuatCanh = (e, isSelect, ngaynhapcanh) => {
    const fromDateString = validationSubmitEvisa.values.thoi_gian_nhap_canh_tu_ngay
    if (fromDateString !== "" && e.value !== "" && isSelect === true) {
      let fromDate = new Date(fromDateString)
      let toDate = new Date(fromDate.setDate(fromDate.getDate() + Number(e.value) - 1))
      setThoigiandudinhcutruValue(e)
      validationSubmitEvisa.setFieldValue("thoi_gian_du_dinh_cu_tru", e.value)
      validationSubmitEvisa.setFieldValue("kinh_phi_du_tinh", Number(e.value) * 35)
      validationSubmitEvisa.setFieldValue("thoi_gian_nhap_canh_den_ngay", toDate)
    } else if (ngaynhapcanh !== "" && isSelect === false) {
      let selected_value = validationSubmitEvisa.values.thoi_gian_du_dinh_cu_tru
      if (selected_value !== "") {
        let fromDate = new Date(ngaynhapcanh)
        let toDate = new Date(fromDate.setDate(fromDate.getDate() + Number(selected_value) - 1))
        validationSubmitEvisa.setFieldValue("kinh_phi_du_tinh", Number(selected_value) * 35)
        validationSubmitEvisa.setFieldValue("thoi_gian_nhap_canh_den_ngay", toDate)
      }
    }
  }

  const set_email_by_fullname = (ho, ten, id) => {
    let email_valid = `${ho !== "" ? `${String(ho).toLowerCase().replace(/ /g, "_")}` : ""}${
      ten !== "" ? `_${String(ten).toLowerCase().replace(/ /g, "_")}` : ""
    }${id !== "" ? `_${String(id).toLowerCase()}` : ""}@${domain}`

    validationSubmitEvisa.setFieldValue("email", email_valid)
  }

  useEffect(() => {
    GetRandomAddressEvisa()
      .then(res => {
        let data = res.data
        if (data && data.diachi) {
          setThanhPhoLabel(data.thanhpho_label_a05)
          validationSubmitEvisa.setFieldValue("dia_chi_cu_tru_tai_viet_nam_tinh_tp", data.thanhpho_value_a05)
          validationSubmitEvisa.setFieldValue("dia_chi_cu_tru_tai_viet_nam_quan_huyen", data.quan_label)
          validationSubmitEvisa.setFieldValue("dia_chi_cu_tru_tai_viet_nam_phuong_xa", data.phuong_label)
          validationSubmitEvisa.setFieldValue("dia_chi_cu_tru_tai_viet_nam_chi_tiet", data.diachi)
          validationSubmitEvisa.setFieldValue("dia_chi_cu_tru_tai_viet_nam_so_dien_thoai", data.sdt_vietnam)
        }
      })
      .catch(err => {})

    GetRandomFakeInfoEvisa(quoc_tichLabel)
      .then(res => {
        let data = res.data
        if (data.msg) {
          validationSubmitEvisa.setFieldValue("so_dien_thoai", "")
          validationSubmitEvisa.setFieldValue("dia_chi_lien_lac", "")
          validationSubmitEvisa.setFieldValue("lien_lac_khan_cap_ho_va_ten", "")
          validationSubmitEvisa.setFieldValue("lien_lac_khan_cap_so_dien_thoai", "")
          validationSubmitEvisa.setFieldValue("lien_lac_khan_cap_noi_o_hien_nay", "")
          validationSubmitEvisa.setFieldValue("ten_cong_ty", "")
          validationSubmitEvisa.setFieldValue("so_dien_thoai_co_quan", "")
          validationSubmitEvisa.setFieldValue("dia_chi_co_quan", "")
          Swal.fire({
            title: "Không có fake data",
            text: "Vui lòng liên hệ admin để thêm fake data cho quốc tịch này",
            timer: 3000,
            icon: "warning"
          })
          return
        }
        validationSubmitEvisa.setFieldValue("so_dien_thoai", data.ttll_so_dien_thoai)
        validationSubmitEvisa.setFieldValue("dia_chi_lien_lac", data.ttll_dia_chi_lien_lac)
        validationSubmitEvisa.setFieldValue("lien_lac_khan_cap_ho_va_ten", data.ttll_lhkc_ho_va_ten)
        validationSubmitEvisa.setFieldValue("lien_lac_khan_cap_so_dien_thoai", data.ttll_lhkc_so_dien_thoai)
        validationSubmitEvisa.setFieldValue("lien_lac_khan_cap_noi_o_hien_nay", data.ttll_lhkc_noi_o_hien_nay)
        validationSubmitEvisa.setFieldValue("ten_cong_ty", data.ttnn_ten_cong_ty)
        validationSubmitEvisa.setFieldValue("so_dien_thoai_co_quan", data.ttnn_sdt_cong_ty)
        validationSubmitEvisa.setFieldValue("dia_chi_co_quan", data.ttnn_dia_chi_cong_ty)
      })
      .catch(err => {})

    // GetEmailDomainEvisa().then((res) => {
    //   let data = res.data;
    //   setDomain(data.domain)
    // }).catch((err) =>{

    // })
  }, [isSuccess, quoc_tichLabel])

  useEffect(() => {
    if (dataOcr === undefined) {
      return
    }
    if (dataOcr.surname !== "") {
      validationSubmitEvisa.setErrors("ho", "")
      validationSubmitEvisa.setFieldValue("ho", dataOcr.surname)
    }
    if (dataOcr.name !== "") {
      validationSubmitEvisa.setErrors("chu_dem_va_ten", null)
      validationSubmitEvisa.setFieldValue("chu_dem_va_ten", dataOcr.name)
    }

    if (dataOcr.surname !== "" || dataOcr.name !== "" || dataOcr.id !== "") {
      set_email_by_fullname(dataOcr.surname, dataOcr.name, dataOcr.id)

      // GetMailDvc_Send(email_valid).then((res) => {
      //   console.log("Da gui mail");
      //   console.log(res.data);
      // });
    }

    if (dataOcr.country !== "") {
      validationSubmitEvisa.setErrors("quoc_tich", null)
      validationSubmitEvisa.setFieldValue("quoc_tich", dataOcr.country)
      validationSubmitEvisa.setFieldValue("noi_sinh", dataOcr.country)
      validationSubmitEvisa.setFieldValue("dia_diem_de_nghi", dataOcr.country)
      setQuoc_tichValue(
        dataOcr.country
          ? quoctich.map((item, index) => {
              if (item.value === dataOcr.country) {
                validationSubmitEvisa.setFieldValue("co_quan_cap", item.label)
                setQuoc_tichLabel(item.label)
                return item
              }
            })
          : quoctich[0]
      )
    }
    if (dataOcr.id !== "") {
      validationSubmitEvisa.setErrors("so_ho_chieu", null)
      validationSubmitEvisa.setFieldValue("so_ho_chieu", dataOcr.id)
      validationSubmitEvisa.setErrors("so_cmnd", null)
      validationSubmitEvisa.setFieldValue("so_cmnd", dataOcr.id)
    }
    if (dataOcr.dob !== "") {
      validationSubmitEvisa.setErrors("ngay_thang_nam_sinh", null)
      validationSubmitEvisa.setFieldValue("ngay_thang_nam_sinh", new Date(dataOcr.dob))
    }
    if (dataOcr.sex !== "") {
      validationSubmitEvisa.setErrors("gioi_tinh", null)
      validationSubmitEvisa.setFieldValue("gioi_tinh", dataOcr.sex === "M" ? "M" : "F")
      setGioi_tinhValue(
        dataOcr.sex
          ? gioitinh_option.map((item, index) => {
              if (item.value === dataOcr.sex) return item
            })
          : gioitinh_option[0]
      )
    }
    if (dataOcr.issue_date !== "") {
      validationSubmitEvisa.setFieldValue("ngay_cap", new Date(dataOcr.issue_date))
    }
    if (dataOcr.expiry_date !== "") {
      validationSubmitEvisa.setFieldValue("ngay_het_han", new Date(dataOcr.expiry_date))
    }
  }, [dataOcr])

  useEffect(() => {
    GetUserFee("EVISA").then(res => {
      let data = res.data
      if (data.length === 0) {
        Swal.fire({
          title: "Lỗi",
          icon: "error",
          text: "Vui lòng liên hệ admin để kích hoạt phí dịch vụ",
          allowOutsideClick: false,
          showConfirmButton: false
        })
      } else {
        let emergency_data_array = []
        data.map((item, index) => {
          emergency_data_array.push({
            label: item.title_vn,
            value: item.id
          })
        })
        setOptionEmergency(emergency_data_array)
      }
    })
  }, [])

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={props.t("EVISA Profile")} breadcrumbItem={props.t("Submit New Evisa")} />
          <Form
            onSubmit={e => {
              e.preventDefault()
              validationSubmitEvisa.handleSubmit()
              return false
            }}
          >
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Col className="d-flex justify-content-between align-items-center">
                      <CardTitle className="mb-3">1. {props.t("Enter personal information")}</CardTitle>
                      <OptionOCR />
                    </Col>
                    <p className="font-weight-400 mb-0 text-danger pb-2">
                      <b>{props.t("Attention")}:</b>{" "}
                      {props.t("Check portrait photo, we do not accept photos wearing glasses")}.
                    </p>
                    <p className="font-weight-400 mb-0 pb-2">
                      <b>{props.t("Attention")}:</b>{" "}
                      {props.t(
                        "Check the information taken from the passport, if there is incorrect information, please correct it"
                      )}
                      .
                    </p>
                    <p className="font-weight-400 mb-0 pb-2">
                      <b>{props.t("Attention")}:</b>{" "}
                      {props.t("Please check the validity period on the customer's passport")}.
                    </p>
                    <p className="font-weight-400 mb-0 pb-4">
                      <b>{props.t("Attention")}:</b>{" "}
                      {props.t("If the photo is not of good quality, please replace it with a better photo")}.
                    </p>
                    <div data-repeater-list="outer-group" className="outer">
                      <div data-repeater-item className="outer">
                        <FormGroup className="mb-4" row>
                          <Col sm={2} md={6} lg={2}>
                            <div>
                              <Label htmlFor="anh_chan_dung" className="form-label">
                                {props.t("Portrait")}:
                              </Label>
                              <Input
                                className="form-control"
                                type="file"
                                id="anh_chan_dung"
                                name="anh_chan_dung"
                                value={validationSubmitEvisa.values.anh_chan_dung || ""}
                                onChange={handleOnChangeAnhChanDung}
                                onBlur={validationSubmitEvisa.handleBlur}
                                invalid={
                                  validationSubmitEvisa.touched.anh_chan_dung &&
                                  validationSubmitEvisa.errors.anh_chan_dung
                                    ? true
                                    : false
                                }
                              />
                              <img
                                src={anhChanDung ? anhChanDung : test_image}
                                alt=""
                                className="w-100 mt-2 rounded-3"
                              />
                              <Col className="d-flex justify-content-center mt-2">
                                <button
                                  type="button"
                                  onClick={async () => setModalImageEditorChanDung(true)}
                                  disabled={disabledButtonEditChanDung}
                                  className="btn btn-primary me-3"
                                >
                                  <i className="bx bx-smile font-size-16 align-middle me-2"></i> {props.t("Edit photo")}
                                </button>
                              </Col>
                            </div>
                          </Col>
                          <Col sm={4} md={6} lg={4}>
                            <div>
                              <Label htmlFor="anh_ho_chieu" className="form-label">
                                {props.t("Upload a passport photo")}:
                              </Label>
                              <Input
                                className="form-control"
                                type="file"
                                id="anh_ho_chieu"
                                name="anh_ho_chieu"
                                value={validationSubmitEvisa.values.anh_ho_chieu || ""}
                                onChange={handleOnChangeAnhHoChieu}
                                onBlur={validationSubmitEvisa.handleBlur}
                                invalid={
                                  validationSubmitEvisa.touched.anh_ho_chieu &&
                                  validationSubmitEvisa.errors.anh_ho_chieu
                                    ? true
                                    : false
                                }
                              />
                              <img
                                src={anhHoChieu ? anhHoChieu : default_passport}
                                alt=""
                                className="w-100 mt-2 rounded-3"
                              />
                              <div className="d-flex justify-content-center mt-2">
                                <button
                                  type="button"
                                  onClick={async () => setModalImageEditorHoChieu(true)}
                                  disabled={disabledButtonEditHoChieu}
                                  className="btn btn-primary me-3"
                                >
                                  <i className="bx bx-smile font-size-16 align-middle me-2"></i> {props.t("Edit photo")}
                                </button>
                                <Button
                                  className="btn btn-danger"
                                  onClick={() => {
                                    handleOcrPassport()
                                  }}
                                >
                                  <i className="bx bx-check-double font-size-16 align-middle"></i>{" "}
                                  {props.t("OCR Passport")}
                                </Button>
                              </div>
                            </div>
                          </Col>
                          <Col sm={3} md={6} lg={3}>
                            <div>
                              <Label htmlFor="ho" className="form-label">
                                {props.t("Surname")}: <span className="text-danger">(*)</span>
                              </Label>
                              <Input
                                id="ho"
                                name="ho"
                                type="text"
                                className="form-control"
                                placeholder={props.t("Ex") + ": WD"}
                                value={validationSubmitEvisa.values.ho}
                                onBlur={validationSubmitEvisa.handleBlur}
                                onChange={e => {
                                  validationSubmitEvisa.handleChange(e)
                                  set_email_by_fullname(
                                    e.target.value,
                                    validationSubmitEvisa.values.chu_dem_va_ten,
                                    validationSubmitEvisa.values.so_ho_chieu
                                  )
                                }}
                                invalid={
                                  validationSubmitEvisa.touched.ho && validationSubmitEvisa.errors.ho ? true : false
                                }
                              />
                            </div>
                            <div className="mt-2">
                              <Label htmlFor="formFile" className="form-label">
                                {props.t("Nationality")}: <span className="text-danger">(*)</span>
                              </Label>
                              <Select
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor:
                                      validationSubmitEvisa.touched.quoc_tich && validationSubmitEvisa.errors.quoc_tich
                                        ? "red"
                                        : "#ced4da !important"
                                  })
                                }}
                                name="quoc_tich"
                                placeholder="Vui lòng chọn"
                                options={quoctich}
                                defaultValue={quoc_tichValue}
                                value={quoc_tichValue}
                                onBlur={validationSubmitEvisa.handleBlur}
                                onChange={e => {
                                  validationSubmitEvisa.setFieldValue("quoc_tich", e.value)
                                  validationSubmitEvisa.setFieldValue("noi_sinh", e.value)
                                  setQuoc_tichValue(e)
                                  setQuoc_tichLabel(e.label)
                                }}
                              />
                            </div>
                            <div className="mt-2">
                              <Label htmlFor="gioi_tinh" className="form-label">
                                {props.t("Gender")}: <span className="text-danger">(*)</span>
                              </Label>
                              <Select
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor:
                                      validationSubmitEvisa.touched.gioi_tinh && validationSubmitEvisa.errors.gioi_tinh
                                        ? "red"
                                        : "#ced4da !important"
                                  })
                                }}
                                name="gioi_tinh"
                                placeholder="Vui lòng chọn"
                                options={gioitinh_option}
                                defaultValue={gioitinh_option[0]}
                                value={gioi_tinhValue}
                                onChange={e => {
                                  validationSubmitEvisa.setFieldValue("gioi_tinh", e.value)
                                  setGioi_tinhValue(e)
                                }}
                              />
                            </div>
                            <div className="mt-2">
                              <Label htmlFor="co_quan_cap" className="form-label">
                                {props.t("Authority/Place of issue")}: <span className="text-danger">(*)</span>
                              </Label>
                              <Input
                                id="co_quan_cap"
                                name="co_quan_cap"
                                type="text"
                                className="form-control"
                                placeholder={props.t("Ex") + ": CHN"}
                                value={validationSubmitEvisa.values.co_quan_cap}
                                onBlur={validationSubmitEvisa.handleBlur}
                                onChange={validationSubmitEvisa.handleChange}
                                invalid={
                                  validationSubmitEvisa.touched.co_quan_cap && validationSubmitEvisa.errors.co_quan_cap
                                    ? true
                                    : false
                                }
                              />
                            </div>
                            <div className="mt-2">
                              <Label htmlFor="ngay_cap" className="form-label">
                                {props.t("Date Range")}: <span className="text-danger">(*)</span>
                              </Label>
                              <DatePicker
                                name="ngay_cap"
                                dateFormat="dd/MM/yyyy"
                                placeholderText="dd/mm/yyyy"
                                className={
                                  "form-control" +
                                  (validationSubmitEvisa.touched.ngay_cap && validationSubmitEvisa.errors.ngay_cap
                                    ? " border-danger"
                                    : "")
                                }
                                selected={
                                  (validationSubmitEvisa.values.ngay_cap &&
                                    new Date(validationSubmitEvisa.values.ngay_cap)) ||
                                  ""
                                }
                                onChange={val => {
                                  validationSubmitEvisa.setFieldValue("ngay_cap", val === null ? "" : val)
                                }}
                                onBlur={validationSubmitEvisa.handleBlur}
                              />
                            </div>
                            <div className="mt-2">
                              <Label htmlFor="email" className="form-label">
                                Email:
                              </Label>
                              <Input
                                disabled
                                className="form-control"
                                type="text"
                                id="email"
                                name="email"
                                value={validationSubmitEvisa.values.email}
                                onChange={validationSubmitEvisa.handleChange}
                                onBlur={validationSubmitEvisa.handleBlur}
                                invalid={
                                  validationSubmitEvisa.touched.email && validationSubmitEvisa.errors.email
                                    ? true
                                    : false
                                }
                              />
                            </div>
                            <div className="mt-2">
                              <Label htmlFor="vip" className="form-label">
                                VIP:
                              </Label>
                              <Input
                                className="form-control"
                                type="select"
                                id="vip"
                                name="vip"
                                value={validationSubmitEvisa.values.vip}
                                onChange={validationSubmitEvisa.handleChange}
                                onBlur={validationSubmitEvisa.handleBlur}
                                invalid={
                                  validationSubmitEvisa.touched.vip && validationSubmitEvisa.errors.vip ? true : false
                                }
                              >
                                <option value={0}>{props.t("No")}</option>
                                <option value={1}>{props.t("Yes")}</option>
                              </Input>
                            </div>
                          </Col>
                          <Col sm={3} md={6} lg={3}>
                            <div>
                              <Label htmlFor="chu_dem_va_ten" className="form-label">
                                {props.t("Middle name & first name")}:<span className="text-danger">(*)</span>
                              </Label>
                              <Input
                                id="chu_dem_va_ten"
                                name="chu_dem_va_ten"
                                type="text"
                                className="form-control"
                                placeholder={props.t("Ex") + ": WU"}
                                value={validationSubmitEvisa.values.chu_dem_va_ten}
                                onBlur={validationSubmitEvisa.handleBlur}
                                onChange={e => {
                                  validationSubmitEvisa.handleChange(e)
                                  set_email_by_fullname(
                                    validationSubmitEvisa.values.ho,
                                    e.target.value,
                                    validationSubmitEvisa.values.so_ho_chieu
                                  )
                                }}
                                invalid={
                                  validationSubmitEvisa.touched.chu_dem_va_ten &&
                                  validationSubmitEvisa.errors.chu_dem_va_ten
                                    ? true
                                    : false
                                }
                              />
                            </div>
                            <div className="mt-2">
                              <Label htmlFor="ngay_thang_nam_sinh" className="form-label">
                                {props.t("Date of birth")}: <span className="text-danger">(*)</span>
                              </Label>
                              <DatePicker
                                name="ngay_thang_nam_sinh"
                                dateFormat="dd/MM/yyyy"
                                placeholderText="dd/mm/yyyy"
                                className={
                                  "form-control" +
                                  (validationSubmitEvisa.touched.ngay_thang_nam_sinh &&
                                  validationSubmitEvisa.errors.ngay_thang_nam_sinh
                                    ? " border-danger"
                                    : "")
                                }
                                selected={
                                  (validationSubmitEvisa.values.ngay_thang_nam_sinh &&
                                    new Date(validationSubmitEvisa.values.ngay_thang_nam_sinh)) ||
                                  ""
                                }
                                onChange={val => {
                                  validationSubmitEvisa.setFieldValue("ngay_thang_nam_sinh", val === null ? "" : val)
                                }}
                                onBlur={validationSubmitEvisa.handleBlur}
                              />
                            </div>
                            <div className="mt-2">
                              <Label htmlFor="so_cmnd" className="form-label">
                                {props.t("ID Card/ID/Passport number")}: <span className="text-danger">(*)</span>
                              </Label>
                              <Input
                                id="so_cmnd"
                                name="so_cmnd"
                                type="text"
                                className="form-control"
                                placeholder={props.t("Ex") + ": E12345678"}
                                value={validationSubmitEvisa.values.so_cmnd}
                                onBlur={validationSubmitEvisa.handleBlur}
                                onChange={e => {
                                  validationSubmitEvisa.handleChange(e)
                                  validationSubmitEvisa.setFieldValue("so_ho_chieu", e.target.value)
                                  set_email_by_fullname(
                                    validationSubmitEvisa.values.ho,
                                    validationSubmitEvisa.values.chu_dem_va_ten,
                                    e.target.value
                                  )
                                }}
                                invalid={
                                  validationSubmitEvisa.touched.so_cmnd && validationSubmitEvisa.errors.so_cmnd
                                    ? true
                                    : false
                                }
                              />
                            </div>
                            <div className="mt-2">
                              <Label htmlFor="loai_ho_chieu" className="form-label">
                                {props.t("Passport type")}: <span className="text-danger">(*)</span>
                              </Label>
                              <Select
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor:
                                      validationSubmitEvisa.touched.loai_ho_chieu &&
                                      validationSubmitEvisa.errors.loai_ho_chieu
                                        ? "red"
                                        : "#ced4da !important"
                                  })
                                }}
                                name="loai_ho_chieu"
                                placeholder="Vui lòng chọn"
                                options={loaihochieu}
                                defaultValue={loaihochieu[1]}
                                onBlur={validationSubmitEvisa.handleBlur}
                                onChange={e => {
                                  validationSubmitEvisa.setFieldValue("loai_ho_chieu", e.value)
                                }}
                              />
                            </div>

                            <div className="mt-2">
                              <Label htmlFor="formFile" className="form-label">
                                {props.t("Expiration date")}:<span className="text-danger">(*)</span>
                              </Label>
                              <DatePicker
                                name="ngay_het_han"
                                dateFormat="dd/MM/yyyy"
                                placeholderText="dd/mm/yyyy"
                                className={
                                  "form-control" +
                                  (validationSubmitEvisa.touched.ngay_het_han &&
                                  validationSubmitEvisa.errors.ngay_het_han
                                    ? " border-danger"
                                    : "")
                                }
                                selected={
                                  (validationSubmitEvisa.values.ngay_het_han &&
                                    new Date(validationSubmitEvisa.values.ngay_het_han)) ||
                                  ""
                                }
                                onChange={val => {
                                  validationSubmitEvisa.setFieldValue("ngay_het_han", val === null ? "" : val)
                                }}
                                onBlur={validationSubmitEvisa.handleBlur}
                              />
                            </div>
                            <div className="mt-2">
                              <Label htmlFor="otp" className="form-label">
                                {props.t("Urgent need")}:
                              </Label>
                              <Input
                                type="select"
                                name="urgent"
                                value={validationSubmitEvisa.values.urgent}
                                onChange={validationSubmitEvisa.handleChange}
                                onBlur={validationSubmitEvisa.handleBlur}
                              >
                                {optionEmergency.map((item, index) => {
                                  return (
                                    <option key={index} value={item.value}>
                                      {item.label}
                                    </option>
                                  )
                                })}
                              </Input>
                            </div>
                            <div className="mt-2">
                              <Label htmlFor="phan_tach_khach_hang" className="form-label">
                                {props.t("Customer segmentation")}:
                              </Label>
                              <Input
                                name="phan_tach_khach_hang"
                                value={validationSubmitEvisa.values.phan_tach_khach_hang}
                                onChange={validationSubmitEvisa.handleChange}
                                onBlur={validationSubmitEvisa.handleBlur}
                              />
                            </div>
                          </Col>
                        </FormGroup>
                      </div>
                    </div>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">2. {props.t("Travel information")}:</CardTitle>
                    <div data-repeater-list="outer-group" className="outer">
                      <div data-repeater-item className="outer">
                        <FormGroup className="mb-4" row>
                          <Row>
                            <Col sm={3}>
                              <div>
                                <Label htmlFor="formFile" className="form-label">
                                  {props.t("Entry purpose")}: <span className="text-danger">(*)</span>
                                </Label>
                                <Select
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      borderColor:
                                        validationSubmitEvisa.touched.muc_dich_nhap_canh &&
                                        validationSubmitEvisa.errors.muc_dich_nhap_canh
                                          ? "red"
                                          : "#ced4da !important"
                                    })
                                  }}
                                  name="muc_dich_nhap_canh"
                                  placeholder="Vui lòng chọn"
                                  options={mucdichnhapcanh}
                                  defaultValue={mucdichnhapcanh[1]}
                                  onBlur={validationSubmitEvisa.handleBlur}
                                  onChange={e => {
                                    validationSubmitEvisa.setFieldValue("muc_dich_nhap_canh", e.value)
                                    validationSubmitEvisa.setFieldValue("muc_dich_nhap_canh_label", e.label)
                                  }}
                                />
                              </div>
                            </Col>
                            <Col sm={3}>
                              <div>
                                <Label htmlFor="gia_tri_thi_thuc_dien_tu" className="form-label">
                                  {props.t("Value of electronic visa")}: <span className="text-danger">(*)</span>
                                </Label>
                                <Select
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      borderColor:
                                        validationSubmitEvisa.touched.gia_tri_thi_thuc_dien_tu &&
                                        validationSubmitEvisa.errors.gia_tri_thi_thuc_dien_tu
                                          ? "red"
                                          : "#ced4da !important"
                                    })
                                  }}
                                  name="gia_tri_thi_thuc_dien_tu"
                                  id="gia_tri_thi_thuc_dien_tu"
                                  placeholder="Vui lòng chọn"
                                  options={giatrithithuc}
                                  defaultValue={giatrithithuc[0]}
                                  onBlur={validationSubmitEvisa.handleBlur}
                                  onChange={e => {
                                    validationSubmitEvisa.setFieldValue("gia_tri_thi_thuc_dien_tu", e.value)
                                  }}
                                />
                              </div>
                            </Col>
                            <Col sm={2}>
                              <Row>
                                <Label htmlFor="thoi_gian_nhap_canh_tu_ngay" className="form-label">
                                  {props.t("Entry time")}: <span className="text-danger">(*)</span>
                                </Label>
                                <DatePicker
                                  id="thoi_gian_nhap_canh_tu_ngay"
                                  name="thoi_gian_nhap_canh_tu_ngay"
                                  dateFormat="dd/MM/yyyy"
                                  placeholderText="dd/mm/yyyy"
                                  className={
                                    "form-control" +
                                    (validationSubmitEvisa.touched.thoi_gian_nhap_canh_tu_ngay &&
                                    validationSubmitEvisa.errors.thoi_gian_nhap_canh_tu_ngay
                                      ? " border-danger"
                                      : "")
                                  }
                                  selected={
                                    (validationSubmitEvisa.values.thoi_gian_nhap_canh_tu_ngay &&
                                      new Date(validationSubmitEvisa.values.thoi_gian_nhap_canh_tu_ngay)) ||
                                    ""
                                  }
                                  onChange={val => {
                                    validationSubmitEvisa.setFieldValue(
                                      "thoi_gian_nhap_canh_tu_ngay",
                                      val === null ? "" : val
                                    )
                                    validationSubmitEvisa.setFieldValue(
                                      "ngay_du_dinh_nhap_canh",
                                      val === null ? "" : val
                                    )
                                    handleCounterNgayNhapXuatCanh("", false, val === null ? "" : val)
                                  }}
                                  onBlur={validationSubmitEvisa.handleBlur}
                                />
                              </Row>
                            </Col>
                            <Col sm={2}>
                              <div>
                                <Label htmlFor="thoi_gian_du_dinh_cu_tru" className="form-label">
                                  {props.t("Residence period")}: <span className="text-danger">(*)</span>
                                </Label>
                                <Select
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      borderColor:
                                        validationSubmitEvisa.touched.thoi_gian_du_dinh_cu_tru &&
                                        validationSubmitEvisa.errors.thoi_gian_du_dinh_cu_tru
                                          ? "red"
                                          : "#ced4da !important"
                                    })
                                  }}
                                  name="thoi_gian_du_dinh_cu_tru"
                                  placeholder="Vui lòng chọn"
                                  options={thoigiandudinhcutru}
                                  value={thoigiandudinhcutruValue}
                                  // defaultValue={thoigiandudinhcutru[0]}
                                  onBlur={validationSubmitEvisa.handleBlur}
                                  onChange={e => {
                                    handleCounterNgayNhapXuatCanh(e, true)
                                  }}
                                />
                              </div>
                            </Col>
                            <Col sm={2}>
                              <Label htmlFor="thoi_gian_nhap_canh_den_ngay" className="form-label">
                                {props.t("Departure time")}: <span className="text-danger">(*)</span>
                              </Label>
                              <DatePicker
                                disabled
                                name="thoi_gian_nhap_canh_den_ngay"
                                dateFormat="dd/MM/yyyy"
                                placeholderText="dd/mm/yyyy"
                                className={
                                  "form-control" +
                                  (validationSubmitEvisa.touched.thoi_gian_nhap_canh_den_ngay &&
                                  validationSubmitEvisa.errors.thoi_gian_nhap_canh_den_ngay
                                    ? " border-danger"
                                    : "")
                                }
                                selected={
                                  (validationSubmitEvisa.values.thoi_gian_nhap_canh_den_ngay &&
                                    new Date(validationSubmitEvisa.values.thoi_gian_nhap_canh_den_ngay)) ||
                                  ""
                                }
                                onChange={val => {
                                  validationSubmitEvisa.setFieldValue(
                                    "thoi_gian_nhap_canh_den_ngay",
                                    val === null ? "" : val
                                  )
                                }}
                                onBlur={validationSubmitEvisa.handleBlur}
                              />
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col sm={4}>
                              <div>
                                <Label htmlFor="ngay_du_dinh_nhap_canh" className="form-label">
                                  {props.t("Intended time of entry")}: <span className="text-danger">(*)</span>
                                </Label>
                                <DatePicker
                                  name="ngay_du_dinh_nhap_canh"
                                  dateFormat="dd/MM/yyyy"
                                  placeholderText="dd/mm/yyyy"
                                  className={
                                    "form-control" +
                                    (validationSubmitEvisa.touched.ngay_du_dinh_nhap_canh &&
                                    validationSubmitEvisa.errors.ngay_du_dinh_nhap_canh
                                      ? " border-danger"
                                      : "")
                                  }
                                  selected={
                                    (validationSubmitEvisa.values.ngay_du_dinh_nhap_canh &&
                                      new Date(validationSubmitEvisa.values.ngay_du_dinh_nhap_canh)) ||
                                    ""
                                  }
                                  onChange={val => {
                                    validationSubmitEvisa.setFieldValue(
                                      "ngay_du_dinh_nhap_canh",
                                      val === null ? "" : val
                                    )
                                  }}
                                  onBlur={validationSubmitEvisa.handleBlur}
                                />
                              </div>
                            </Col>
                            <Col sm={4}>
                              <div>
                                <Label htmlFor="cua_khau_du_dinh_nhap_canh" className="form-label">
                                  {props.t("Entry gate")}: <span className="text-danger">(*)</span>
                                </Label>
                                <Select
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      borderColor:
                                        validationSubmitEvisa.touched.cua_khau_du_dinh_nhap_canh &&
                                        validationSubmitEvisa.errors.cua_khau_du_dinh_nhap_canh
                                          ? "red"
                                          : "#ced4da !important"
                                    })
                                  }}
                                  id="cua_khau_du_dinh_nhap_canh"
                                  name="cua_khau_du_dinh_nhap_canh"
                                  placeholder="Vui lòng chọn"
                                  options={diachi_nhapxuatcanh}
                                  defaultValue={diachi_nhapxuatcanh[1]}
                                  onBlur={validationSubmitEvisa.handleBlur}
                                  onChange={e => {
                                    validationSubmitEvisa.setFieldValue("cua_khau_du_dinh_nhap_canh", e.value)
                                  }}
                                />
                              </div>
                            </Col>
                            <Col sm={4}>
                              <div>
                                <Label htmlFor="formFile" className="form-label">
                                  {props.t("Gate of departure")}: <span className="text-danger">(*)</span>
                                </Label>
                                <Select
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      borderColor:
                                        validationSubmitEvisa.touched.cua_khau_du_dinh_xuat_canh &&
                                        validationSubmitEvisa.errors.cua_khau_du_dinh_xuat_canh
                                          ? "red"
                                          : "#ced4da !important"
                                    })
                                  }}
                                  name="cua_khau_du_dinh_xuat_canh"
                                  placeholder="Vui lòng chọn"
                                  options={diachi_nhapxuatcanh}
                                  defaultValue={diachi_nhapxuatcanh[1]}
                                  onBlur={validationSubmitEvisa.handleBlur}
                                  onChange={e => {
                                    validationSubmitEvisa.setFieldValue("cua_khau_du_dinh_xuat_canh", e.value)
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                        </FormGroup>
                      </div>
                    </div>
                  </CardBody>
                </Card>

                {thongtinlienlac ? (
                  <Card>
                    <CardBody className="d-flex align-items-center">
                      <Button
                        className={`bx bx-toggle-right`}
                        onClick={() => setThongtinlienlac(!thongtinlienlac)}
                      ></Button>
                      <CardTitle className="mx-2 mb-0" onClick={() => setThongtinlienlac(!thongtinlienlac)}>
                        3. {props.t("Communications")}:
                      </CardTitle>
                    </CardBody>
                    <CardBody>
                      <div data-repeater-list="outer-group" className="outer">
                        <div data-repeater-item className="outer">
                          <FormGroup className="mb-4" row>
                            <Row>
                              <Col sm={6}>
                                <div>
                                  <Label htmlFor="so_dien_thoai" className="form-label">
                                    {props.t("Phone")}:
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    id="so_dien_thoai"
                                    name="so_dien_thoai"
                                    value={validationSubmitEvisa.values.so_dien_thoai}
                                    onChange={validationSubmitEvisa.handleChange}
                                    onBlur={validationSubmitEvisa.handleBlur}
                                    invalid={
                                      validationSubmitEvisa.touched.so_dien_thoai &&
                                      validationSubmitEvisa.errors.so_dien_thoai
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                              </Col>
                              <Col sm={6}>
                                <div>
                                  <Label htmlFor="dia_chi_lien_lac" className="form-label">
                                    {props.t("Contact address")}:
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    id="dia_chi_lien_lac"
                                    name="dia_chi_lien_lac"
                                    value={validationSubmitEvisa.values.dia_chi_lien_lac}
                                    onChange={validationSubmitEvisa.handleChange}
                                    onBlur={validationSubmitEvisa.handleBlur}
                                    invalid={
                                      validationSubmitEvisa.touched.dia_chi_lien_lac &&
                                      validationSubmitEvisa.errors.dia_chi_lien_lac
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                              </Col>
                            </Row>
                            <CardTitle className="mb-2 mt-3">{props.t("Emergency Contact")}:</CardTitle>
                            <Row className="mt-3">
                              <Col sm={3}>
                                <div>
                                  <Label htmlFor="lien_lac_khan_cap_ho_va_ten" className="form-label">
                                    {props.t("Fullname")}:
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    id="lien_lac_khan_cap_ho_va_ten"
                                    name="lien_lac_khan_cap_ho_va_ten"
                                    value={validationSubmitEvisa.values.lien_lac_khan_cap_ho_va_ten}
                                    onChange={validationSubmitEvisa.handleChange}
                                    onBlur={validationSubmitEvisa.handleBlur}
                                    invalid={
                                      validationSubmitEvisa.touched.lien_lac_khan_cap_ho_va_ten &&
                                      validationSubmitEvisa.errors.lien_lac_khan_cap_ho_va_ten
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                              </Col>
                              <Col sm={3}>
                                <div>
                                  <Label htmlFor="lien_lac_khan_cap_so_dien_thoai" className="form-label">
                                    {props.t("Phone")}:
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    id="lien_lac_khan_cap_so_dien_thoai"
                                    name="lien_lac_khan_cap_so_dien_thoai"
                                    value={validationSubmitEvisa.values.lien_lac_khan_cap_so_dien_thoai}
                                    onChange={validationSubmitEvisa.handleChange}
                                    onBlur={validationSubmitEvisa.handleBlur}
                                    invalid={
                                      validationSubmitEvisa.touched.lien_lac_khan_cap_so_dien_thoai &&
                                      validationSubmitEvisa.errors.lien_lac_khan_cap_so_dien_thoai
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                              </Col>
                              <Col sm={3}>
                                <div>
                                  <Label htmlFor="lien_lac_khan_cap_noi_o_hien_nay" className="form-label">
                                    {props.t("Current residence")}:
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    id="lien_lac_khan_cap_noi_o_hien_nay"
                                    name="lien_lac_khan_cap_noi_o_hien_nay"
                                    value={validationSubmitEvisa.values.lien_lac_khan_cap_noi_o_hien_nay}
                                    onChange={validationSubmitEvisa.handleChange}
                                    onBlur={validationSubmitEvisa.handleBlur}
                                    invalid={
                                      validationSubmitEvisa.touched.lien_lac_khan_cap_noi_o_hien_nay &&
                                      validationSubmitEvisa.errors.lien_lac_khan_cap_noi_o_hien_nay
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                              </Col>
                              <Col sm={3}>
                                <div>
                                  <Label htmlFor="dia_diem_de_nghi" className="form-label">
                                    {props.t("Recommended location")}:
                                  </Label>
                                  <Select
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor:
                                          validationSubmitEvisa.touched.dia_diem_de_nghi &&
                                          validationSubmitEvisa.errors.dia_diem_de_nghi
                                            ? "red"
                                            : "#ced4da !important"
                                      })
                                    }}
                                    id="dia_diem_de_nghi"
                                    name="dia_diem_de_nghi"
                                    placeholder="Vui lòng chọn"
                                    options={quoctich}
                                    value={quoc_tichValue}
                                    defaultValue={quoc_tichValue}
                                    onBlur={validationSubmitEvisa.handleBlur}
                                    onChange={e => {
                                      validationSubmitEvisa.setFieldValue("dia_diem_de_nghi", e.value)
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </FormGroup>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                ) : (
                  <Card>
                    <CardBody className="d-flex align-items-center">
                      <Button
                        className={`bx bx-toggle-left`}
                        onClick={() => setThongtinlienlac(!thongtinlienlac)}
                      ></Button>
                      <CardTitle className="mx-2 mb-0" onClick={() => setThongtinlienlac(!thongtinlienlac)}>
                        3. {props.t("Communications")}:
                      </CardTitle>
                    </CardBody>
                  </Card>
                )}

                {thongtinnghenghiep ? (
                  <Card>
                    <CardBody className="d-flex align-items-center">
                      <Button
                        className={`bx bx-toggle-right`}
                        onClick={() => setThongtinnghenghiep(!thongtinnghenghiep)}
                      ></Button>
                      <CardTitle className="mx-2 mb-0" onClick={() => setThongtinnghenghiep(!thongtinnghenghiep)}>
                        4. {props.t("Job information")}:
                      </CardTitle>
                    </CardBody>

                    <CardBody>
                      <div data-repeater-list="outer-group" className="outer">
                        <div data-repeater-item className="outer">
                          <FormGroup className="mb-4" row>
                            <Row>
                              <Col sm={2}>
                                <div>
                                  <Label htmlFor="formFile" className="form-label">
                                    {props.t("Current occupation")}:
                                  </Label>
                                  <Select
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor:
                                          validationSubmitEvisa.touched.nghe_nghiep_hien_tai &&
                                          validationSubmitEvisa.errors.nghe_nghiep_hien_tai
                                            ? "red"
                                            : "#ced4da !important"
                                      })
                                    }}
                                    name="nghe_nghiep_hien_tai"
                                    placeholder="Vui lòng chọn"
                                    options={nghenghiep}
                                    defaultValue={nghenghiep[3]}
                                    onChange={e => {
                                      validationSubmitEvisa.setFieldValue("nghe_nghiep_hien_tai", e.value)
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col sm={2}>
                                <div>
                                  <Label htmlFor="ten_cong_ty" className="form-label">
                                    {props.t("Company name")}:
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    id="ten_cong_ty"
                                    name="ten_cong_ty"
                                    value={validationSubmitEvisa.values.ten_cong_ty}
                                    onChange={validationSubmitEvisa.handleChange}
                                    onBlur={validationSubmitEvisa.handleBlur}
                                    invalid={
                                      validationSubmitEvisa.touched.ten_cong_ty &&
                                      validationSubmitEvisa.errors.ten_cong_ty
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                              </Col>
                              <Col sm={2}>
                                <div>
                                  <Label htmlFor="so_dien_thoai_co_quan" className="form-label">
                                    {props.t("Company phone number")}:
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    id="so_dien_thoai_co_quan"
                                    name="so_dien_thoai_co_quan"
                                    value={validationSubmitEvisa.values.so_dien_thoai_co_quan}
                                    onChange={validationSubmitEvisa.handleChange}
                                    onBlur={validationSubmitEvisa.handleBlur}
                                    invalid={
                                      validationSubmitEvisa.touched.so_dien_thoai_co_quan &&
                                      validationSubmitEvisa.errors.so_dien_thoai_co_quan
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                              </Col>
                              <Col sm={6}>
                                <div>
                                  <Label htmlFor="dia_chi_co_quan" className="form-label">
                                    {props.t("Company address")}:
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    id="dia_chi_co_quan"
                                    name="dia_chi_co_quan"
                                    value={validationSubmitEvisa.values.dia_chi_co_quan}
                                    onChange={validationSubmitEvisa.handleChange}
                                    onBlur={validationSubmitEvisa.handleBlur}
                                    invalid={
                                      validationSubmitEvisa.touched.dia_chi_co_quan &&
                                      validationSubmitEvisa.errors.dia_chi_co_quan
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                              </Col>
                            </Row>
                          </FormGroup>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                ) : (
                  <Card>
                    <CardBody className="d-flex align-items-center">
                      <Button
                        className={`bx bx-toggle-left`}
                        onClick={() => setThongtinnghenghiep(!thongtinnghenghiep)}
                      ></Button>
                      <CardTitle className="mx-2 mb-0" onClick={() => setThongtinnghenghiep(!thongtinnghenghiep)}>
                        4. {props.t("Job information")}:
                      </CardTitle>
                    </CardBody>
                  </Card>
                )}

                {diachiovietnam ? (
                  <Card>
                    <CardBody className="d-flex align-items-center">
                      <Button
                        className={`bx bx-toggle-right`}
                        onClick={() => setDiachiovietnam(!diachiovietnam)}
                      ></Button>
                      <CardTitle className="mx-2 mb-0" onClick={() => setDiachiovietnam(!diachiovietnam)}>
                        5. {props.t("Address in Vietnam")}:
                      </CardTitle>
                    </CardBody>
                    <CardBody>
                      <div data-repeater-list="outer-group" className="outer">
                        <div data-repeater-item className="outer">
                          <FormGroup className="mb-4" row>
                            <Row>
                              <Col sm={2}>
                                <div>
                                  <Label htmlFor="dia_chi_cu_tru_tai_viet_nam_tinh_tp" className="form-label">
                                    {props.t("City")}:
                                  </Label>
                                  {/* <Select
                                    styles={{
                                      control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      borderColor:
                                        validationSubmitEvisa.touched.dia_chi_cu_tru_tai_viet_nam_tinh_tp &&
                                        validationSubmitEvisa.errors.dia_chi_cu_tru_tai_viet_nam_tinh_tp
                                        ? "red"
                                        : "#ced4da !important",
                                      }),
                                    }}
                                    id="dia_chi_cu_tru_tai_viet_nam_tinh_tp"
                                    name="dia_chi_cu_tru_tai_viet_nam_tinh_tp"
                                    placeholder="Vui lòng chọn"
                                    options={thanhpho}
                                    defaultValue={
                                      validationSubmitEvisa.values.dia_chi_cu_tru_tai_viet_nam_tinh_tp !== "" ? 
                                        thanhpho.map((item, index) => { 
                                          if(item.label === validationSubmitEvisa.values.dia_chi_cu_tru_tai_viet_nam_tinh_tp) return item
                                        }) 
                                        : 
                                        thanhpho[0]
                                    }
                                    onBlur={validationSubmitEvisa.handleBlur}
                                    onChange={e => {
                                      validationSubmitEvisa.setFieldValue(
                                      "dia_chi_cu_tru_tai_viet_nam_tinh_tp",
                                      e.value
                                      )
                                    }}
                                  /> */}
                                  <Input
                                    disabled
                                    className="form-control"
                                    type="text"
                                    id="dia_chi_cu_tru_tai_viet_nam_tinh_tp"
                                    name="dia_chi_cu_tru_tai_viet_nam_tinh_tp"
                                    value={thanh_pho_label}
                                    onChange={validationSubmitEvisa.handleChange}
                                    onBlur={validationSubmitEvisa.handleBlur}
                                    invalid={
                                      validationSubmitEvisa.touched.dia_chi_cu_tru_tai_viet_nam_tinh_tp &&
                                      validationSubmitEvisa.errors.dia_chi_cu_tru_tai_viet_nam_tinh_tp
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                              </Col>
                              <Col sm={2}>
                                <div>
                                  <Label htmlFor="formFile" className="form-label">
                                    {props.t("District")}:
                                  </Label>
                                  {/* <Select
                                    styles={{
                                      control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      borderColor:
                                        validationSubmitEvisa.touched.dia_chi_cu_tru_tai_viet_nam_quan_huyen &&
                                        validationSubmitEvisa.errors.dia_chi_cu_tru_tai_viet_nam_quan_huyen
                                        ? "red"
                                        : "#ced4da !important",
                                      }),
                                    }}
                                    id="dia_chi_cu_tru_tai_viet_nam_quan_huyen"
                                    name="dia_chi_cu_tru_tai_viet_nam_quan_huyen"
                                    placeholder="Vui lòng chọn"
                                    options={thanhpho}
                                    defaultValue={
                                      validationSubmitEvisa.values.dia_chi_cu_tru_tai_viet_nam_quan_huyen !== "" ? 
                                        thanhpho.map((item, index) => { 
                                          if(item.label === validationSubmitEvisa.values.dia_chi_cu_tru_tai_viet_nam_quan_huyen) return item
                                        }) 
                                        : 
                                        thanhpho[0]
                                    }
                                    onBlur={validationSubmitEvisa.handleBlur}
                                    onChange={e => {
                                      validationSubmitEvisa.setFieldValue(
                                      "dia_chi_cu_tru_tai_viet_nam_quan_huyen",
                                      e.value
                                      )
                                    }}
                                  /> */}
                                  <Input
                                    disabled
                                    className="form-control"
                                    type="text"
                                    id="dia_chi_cu_tru_tai_viet_nam_quan_huyen"
                                    name="dia_chi_cu_tru_tai_viet_nam_quan_huyen"
                                    value={validationSubmitEvisa.values.dia_chi_cu_tru_tai_viet_nam_quan_huyen}
                                    onChange={validationSubmitEvisa.handleChange}
                                    onBlur={validationSubmitEvisa.handleBlur}
                                    invalid={
                                      validationSubmitEvisa.touched.dia_chi_cu_tru_tai_viet_nam_quan_huyen &&
                                      validationSubmitEvisa.errors.dia_chi_cu_tru_tai_viet_nam_quan_huyen
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                              </Col>
                              <Col sm={2}>
                                <div>
                                  <Label htmlFor="formFile" className="form-label">
                                    {props.t("Wards/ communes/ towns")}:
                                  </Label>
                                  <Input
                                    disabled
                                    className="form-control"
                                    type="text"
                                    id="dia_chi_cu_tru_tai_viet_nam_phuong_xa"
                                    name="dia_chi_cu_tru_tai_viet_nam_phuong_xa"
                                    value={validationSubmitEvisa.values.dia_chi_cu_tru_tai_viet_nam_phuong_xa}
                                    onChange={validationSubmitEvisa.handleChange}
                                    onBlur={validationSubmitEvisa.handleBlur}
                                    invalid={
                                      validationSubmitEvisa.touched.dia_chi_cu_tru_tai_viet_nam_phuong_xa &&
                                      validationSubmitEvisa.errors.dia_chi_cu_tru_tai_viet_nam_phuong_xa
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                              </Col>
                              <Col sm={3}>
                                <div>
                                  <Label htmlFor="dia_chi_cu_tru_tai_viet_nam_chi_tiet" className="form-label">
                                    {props.t("Detailed residential address in Vietnam")}:
                                  </Label>
                                  <Input
                                    disabled
                                    className="form-control"
                                    type="text"
                                    id="dia_chi_cu_tru_tai_viet_nam_chi_tiet"
                                    name="dia_chi_cu_tru_tai_viet_nam_chi_tiet"
                                    value={validationSubmitEvisa.values.dia_chi_cu_tru_tai_viet_nam_chi_tiet}
                                    onChange={validationSubmitEvisa.handleChange}
                                    onBlur={validationSubmitEvisa.handleBlur}
                                    invalid={
                                      validationSubmitEvisa.touched.dia_chi_cu_tru_tai_viet_nam_chi_tiet &&
                                      validationSubmitEvisa.errors.dia_chi_cu_tru_tai_viet_nam_chi_tiet
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                              </Col>
                              <Col sm={3}>
                                <div>
                                  <Label htmlFor="formFile" className="form-label">
                                    {props.t("Phone number in Vietnam")}:
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    id="dia_chi_cu_tru_tai_viet_nam_so_dien_thoai"
                                    name="dia_chi_cu_tru_tai_viet_nam_so_dien_thoai"
                                    value={validationSubmitEvisa.values.dia_chi_cu_tru_tai_viet_nam_so_dien_thoai}
                                    onChange={validationSubmitEvisa.handleChange}
                                    onBlur={validationSubmitEvisa.handleBlur}
                                    invalid={
                                      validationSubmitEvisa.touched.dia_chi_cu_tru_tai_viet_nam_so_dien_thoai &&
                                      validationSubmitEvisa.errors.dia_chi_cu_tru_tai_viet_nam_so_dien_thoai
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                              </Col>
                            </Row>
                            <CardTitle className="mb-2 mt-3">{props.t("Funding, trip insurance")}:</CardTitle>
                            <Row>
                              <Col sm={4}>
                                <div>
                                  <Label htmlFor="kinh_phi_du_tinh" className="form-label">
                                    {props.t("Estimated cost(USD)")}:
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    id="kinh_phi_du_tinh"
                                    name="kinh_phi_du_tinh"
                                    value={validationSubmitEvisa.values.kinh_phi_du_tinh}
                                    onChange={validationSubmitEvisa.handleChange}
                                    onBlur={validationSubmitEvisa.handleBlur}
                                    invalid={
                                      validationSubmitEvisa.touched.kinh_phi_du_tinh &&
                                      validationSubmitEvisa.errors.kinh_phi_du_tinh
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                              </Col>
                              <Col sm={4}>
                                <div>
                                  <Label htmlFor="nguoi_dam_bao_kinh_phi_chuyen_di" className="form-label">
                                    {props.t("Person who guarantees trip expenses")}:
                                  </Label>
                                  <Select
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor:
                                          validationSubmitEvisa.touched.nguoi_dam_bao_kinh_phi_chuyen_di &&
                                          validationSubmitEvisa.errors.nguoi_dam_bao_kinh_phi_chuyen_di
                                            ? "red"
                                            : "#ced4da !important"
                                      })
                                    }}
                                    id="nguoi_dam_bao_kinh_phi_chuyen_di"
                                    name="nguoi_dam_bao_kinh_phi_chuyen_di"
                                    placeholder="Vui lòng chọn"
                                    options={nguoidambaokinhphi}
                                    defaultValue={nguoidambaokinhphi[1]}
                                    onBlur={validationSubmitEvisa.handleBlur}
                                    onChange={e => {
                                      validationSubmitEvisa.setFieldValue("nguoi_dam_bao_kinh_phi_chuyen_di", e.value)
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col sm={4}>
                                <div>
                                  <Label htmlFor="formFile" className="form-label">
                                    {props.t("Have you purchased insurance upon arrival")}:
                                  </Label>
                                  <Select
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor:
                                          validationSubmitEvisa.touched.da_mua_bao_hiem_chua &&
                                          validationSubmitEvisa.errors.da_mua_bao_hiem_chua
                                            ? "red"
                                            : "#ced4da !important"
                                      })
                                    }}
                                    id="da_mua_bao_hiem_chua"
                                    name="da_mua_bao_hiem_chua"
                                    placeholder="Vui lòng chọn"
                                    options={yes_no}
                                    defaultValue={yes_no[0]}
                                    onBlur={validationSubmitEvisa.handleBlur}
                                    onChange={e => {
                                      validationSubmitEvisa.setFieldValue("da_mua_bao_hiem_chua", e.value)
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </FormGroup>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                ) : (
                  <Card>
                    <CardBody className="d-flex align-items-center">
                      <Button className="bx bx-toggle-left" onClick={() => setDiachiovietnam(!diachiovietnam)}></Button>
                      <CardTitle className="mx-2 mb-0" onClick={() => setDiachiovietnam(!diachiovietnam)}>
                        5. {props.t("Address in Vietnam")}:
                      </CardTitle>
                    </CardBody>
                  </Card>
                )}

                <Row>
                  <Col lg="12">
                    <Button className="mx-auto d-block mb-4" type="submit" color="primary">
                      {props.t("Submit Profile")}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Container>

        <Modal
          isOpen={anhChanDung && modalImageEditorChanDung}
          toggle={() => setModalImageEditorChanDung(false)}
          size="xl"
        >
          {/*<ModalHeader toggle={() => setModalImageEditorChanDung(false)}>{props.t("Edit image portrait")}</ModalHeader>*/}
          <ModalBody>
            <ImageEditor
              ref={refImageEditorChanDung}
              useDefaultUI={true}
              includeUI={{
                loadImage: {
                  path: anhChanDung ? anhChanDung : null,
                  name: "image"
                },
                theme: whiteTheme,
                menu: ["resize", "crop", "flip", "rotate", "filter"],
                initMenu: "filter",
                uiSize: {
                  width: "1106px",
                  height: "700px"
                },
                menuBarPosition: "left"
              }}
              cssMaxHeight={500}
              cssMaxWidth={700}
              selectionStyle={{
                cornerSize: 20,
                rotatingPointOffset: 70
              }}
              usageStatistics={true}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleSaveImageEditorChanDung}>
              {props.t("Save")}
            </Button>{" "}
            <Button color="secondary" onClick={() => setModalImageEditorChanDung(false)}>
              {props.t("Cancel")}
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={anhHoChieu && modalImageEditorHoChieu}
          toggle={() => setModalImageEditorHoChieu(false)}
          size="xl"
        >
          {/*<ModalHeader toggle={() => setModalImageEditorHoChieu(false)}>{props.t("Edit image passport")}</ModalHeader>*/}
          <ModalBody>
            <ImageEditor
              ref={refImageEditorHoChieu}
              useDefaultUI={true}
              includeUI={{
                loadImage: {
                  path: anhHoChieu ? anhHoChieu : null,
                  name: "image"
                },
                theme: whiteTheme,
                menu: ["resize", "crop", "flip", "rotate", "filter"],
                initMenu: "filter",
                uiSize: {
                  width: "1106px",
                  height: "700px"
                },
                menuBarPosition: "left"
              }}
              cssMaxHeight={500}
              cssMaxWidth={700}
              selectionStyle={{
                cornerSize: 20,
                rotatingPointOffset: 70
              }}
              usageStatistics={true}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleSaveImageEditorHoChieu}>
              {props.t("Save")}
            </Button>{" "}
            <Button color="secondary" onClick={() => setModalImageEditorHoChieu(false)}>
              {props.t("Cancel")}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  )
}

Evisa.propTypes = {
  t: PropTypes.any
}
export default withRouter(withTranslation()(Evisa))
