import {
  Col,
  Row,
  Modal,
  ModalBody,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
  Card,
  CardBody
} from "reactstrap"
import TableContainer from "./Datatable/TableContainer"
import React, { useEffect, useMemo, useState, useRef } from "react"
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import withRouter from "components/Common/withRouter"
//formik
import * as Yup from "yup"
import { useFormik } from "formik"
//xlsx
import * as XLSX from "xlsx"
//services
import { DichVuCongCheckMahoso, CreateNewResultBca, CheckPassportValidDoitac } from "services"
import Swal from "sweetalert2"

import { STORAGE_KEY } from "configs/app.const"
import Cookies from "universal-cookie"

const ModalSubmitNew = props => {
  let { isOpen, toggle, gridRef } = props

  const cookies = new Cookies()
  const fileInputRef = useRef(null)
  const [excelData, setExcelData] = useState([])
  const [data, setData] = useState([])
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(true)

  const validationSubmit = useFormik({
    enableReinitialize: true,
    initialValues: {
      file: null,
      so_ho_so: "",
      ngay_nop: "",
      ngay_tiep_nhan: "",
      ngay_du_kien_tra: "",
      noi_dung_ycbs: "",
      trang_thai_ho_so: "",
      link_ket_qua: "",
      ten_cong_ty: "",
      ngay_tra_ket_qua: "",
      so_cong_van: "",
      fax: "",
      so_ho_chieu: "",
      so_khach: 0,
      ngay_nhap_canh: "",
      so_ngay_luu_tru: "",
      urgent: "",
      noi_nhan_thi_thuc: "",
      gia_tri_thi_thuc: "",
      quoc_tich: "",
      phan_tach_khach_hang: "",
      data_khachhang: []
    },
    validationSchema: Yup.object({
      file: Yup.mixed().required("Vui lòng chọn file khách hàng"),
      so_cong_van: Yup.string().required("Vui điền số công văn"),
      fax: Yup.string().required("Vui lòng chọn số FAX"),
      so_ho_so: Yup.string()
        .required()
        .test("check_sohoso", value => {
          if (!value) return true
          let regex = /^G(?:\d+\.){3}\d+-\d{6}-\d{5}$/
          return value.match(regex)
        })
    }),
    onSubmit: (values, { resetForm }) => {
      try {
        Swal.fire({
          title: "Đang gửi",
          icon: "warning",
          text: "Vui lòng chờ ...",
          showConfirmButton: false
        })
        values.data_khachhang = JSON.stringify(data)
        values.ma_nhan_vien = cookies.get(STORAGE_KEY.code)
        CreateNewResultBca(values)
          .then(res => {
            let data = res.data
            gridRef.current.api.applyTransaction({
              add: [
                {
                  ngay_nop: values.ngay_nop,
                  so_ho_so: values.so_ho_so,
                  trang_thai_ho_so: values.trang_thai_ho_so,
                  da_xu_ly_file: true,
                  link_detail: values.link_ket_qua,
                  ngay_tiep_nhan: values.ngay_tiep_nhan,
                  ten_cong_ty: values.ten_cong_ty,
                  fax: values.fax,
                  so_cong_van: values.so_cong_van,
                  so_ho_chieu: values.so_ho_chieu,
                  so_khach: values.so_khach,
                  so_ngay_luu_tru: values.so_ngay_luu_tru,
                  ngay_nhap_canh: values.ngay_nhap_canh
                }
              ],
              addIndex: 0
            })
            Swal.fire({
              title: "Thành công",
              icon: "success",
              text: "Hồ sơ đã được gửi thành công",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true
            })
            setIsDisabledSubmit(true)
            setData([])
            setExcelData([])
            resetForm()
            toggle()
          })
          .catch(error => {
            let statusCode = error.response.status
            if (statusCode === 400) {
              Swal.fire({
                title: "Lỗi",
                icon: "error",
                text: `Hồ sơ ${values.so_ho_so} đã được tạo`,
                showConfirmButton: false
              })
              validationSubmit.resetForm()
              setExcelData([])
              setData([])
              setIsDisabledSubmit(true)
            }
          })
      } catch (error) {
        console.error(error)
      }
    }
  })

  const collumns = useMemo(() => [
    {
      Header: props.t("Fullname"),
      accessor: "ho_va_ten",
      filterable: true,
      Cell: cellProps => {
        return <>{cellProps.value}</>
      }
    },
    {
      Header: props.t("Passport number"),
      accessor: "so_ho_chieu",
      filterable: true,
      Cell: cellProps => {
        return <>{cellProps.value}</>
      }
    },
    {
      Header: props.t("Gender"),
      accessor: "gioi_tinh",
      filterable: true,
      Cell: cellProps => {
        return <>{cellProps.value}</>
      }
    },
    {
      Header: props.t("Date of birth"),
      accessor: "dob",
      filterable: true,
      Cell: cellProps => {
        return <>{cellProps.value}</>
      }
    },
    {
      Header: props.t("Nationality"),
      accessor: "quoc_tich_hien_nay",
      filterable: true,
      Cell: cellProps => {
        return <>{cellProps.value}</>
      }
    },
    {
      Header: props.t("Date of Entry"),
      accessor: "de_nghi_tu_ngay",
      filterable: true,
      Cell: cellProps => {
        return <>{cellProps.value}</>
      }
    },
    {
      Header: props.t("Departure Date"),
      accessor: "den_ngay",
      filterable: true,
      Cell: cellProps => {
        return <>{cellProps.value}</>
      }
    },
    {
      Header: props.t("Multiple entry"),
      accessor: "gia_tri_thi_thuc",
      filterable: true,
      Cell: cellProps => {
        return <>{cellProps.value}</>
      }
    },
    {
      Header: props.t("Address to get"),
      accessor: "noi_nhan_thi_thuc",
      filterable: true,
      Cell: cellProps => {
        return <>{cellProps.value}</>
      }
    }
  ])

  //read xlsx
  const handleFileChange = e => {
    const file = e.target.files[0]

    if (!file) {
      return
    }

    const reader = new FileReader()

    reader.onload = event => {
      const data = event.target.result
      const workbook = XLSX.read(data, { type: "binary" })

      // Specify the sheet name
      const targetSheetName = "INF_NHAN_SU" // Change this to the desired sheet name

      if (!workbook.SheetNames.includes(targetSheetName)) {
        alert("Không đúng định dạng file")
        return
      }
      validationSubmit.handleChange(e)

      const sheet = workbook.Sheets[targetSheetName]
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 })
      jsonData.splice(0, 1)
      jsonData.reverse()
      setExcelData(jsonData)
    }

    reader.readAsBinaryString(file)
  }

  const handleCheckMahoso = () => {
    try {
      let so_ho_so = validationSubmit.values.so_ho_so
      if (so_ho_so !== "") {
        Swal.fire({
          title: props.t("Processing"),
          icon: "warning",
          text: props.t("Please waitting..."),
          showConfirmButton: false
        })
        let payload = [so_ho_so]
        DichVuCongCheckMahoso(payload)
          .then(res => {
            let data = res.data[0]
            if (data) {
              if (data.ngay_nop !== "") {
                setIsDisabledSubmit(false)
              } else {
                setIsDisabledSubmit(true)
              }
            }
            validationSubmit.setFieldValue("ngay_nop", data.ngay_nop)
            validationSubmit.setFieldValue("ngay_du_kien_tra", data.ngay_du_kien_tra)
            validationSubmit.setFieldValue("ten_cong_ty", data.cong_ty)
            validationSubmit.setFieldValue("ngay_tiep_nhan", data.ngay_tiep_nhan)
            validationSubmit.setFieldValue("trang_thai_ho_so", data.trang_thai_ho_so)
            validationSubmit.setFieldValue("ngay_tra_ket_qua", data.ngay_co_ket_qua)
            validationSubmit.setFieldValue("link_ket_qua", data.link_ket_qua)
            validationSubmit.setFieldValue("noi_dung_ycbs", data.noi_dung_ycbs)
            Swal.fire({
              title: props.t("Success"),
              icon: "success",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: false
            })
          })
          .catch(err => {
            console.error(err)
            Swal.fire({
              title: props.t("Error"),
              icon: "error",
              text: err.response.data,
              showConfirmButton: false
            })
          })
      }
    } catch (error) {}
  }

  const formattedDate = dateString => {
    const [day, month, year] = dateString.split("/")
    return new Date(`${year}-${month}-${day}`)
  }

  function getDistinctNumbersWithCount(numbers) {
    const countMap = {}

    // Count occurrences of each number
    for (const number of numbers) {
      countMap[number] = (countMap[number] || 0) + 1
    }

    // Extract distinct numbers and their counts
    const distinctNumbersWithCount = Object.entries(countMap).map(([number, count]) => ({
      number: parseInt(number, 10),
      count
    }))

    return distinctNumbersWithCount
  }

  function findNumberWithMaxCount(distinctNumbersWithCount) {
    let maxCount = 0
    let numberWithMaxCount

    for (const entry of distinctNumbersWithCount) {
      const { number, count } = entry

      if (count > maxCount || (count === maxCount && number < numberWithMaxCount)) {
        maxCount = count
        numberWithMaxCount = number
      }
    }

    return numberWithMaxCount
  }

  const handleCountSoNgayLuuTru = data => {
    let temp = []
    if (data.length > 0) {
      data.map((item, index) => {
        let startDate = formattedDate(item.de_nghi_tu_ngay)
        let endDate = formattedDate(item.den_ngay)
        let count = endDate - startDate + 1
        let totalDays = Math.ceil(count / (1000 * 60 * 60 * 24))
        temp.push(totalDays)
      })
    }
    let distinctNumbersWithCount = getDistinctNumbersWithCount(temp)
    validationSubmit.setFieldValue("so_ngay_luu_tru", findNumberWithMaxCount(distinctNumbersWithCount))
  }

  function findSmallestDate(dateArray) {
    let smallestDate = dateArray[0] // Assume the first date as the smallest

    for (const date of dateArray) {
      if (date < smallestDate) {
        smallestDate = date
      }
    }

    return smallestDate
  }

  const handleCountNgayNhapCanhSomNhat = data => {
    let temp = []
    if (data.length > 0) {
      data.map((item, index) => {
        let startDate = formattedDate(item.de_nghi_tu_ngay)
        temp.push(startDate)
      })
    }
    validationSubmit.setFieldValue("ngay_nhap_canh", findSmallestDate(temp))
  }

  useEffect(() => {
    if (excelData.length > 0) {
      let temp = []

      let noi_nhan_thi_thuc_item0 = excelData[0][14]
      let noi_nhan_thi_thuc = excelData[0][14]

      let quoc_tich = excelData[0][4]
      let quoc_tich_item0 = excelData[0][4]

      let gia_tri_thi_thuc = excelData[0][13]
      let gia_tri_thi_thuc_item0 = excelData[0][13]

      excelData.map((item, index) => {
        if (item.length > 0) {
          let item_convert = {
            id: item[0],
            ho_va_ten: item[1],
            dob: item[2],
            gioi_tinh: item[3],
            quoc_tich_hien_nay: item[4],
            de_nghi_tu_ngay: item[11],
            den_ngay: item[12],
            gia_tri_thi_thuc: item[13],
            noi_nhan_thi_thuc: item[14],
            so_ho_chieu: item[8]
          }
          if (noi_nhan_thi_thuc_item0 !== item[14]) {
            noi_nhan_thi_thuc = ""
          }

          if (gia_tri_thi_thuc_item0 !== item[13]) {
            gia_tri_thi_thuc = ""
          }

          if (quoc_tich_item0 !== item[4]) {
            quoc_tich = ""
          }

          temp.push(item_convert)
        }
      })
      validationSubmit.setFieldValue("noi_nhan_thi_thuc", noi_nhan_thi_thuc)
      validationSubmit.setFieldValue("gia_tri_thi_thuc", gia_tri_thi_thuc)
      validationSubmit.setFieldValue("quoc_tich", quoc_tich)

      CheckPassportValidDoitac({ data: JSON.stringify(temp) }).then(res => {
        let data = res.data
        if (data.total === temp.length) {
          let error_message = ""
          let error_message_2 = ""

          let urgent = data.result[0].urgent
          let urgent_item0 = data.result[0].urgent

          let phan_tach_khach_hang = data.result[0].phan_tach_khach_hang
          let phan_tach_khach_hang_item0 = data.result[0].phan_tach_khach_hang

          data.result.map(item => {
            if (item.status === false) {
              error_message += `${item.so_ho_chieu} `
            }

            if (item.da_nop === true) {
              error_message_2 += `${item.so_ho_chieu} `
            }
            if (urgent_item0 !== item.urgent) {
              urgent = ""
            }
            if (phan_tach_khach_hang_item0 !== item.phan_tach_khach_hang) {
              phan_tach_khach_hang = ""
            }
          })
          validationSubmit.setFieldValue("urgent", urgent)
          validationSubmit.setFieldValue("phan_tach_khach_hang", phan_tach_khach_hang)

          if (error_message !== "" || error_message_2 !== "") {
            if (fileInputRef.current) {
              fileInputRef.current.value = ""
            }
            Swal.fire({
              title: props.t("Error"),
              icon: "error",
              text:
                (error_message !== "" ? "Số hộ chiếu: " + error_message + " chưa được ghi nhận trên hệ thống \n" : "") +
                (error_message_2 !== "" ? "Số hộ chiếu: " + error_message_2 + " đã được nộp" : ""),
              showConfirmButton: false
            })
          } else {
            validationSubmit.setFieldValue("so_ho_chieu", temp[0].so_ho_chieu)
            validationSubmit.setFieldValue("so_khach", temp.length)
            handleCountSoNgayLuuTru(temp)
            handleCountNgayNhapCanhSomNhat(temp)
            setData(temp)
          }
        }
      })
    }
  }, [excelData])

  return (
    <React.Fragment>
      <Modal size="xl" isOpen={isOpen} toggle={toggle} className="modal-dialog" fullscreen>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
            {props.t("Submit new result")}
          </h5>
          <button
            onClick={() => {
              toggle()
              setData([])
              validationSubmit.resetForm()
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>
          <Container fluid>
            <Row>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validationSubmit.handleSubmit()
                  return false
                }}
              >
                <div className="d-flex justify-content-between mb-3">
                  <Col lg={4} sm={3} className="me-4">
                    <Card>
                      <CardBody>
                        {/* <h4 className="pb-3">Thông tin hồ sơ chi tiết</h4> */}
                        <Label htmlFor="so_ho_so" className="">
                          {props.t("File number")} <span className="text-danger">*</span>
                        </Label>
                        <div className="hstack gap-3 mb-3 d-flex justify-content-between">
                          <Input
                            id="so_ho_so"
                            name="so_ho_so"
                            value={validationSubmit.values.so_ho_so}
                            onChange={validationSubmit.handleChange}
                            onBlur={validationSubmit.handleBlur}
                            type="text"
                            className="form-control w-75"
                            placeholder="G01.108.000.000-231219-00287"
                            invalid={
                              validationSubmit.touched.so_ho_so && validationSubmit.errors.so_ho_so ? true : false
                            }
                          />
                          <button
                            disabled={
                              (validationSubmit.touched.so_ho_so && validationSubmit.errors.so_ho_so) ||
                              validationSubmit.values.so_ho_so === ""
                                ? true
                                : false
                            }
                            type="button"
                            className="btn btn-secondary w-50"
                            onClick={handleCheckMahoso}
                          >
                            {props.t("Check")}
                          </button>
                        </div>
                        <div className="mb-3 d-flex justify-content-between">
                          <div className="me-2">
                            <Label htmlFor="" className="">
                              {props.t("Document number")} <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="so_cong_van"
                              className="form-control"
                              placeholder="CV-01"
                              value={validationSubmit.values.so_cong_van}
                              onChange={validationSubmit.handleChange}
                              onBlur={validationSubmit.handleBlur}
                              invalid={
                                validationSubmit.touched.so_cong_van && validationSubmit.errors.so_cong_van
                                  ? true
                                  : false
                              }
                            />
                          </div>
                          <div className="me-2">
                            <Label htmlFor="" className="">
                              {props.t("FAX")} <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="select"
                              name="fax"
                              className="form-control"
                              value={validationSubmit.values.fax}
                              onChange={validationSubmit.handleChange}
                              onBlur={validationSubmit.handleBlur}
                              invalid={validationSubmit.touched.fax && validationSubmit.errors.fax ? true : false}
                            >
                              <option value="">Vui lòng chọn</option>
                              <option value="0">0 FAX</option>
                              <option value="1">1 FAX</option>
                              <option value="2">2 FAX</option>
                              <option value="3">3 FAX</option>
                              <option value="4">4 FAX</option>
                              <option value="5">5 FAX</option>
                              <option value="6">6 FAX</option>
                              <option value="7">7 FAX</option>
                              <option value="8">8 FAX</option>
                              <option value="9">9 FAX</option>
                              <option value="10">10 FAX</option>
                              <option value="11">11 FAX</option>
                              <option value="12">12 FAX</option>
                              <option value="13">13 FAX</option>
                              <option value="14">14 FAX</option>
                              <option value="15">15 FAX</option>
                            </Input>
                          </div>
                          <div className="">
                            <Label htmlFor="file" className="">
                              {props.t("Visistor file (xlsx)")} <span className="text-danger">*</span>
                            </Label>
                            <Col>
                              <Input
                                id="file"
                                name="file"
                                onChange={e => {
                                  handleFileChange(e)
                                }}
                                innerRef={fileInputRef}
                                // value={validationSubmit.values.file}
                                onBlur={validationSubmit.handleBlur}
                                type="file"
                                className="form-control"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                invalid={validationSubmit.touched.file && validationSubmit.errors.file ? true : false}
                              />
                              {/* {validationSubmit.touched.file &&
                              validationSubmit.errors.file ? (
                                <FormFeedback type="invalid">
                                  {validationSubmit.errors.file}
                                </FormFeedback>
                              ) : (
                                ""
                              )} */}
                            </Col>
                          </div>
                        </div>
                        <div className="d-flex justify-content-center">
                          <div>
                            <Button type="submit" color="success" disabled={isDisabledSubmit}>
                              {props.t("Submit result")}
                            </Button>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg={8} sm={7}>
                    <Card>
                      <CardBody>
                        <div className="mb-3">
                          <h4 className="pb-3">{props.t("Result Detail")}</h4>
                          <h5>
                            <b>{props.t("Status")}:</b> {validationSubmit.values.trang_thai_ho_so}
                          </h5>
                          <h5>
                            <b>{props.t("Submit date")}:</b> {validationSubmit.values.ngay_nop}
                          </h5>
                          <h5>
                            <b>{props.t("Date of reception")}:</b> {validationSubmit.values.ngay_tiep_nhan}
                          </h5>
                          <h5>
                            <b>{props.t("Result link")}:</b>{" "}
                            <a href={validationSubmit.values.link_ket_qua}>{validationSubmit.values.link_ket_qua}</a>
                          </h5>
                          <h5>
                            <b>{props.t("Company Name")}:</b> {validationSubmit.values.ten_cong_ty}
                          </h5>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </div>
              </Form>
              <div>
                <TableContainer
                  columns={collumns}
                  data={data}
                  isGlobalFilter={true}
                  isAddOptions={false}
                  customPageSize={10}
                />
              </div>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

ModalSubmitNew.propTypes = {
  t: PropTypes.any
}

export default withRouter(withTranslation()(ModalSubmitNew))
