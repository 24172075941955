import React, { forwardRef, useImperativeHandle } from "react"
import { Button } from "reactstrap"
import { useTranslation } from "react-i18next"
import Swal from "sweetalert2"

import { RemovePassport } from "services"

const ButtonRenderer = forwardRef((props, ref) => {
  const { t } = useTranslation()
  const { gridRef } = props
  useImperativeHandle(ref, () => {
    return {
      refresh(params) {}
    }
  })
  return (
    <>
      <Button
        color="danger"
        onClick={() => {
          Swal.fire({
            title: t("Are you sure?"),
            icon: "warning",
            text: "",
            showCancelButton: true,
            showConfirmButton: true,
            reverseButtons: true,
            cancelButtonText: t("Cancel"),
            confirmButtonText: t("Confirm")
          }).then(res => {
            if (res.isConfirmed) {
              const id = props.data._id
              RemovePassport(id)
                .then(res => {
                  gridRef.current.api.applyTransaction({ remove: [props.node.data] })
                  Swal.fire({
                    title: t("Success"),
                    icon: "success",
                    timer: 3000,
                    timerProgressBar: true
                  })
                })
                .catch(err => {
                  Swal.fire({
                    title: t("Error"),
                    icon: "error",
                    timer: 3000,
                    timerProgressBar: true
                  })
                })
            }
          })
        }}
      >
        {t("Delete")}
      </Button>
    </>
  )
})

ButtonRenderer.displayName = "MyImageRenderer"

export default ButtonRenderer
