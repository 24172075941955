import React, { forwardRef, useImperativeHandle, useState } from "react"
import { Button } from "reactstrap"
import { useTranslation } from "react-i18next"

import { GetOneDVCHoSo } from "services"

const ButtonRenderer = forwardRef((props, ref) => {
  const { t } = useTranslation()

  useImperativeHandle(ref, () => {
    return {
      refresh(params) {}
    }
  })
  return (
    <>
      <Button
        color={props.color}
        onClick={e => {
          // props.navigate('/result/bca/list', {state: {ma_ho_so: props.value}});
          GetOneDVCHoSo(props.data.mahoso)
            .then(res => {
              props.data.thong_tin_ho_so = res.data.data
              props.setItemDetail(props.data)
              props.toggleDetail()
            })
            .catch(err => {})
        }}
      >
        {t("Detail")}
      </Button>
    </>
  )
})

ButtonRenderer.displayName = "MyImageRenderer"

export default ButtonRenderer
