import React, { forwardRef, useImperativeHandle, useState } from "react"
import { useTranslation } from "react-i18next"

const ButtonRenderer = forwardRef((props, ref) => {
  const { t } = useTranslation()

  const [haveDetail, setHaveDetail] = useState(props.value)

  useImperativeHandle(ref, () => {
    return {
      refresh(params) {}
    }
  })

  const converTrangThai = value => {
    switch (Number(value)) {
      case 30:
        return "text-success"
      case 90:
        return "text-primary"
      default:
        return ""
    }
  }

  return (
    <>
      {haveDetail && (
        <span
          className={converTrangThai(props.value)}
          // style={{color: "#fffff"}}
        >
          {props.value}
        </span>
      )}
    </>
  )
})

ButtonRenderer.displayName = "MyImageRenderer"

export default ButtonRenderer
