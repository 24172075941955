import React, { useEffect, useState, useRef, useContext, useMemo } from "react"
import { Button, Col, Container, Row } from "reactstrap"

import { AgGridReact } from "ag-grid-react" // React Grid Logic
import "ag-grid-enterprise"
import { LicenseManager } from "ag-grid-enterprise"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-alpine.css"

import moment from "moment"

import { useNavigate } from "react-router-dom"

import { useTranslation } from "react-i18next"
import Breadcrumb from "components/Common/Breadcrumb"
import ModalDetail from "./Modal"
import ModalDetailCv from "./Modal/detail"

//Cell rendering
import AgButtonImage from "./AgCellRendering/AgButtonImage"
import AgButtonDetail from "./AgCellRendering/AgButtonDetail"
import AgText from "./AgCellRendering/AgText"
import AgTextSpan from "./AgCellRendering/AgTextSpan"
import AgVip from "./AgCellRendering/AgVip"

//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//import react-select
import Select from "react-select"

//config
import { AG_GRID_KEY } from "configs"

//services
import { GetPassportSubmittedBCA } from "services"
import { getHeight } from "helpers/data_grid_helper"

// Loading
import LoadingContext from "context/loading.context"

//set lic for ag-grid
LicenseManager.setLicenseKey(AG_GRID_KEY)

const ListSubmittedBca = props => {
  const { t } = useTranslation()
  const [data, setData] = useState([])
  const gridRef = useRef()
  const [heightTable, setHeightTable] = useState(500)
  const [item, setItem] = useState({})
  const [isOpen, setIsOpen] = useState(false)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [selectedDate, setSelectedDate] = useState()

  const [itemDetail, setItemDetail] = useState({})
  const [isOpenDetail, setIsOpenDetail] = useState(false)
  const { setLoading } = useContext(LoadingContext)

  const navigate = useNavigate()

  const toggle = () => {
    if (isOpen) {
      setIsOpen(false)
      setItem({})
    } else {
      setIsOpen(true)
    }
  }

  const toggleDetail = () => {
    if (isOpenDetail) {
      setIsOpenDetail(false)
    } else {
      setIsOpenDetail(true)
    }
  }

  const optionDateSelect = [
    { label: "Hôm nay", value: "today" },
    { label: "Hôm qua", value: "yesterday" },
    { label: "3 ngày trước", value: "3daysago" },
    { label: "7 ngày trước", value: "7daysago" },
    { label: "15 ngày trước", value: "15daysago" },
    { label: "30 ngày trước", value: "30daysago" }
  ]

  const handleDateSelect = value => {
    let today = new Date()
    let last_date = new Date()
    last_date.setDate(today.getDate() - 1)
    setEndDate(last_date)
    switch (value) {
      case "today":
        setStartDate(today)
        setEndDate(today)
        break
      case "yesterday":
        let yesterday = new Date(today)
        yesterday.setDate(today.getDate() - 1)
        setStartDate(yesterday)
        break
      case "3daysago":
        let threedaysago = new Date(today)
        threedaysago.setDate(today.getDate() - 3)
        setStartDate(threedaysago)
        break
      case "7daysago":
        let sevendaysago = new Date(today)
        sevendaysago.setDate(today.getDate() - 7)
        setStartDate(sevendaysago)
        break
      case "15daysago":
        let fifteendaysago = new Date(today)
        fifteendaysago.setDate(today.getDate() - 15)
        setStartDate(fifteendaysago)
        break
      case "30daysago":
        let thirtydaysago = new Date(today)
        thirtydaysago.setDate(today.getDate() - 30)
        setStartDate(thirtydaysago)
        break
      default:
        setStartDate()
        setEndDate()
        setSelectedDate(null)
        break
    }
  }

  const validateStartAndEnd = () => {
    if (startDate && endDate) {
      if (startDate > endDate) {
        return true
      } else {
        return false
      }
    }
    return null
  }

  /* Config for ag grid */
  const columnDefs = [
    {
      headerName: t("Date Created"),
      field: "createAt",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
      sortable: true,
      pinned: "left"
    },
    {
      headerName: t("Fullname"),
      field: "fullname",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
      sortable: true,
      pinned: "left"
    },
    {
      headerName: t("Image"),
      field: "passport_img",
      resizable: true,
      cellRendererSelector: params => {
        const image = {
          component: AgButtonImage
        }
        if (params.data) {
          return image
        }
      },
      cellRendererParams: {
        setItem: setItem,
        toggle: toggle,
        color: "danger"
      },
      pinned: "left"
    },
    {
      headerName: t("Detail"),
      field: "mahoso",
      resizable: true,
      cellRendererSelector: params => {
        const detail = {
          component: AgButtonDetail
        }
        if (params.data) {
          return detail
        }
      },
      cellRendererParams: {
        navigate,
        color: "danger",
        toggleDetail,
        setItemDetail
      },
      pinned: "left"
    },
    {
      headerName: t("File number"),
      field: "mahoso",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
      sortable: true
      // minWidth: 250
    },
    {
      headerName: t("Customer segmentation"),
      field: "phantachkhachhang",
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      resizable: true,
      sortable: true
    },
    {
      headerName: t("Date of birth"),
      field: "dob",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
      sortable: true
    },
    {
      headerName: t("Gender"),
      field: "gender",
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      resizable: true,
      sortable: true
    },
    {
      headerName: t("Passport number"),
      field: "personal_id",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
      sortable: true
    },
    {
      headerName: t("Urgent"),
      field: "urgent_title",
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      resizable: true,
      sortable: true,
      cellRendererSelector: params => {
        const text = {
          component: AgText
        }
        if (params.data) {
          return text
        }
      }
    },
    {
      headerName: t("Value of visa"),
      field: "multipleEntry_title",
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      resizable: true,
      cellRendererSelector: params => {
        const text = {
          component: AgTextSpan
        }
        if (params.data) {
          return text
        }
      }
    },
    {
      headerName: t("Number of days of stay"),
      field: "stayIn",
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      resizable: true,
      sortable: true
    },
    {
      headerName: t("Date of Entry"),
      field: "entry_date",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
      sortable: true
    },
    {
      headerName: t("Departure Date"),
      field: "departure_date",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
      sortable: true
    },
    {
      headerName: t("Nationality"),
      field: "country",
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      resizable: true,
      sortable: true
    },
    // {
    //   headerName: t("Issuance date"),
    //   field: "issuance_date",
    //   filter: "agTextColumnFilter",
    //   floatingFilter: true,
    //   resizable: true,
    //   sortable: true,
    // },
    // {
    //   headerName: t("Expiration Date"),
    //   field: "expridation_date",
    //   filter: "agTextColumnFilter",
    //   floatingFilter: true,
    //   resizable: true,
    //   sortable: true,
    // },
    {
      headerName: t("Vip"),
      field: "vip",
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      resizable: true,
      sortable: true,
      cellRendererSelector: params => {
        const text = {
          component: AgVip
        }
        if (params.data) {
          return text
        }
      }
    },
    {
      headerName: t("Visa receiving place"),
      field: "addToGetVisa",
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      resizable: true,
      sortable: true,
      minWidth: 500
    },
    {
      headerName: t("Employee Submits"),
      field: "ma_nhan_vien",
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      resizable: true,
      sortable: true
    }
  ]
  const defaultColDef = useMemo(() => {
    return {
      // flex: 1,
      minWidth: 100,
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }
    }
  }, [])

  let gridStyle = { minHeight: 750, marginTop: 10 }
  if (heightTable) {
    gridStyle.minHeight = "auto"
    gridStyle.height = heightTable + "px"
  }

  useEffect(() => {
    const height = getHeight(62)
    setHeightTable(height)
  }, [])

  window.addEventListener("resize", function () {
    const height = getHeight(62)
    setHeightTable(height)
  })

  const formatDate = date => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")
    return `${year}-${month}-${day}`
  }

  // const onGridReady = useCallback((params) => {
  //   GetPassportSubmittedBCA().then((res) => {
  //     let result = res.data
  //     let temp = []
  //     result.map((item, index) => {
  //       item.createAt = moment(item.createAt).format("DD/MM/YYYY HH:mm:ss")
  //       item.fullname = item.surname + " " + item.name
  //       item.gender = item.sex === true ? t("Male") : t("Female")
  //       item.urgent_title = item && item.fee !== undefined ? item.fee.title_vn : ""
  //       item.vip = item.vip === true ? t("Yes") : t("No")
  //       item.multipleEntry_title = Number(item.multipleEntry) === 1 ? t("Many times"): t("Once time")
  //       temp.push(item)
  //     })
  //     setData(temp)
  //   })
  // }, [])

  /* End config for ag grid */

  useEffect(() => {
    let payload = {}
    if (startDate && endDate) {
      let status = validateStartAndEnd()
      if (status === false) {
        payload.startDate = formatDate(startDate)
        payload.endDate = formatDate(endDate)
      }
    }
    setLoading(true)
    GetPassportSubmittedBCA(payload)
      .then(res => {
        let result = res.data
        let temp = []
        result.map((item, index) => {
          item.createAt = moment(item.createAt).format("DD/MM/YYYY HH:mm:ss")
          item.fullname = item.surname + " " + item.name
          item.gender = item.sex === true ? t("Male") : t("Female")
          item.urgent_title = item && item.fee !== undefined ? item.fee.title_vn : ""
          item.vip = item.vip === true ? t("Yes") : t("No")
          item.multipleEntry_title = Number(item.multipleEntry) === 1 ? t("Many times") : t("Once time")
          temp.push(item)
        })

        // Deplay loading
        if (res.length > 100) {
          setLoading(false)
          setData(temp)
        } else {
          setTimeout(() => {
            setLoading(false)
            setData(temp)
          }, 500)
        }
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
      })
  }, [startDate, endDate])

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agFilteredRowCountComponent", align: "left" },
        { statusPanel: "agAggregationComponent", align: "left" },
        { statusPanel: "agSelectedRowCountComponent" }
      ]
    }
  }, [])

  return (
    <>
      <div className="page-content">
        <Container className="body" fluid={true}>
          <Breadcrumb title={t("Normal Profile")} breadcrumbItem={t("Submitted List BCA")} />
          <ModalDetail isOpen={isOpen} item={item} toggle={toggle} />
          <ModalDetailCv isOpen={isOpenDetail} item={itemDetail} toggle={toggleDetail} />
          <Row>
            <Col>
              <div className="js-height">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="d-flex me-3">
                      <Button
                        className="me-2"
                        onClick={() => {
                          gridRef.current.api.deselectAll()
                        }}
                      >
                        {t("Clear row selected")}
                      </Button>
                      <Button
                        className=""
                        onClick={() => {
                          gridRef.current.api.setFilterModel(null)
                        }}
                      >
                        {t("Clear filter")}
                      </Button>
                    </div>
                    <div className="d-flex me-3" style={{ height: "20px" }}>
                      <div className="vr"></div>
                    </div>
                    <div className="me-2">
                      <DatePicker
                        placeholderText="dd/mm/yyyy"
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        selected={startDate || ""}
                        onChange={e => {
                          setSelectedDate(null)
                          setStartDate(e)
                        }}
                      />
                    </div>
                    <div className="me-2">
                      <DatePicker
                        placeholderText="dd/mm/yyyy"
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        selected={endDate || ""}
                        onChange={e => {
                          setSelectedDate(null)
                          setEndDate(e)
                        }}
                      />
                    </div>
                    <div className="me-2">
                      <Select
                        menuPortalTarget={document.body}
                        styles={{
                          menu: provided => ({ ...provided, zIndex: 9999 })
                        }}
                        options={optionDateSelect}
                        value={selectedDate}
                        placeholder={t("Select time")}
                        onChange={e => {
                          setSelectedDate(e)
                          handleDateSelect(e.value)
                        }}
                      />
                    </div>
                    <Button
                      onClick={() => {
                        handleDateSelect()
                      }}
                      color="danger"
                    >
                      {" "}
                      {t("Reset")}{" "}
                    </Button>
                  </div>
                </div>
                {validateStartAndEnd() === true && (
                  <p className="font-weight-400 mb-0 text-danger pb-2 js-height">
                    {t("Check your start or end date again")}.
                  </p>
                )}
              </div>
            </Col>
          </Row>
          <div className="ag-theme-alpine" style={gridStyle}>
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              onFirstDataRendered={params => {
                params.columnApi.autoSizeAllColumns()
                params.columnApi.autoSizeAllColumns()
              }}
              // onGridReady={onGridReady}
              rowData={data}
              rowSelection={"multiple"}
              suppressRowClickSelection={true}
              // colResizeDefault={true}
              pagination={true}
              enableCellChangeFlash={true}
              enableRangeSelection={true}
              statusBar={statusBar}
              // Hidden loading overlay
              overlayLoadingTemplate="<span></span>"
            />
          </div>
        </Container>
      </div>
    </>
  )
}

export default ListSubmittedBca
