import React, { useEffect, useState, useRef, useMemo, useContext } from "react"
import { Button, Col, Container, Row } from "reactstrap"

import { AgGridReact } from "ag-grid-react" // React Grid Logic
import "ag-grid-enterprise"
import { LicenseManager } from "ag-grid-enterprise"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-alpine.css"

//cell renderer
import AgText from "./AgCellRendering/AgText"
import AgTextSpan from "./AgCellRendering/AgTextSpan"
import AgVip from "./AgCellRendering/AgVip"

import ButtonRenderer from "components/Common/AgCellRendering/AgButton"
import ButtonDelete from "./AgCellRendering/AgButtonDelete"

import { useTranslation } from "react-i18next"
import Breadcrumb from "components/Common/Breadcrumb"

import Modal from "./Modal"

//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//import react-select
import Select from "react-select"

//config
import { AG_GRID_KEY, API_URL_PYTHON_EXPORT_EXCEL } from "configs"
//services
import { GetSentList } from "services"
import moment from "moment"
import { getHeight } from "../../helpers/data_grid_helper"

// Loading
import LoadingContext from "context/loading.context"

//set lic for ag-grid
LicenseManager.setLicenseKey(AG_GRID_KEY)

const SentList = props => {
  const { t } = useTranslation()
  const gridRef = useRef()
  const [item, setItem] = useState({})
  const [isOpen, setIsOpen] = useState(false)
  const [heightTable, setHeightTable] = useState(500)
  const [rowData, setRowData] = useState()
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [selectedDate, setSelectedDate] = useState()
  const [selected, setSelected] = useState([])
  const { setLoading } = useContext(LoadingContext)

  const toggle = () => {
    if (isOpen) {
      setIsOpen(false)
      setItem({})
    } else {
      setIsOpen(true)
    }
  }

  /* Config for ag grid */
  const columnDefs = [
    {
      headerName: t("Date Created"),
      field: "createAt",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      resizable: true,
      sortable: true,
      pinned: "left"
    },
    {
      headerName: t("Customer Name"),
      field: "name",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
      sortable: true,
      pinned: "left"
    },
    {
      headerName: t("Image"),
      resizable: true,
      field: "passport_img",
      cellRendererSelector: params => {
        const moodDetails = {
          component: ButtonRenderer
        }
        if (params.data) {
          return moodDetails
        }
      },
      cellRendererParams: {
        setItem: setItem,
        toggle: toggle,
        view: true
      },
      maxWidth: 100,
      pinned: "left"
    },
    {
      headerName: t("Delete"),
      field: "_id",
      resizable: true,
      cellRendererSelector: params => {
        const moodDetails = {
          component: ButtonDelete
        }
        if (params.data) {
          return moodDetails
        }
      },
      cellRendererParams: {
        gridRef
      },
      maxWidth: 100,
      pinned: "left"
    },
    {
      headerName: t("Customer segmentation"),
      field: "phantachkhachhang",
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      resizable: true
    },
    {
      headerName: t("Date of birth"),
      field: "dob",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true
    },
    {
      headerName: t("Gender"),
      field: "gender",
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      resizable: true,
      sortable: true
    },
    {
      headerName: t("Passport number"),
      field: "personal_id",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true
    },
    {
      headerName: t("Urgent"),
      field: "urgent",
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      resizable: true,
      cellRendererSelector: params => {
        const text = {
          component: AgText
        }
        if (params.data) {
          return text
        }
      }
    },
    {
      headerName: t("Value of visa"),
      field: "multipleEntry_title",
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      resizable: true,
      cellRendererSelector: params => {
        const text = {
          component: AgTextSpan
        }
        if (params.data) {
          return text
        }
      }
    },
    // {
    //   headerName: t("Issuance date"),
    //   field: "issuance_date",
    //   filter: "agTextColumnFilter",
    //   floatingFilter: true,
    //   resizable: true,
    // },
    // {
    //   headerName: t("Expiration Date"),
    //   field: "expridation_date",
    //   filter: "agTextColumnFilter",
    //   floatingFilter: true,
    //   resizable: true,
    // },
    {
      headerName: t("Number of days of stay"),
      field: "stayIn",
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      resizable: true
    },
    {
      headerName: t("Date of Entry"),
      field: "entry_date",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true
    },
    {
      headerName: t("Departure Date"),
      field: "departure_date",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true
    },
    // {
    //   headerName: t("How to get a visa"),
    //   field: "wayToGetVisa",
    //   filter: "agTextColumnFilter",
    //   floatingFilter: true,
    //   resizable: true,
    // },
    {
      headerName: t("Nationality"),
      field: "country",
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      resizable: true
    },
    {
      headerName: t("Vip"),
      field: "vip",
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      resizable: true,
      cellRendererSelector: params => {
        const text = {
          component: AgVip
        }
        if (params.data) {
          return text
        }
      }
    },
    {
      headerName: t("Issuance date"),
      field: "issuance_date",
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      resizable: true
    },
    {
      headerName: t("Expiration Date"),
      field: "expridation_date",
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      resizable: true
    },
    {
      headerName: t("Visa receiving place"),
      field: "addToGetVisa",
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      resizable: true,
      sortable: true,
      minWidth: 500
    },
    {
      headerName: t("Staff"),
      field: "ma_nhan_vien",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true
    }
  ]
  const defaultColDef = useMemo(() => {
    return {
      // flex: 1,
      // minWidth: 100,
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }
    }
  }, [])

  let gridStyle = { minHeight: 750, marginTop: 10 }

  if (heightTable) {
    gridStyle.minHeight = "auto"
    gridStyle.height = heightTable + "px"
  }

  useEffect(() => {
    const height = getHeight(62)
    setHeightTable(height)
  }, [])

  window.addEventListener("resize", function () {
    const height = getHeight(62)
    setHeightTable(height)
  })

  const formatDate = date => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")
    return `${year}-${month}-${day}`
  }

  const optionDateSelect = [
    { label: "Hôm nay", value: "today" },
    { label: "Hôm qua", value: "yesterday" },
    { label: "3 ngày trước", value: "3daysago" },
    { label: "7 ngày trước", value: "7daysago" },
    { label: "15 ngày trước", value: "15daysago" },
    { label: "30 ngày trước", value: "30daysago" }
  ]

  const handleDateSelect = value => {
    let today = new Date()
    let last_date = new Date()
    last_date.setDate(today.getDate() - 1)
    setEndDate(last_date)
    switch (value) {
      case "today":
        setStartDate(today)
        setEndDate(today)
        break
      case "yesterday":
        let yesterday = new Date(today)
        yesterday.setDate(today.getDate() - 1)
        setStartDate(yesterday)
        break
      case "3daysago":
        let threedaysago = new Date(today)
        threedaysago.setDate(today.getDate() - 3)
        setStartDate(threedaysago)
        break
      case "7daysago":
        let sevendaysago = new Date(today)
        sevendaysago.setDate(today.getDate() - 7)
        setStartDate(sevendaysago)
        break
      case "15daysago":
        let fifteendaysago = new Date(today)
        fifteendaysago.setDate(today.getDate() - 15)
        setStartDate(fifteendaysago)
        break
      case "30daysago":
        let thirtydaysago = new Date(today)
        thirtydaysago.setDate(today.getDate() - 30)
        setStartDate(thirtydaysago)
        break
      default:
        setStartDate()
        setEndDate()
        setSelectedDate(null)
        break
    }
  }

  const validateStartAndEnd = () => {
    if (startDate && endDate) {
      if (startDate > endDate) {
        return true
      } else {
        return false
      }
    }
    return null
  }
  useEffect(() => {
    let payload = {}
    if (startDate && endDate) {
      let status = validateStartAndEnd()
      if (status === false) {
        payload.startDate = formatDate(startDate)
        payload.endDate = formatDate(endDate)
      }
    }
    setLoading(true)
    GetSentList(payload)
      .then(res => {
        let response = res.data
        let temp = []
        response.map((item, index) => {
          item.createAt = moment(item.createAt).format("DD/MM/YYYY HH:mm:ss")
          item.stayIn = Number(item.stayIn)
          item.name = item.surname + " " + item.name
          item.gender = item.sex === true ? t("Male") : t("Female")
          item.urgent = item?.fee?.title_vn
          item.vip = item.vip === true ? t("Yes") : t("No")
          item.multipleEntry_title = Number(item.multipleEntry) === 1 ? t("Many times") : t("Once time")
          temp.push(item)
        })

        // Deplay loading
        if (response.length > 100) {
          setLoading(false)
          setRowData(temp)
        } else {
          setTimeout(() => {
            setLoading(false)
            setRowData(temp)
          }, 500)
        }
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
      })
  }, [t, startDate, endDate])
  /* End config ag grid */

  /*Export excel format */
  const getAge = dob => {
    const dobComponents = dob.split("/")
    // Create a Date object using the components
    const birthDate = new Date(`${dobComponents[2]}-${dobComponents[1]}-${dobComponents[0]}`)
    // Get the current date
    const currentDate = new Date()
    // Calculate the difference in years
    const age = currentDate.getFullYear() - birthDate.getFullYear()
    // Adjust the age if the birthday hasn't occurred yet this year
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())
    ) {
      return age - 1
    } else {
      return age
    }
  }

  const getJobByAge = age => {
    if (age <= 6 && age >= 0) {
      return "Trẻ em"
    } else if (age <= 18 && age >= 7) {
      return "Học sinh"
    } else if (age <= 55 && age >= 18) {
      return "Nhân viên"
    } else if (age > 55) {
      return "Hưu trí"
    } else {
      return "Nhân viên"
    }
  }

  // Handle export CSV
  const exportCsv = () => {
    let data = selected
    if (data.length === 0) {
      return
    } else {
      let data_to_send = []
      let id = 1
      data.forEach(item => {
        let age = getAge(item.dob)
        let job = getJobByAge(age)
        data_to_send.push([
          id,
          item.name,
          item.dob,
          item.gender,
          item.country,
          "",
          job,
          "",
          item.personal_id,
          "Hộ chiếu phổ thông",
          "du lịch",
          item.entry_date,
          item.departure_date,
          Number(item.multipleEntry) === 1 ? "Nhiều lần" : "Một lần",
          item.addToGetVisa
        ])
        id++
      })
      fetch(API_URL_PYTHON_EXPORT_EXCEL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data_to_send)
      })
        .then(response => response.blob())
        .then(blob => {
          // Handle the successful response here
          // The blob will contain the binary XLSX data
          // Save the blob as a file with the name 'export.xlsx'
          const downloadLink = document.createElement("a")
          downloadLink.href = URL.createObjectURL(blob)
          downloadLink.setAttribute("download", "ThongTinHoChieu.xlsx")
          downloadLink.click()
        })
        .catch(error => {
          // Handle the error here
          console.error("Error exporting XLSX:", error)
        })
    }
  }
  /**End */

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agFilteredRowCountComponent", align: "left" },
        { statusPanel: "agAggregationComponent", align: "left" },
        { statusPanel: "agSelectedRowCountComponent" }
      ]
    }
  }, [])

  return (
    <>
      <div className="page-content">
        <Container className="body" fluid={true}>
          <Row>
            <Modal isOpen={isOpen} toggle={toggle} item={item} />
            <Breadcrumb title={t("Normal Profile")} breadcrumbItem={t("Sent List")} />
            <Col>
              <div className="js-height">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="d-flex me-3">
                      <Button
                        className="me-2"
                        onClick={() => {
                          gridRef.current.api.deselectAll()
                        }}
                      >
                        {t("Clear row selected")}
                      </Button>
                      <Button
                        className=""
                        onClick={() => {
                          gridRef.current.api.setFilterModel(null)
                        }}
                      >
                        {t("Clear filter")}
                      </Button>
                    </div>
                    <div className="d-flex me-3" style={{ height: "20px" }}>
                      <div className="vr"></div>
                    </div>
                    <div className="me-2">
                      <DatePicker
                        placeholderText="dd/mm/yyyy"
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        selected={startDate || ""}
                        onChange={e => {
                          setSelectedDate(null)
                          setStartDate(e)
                        }}
                      />
                    </div>
                    <div className="me-2">
                      <DatePicker
                        placeholderText="dd/mm/yyyy"
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        selected={endDate || ""}
                        onChange={e => {
                          setSelectedDate(null)
                          setEndDate(e)
                        }}
                      />
                    </div>
                    <div className="me-2">
                      <Select
                        menuPortalTarget={document.body}
                        styles={{
                          menu: provided => ({ ...provided, zIndex: 9999 })
                        }}
                        options={optionDateSelect}
                        value={selectedDate}
                        placeholder={t("Select time")}
                        onChange={e => {
                          setSelectedDate(e)
                          handleDateSelect(e.value)
                        }}
                      />
                    </div>
                    <Button
                      onClick={() => {
                        handleDateSelect()
                      }}
                      color="danger"
                    >
                      {" "}
                      {t("Reset")}{" "}
                    </Button>
                  </div>
                  <Button onClick={exportCsv} disabled={selected.length > 0 ? false : true} color="success">
                    {" "}
                    {t("Export excel")}
                  </Button>
                </div>
                {validateStartAndEnd() === true && (
                  <p className="font-weight-400 mb-0 text-danger pb-2 js-height">
                    {t("Check your start or end date again")}.
                  </p>
                )}
              </div>
            </Col>
          </Row>
          <div className="ag-theme-alpine" style={gridStyle}>
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowSelection={"multiple"}
              suppressRowClickSelection={true}
              colResizeDefault={true}
              // isRowSelectable={isRowSelectable}
              // onGridReady={onGridReady}
              enableRangeSelection={true}
              onFirstDataRendered={params => {
                params.columnApi.autoSizeAllColumns()
              }}
              onSelectionChanged={event => {
                const selectedNodes = event.api.getSelectedNodes()
                const isSelectAllChecked = selectedNodes.length > 0 && selectedNodes[0].isSelected()
                // Check if there's an active filter
                const isFilterActive = event.api.isAnyFilterPresent()

                if (isSelectAllChecked && isFilterActive) {
                  const filteredRows = []
                  const deselectedRows = []
                  const selected_row = []

                  event.api.forEachNodeAfterFilter(node => {
                    if (node.isSelected()) {
                      filteredRows.push(node)
                    }
                  })
                  filteredRows.map((item, index) => {
                    selected_row.push(item.data)
                  })
                  setSelected(selected_row)
                } else {
                  setSelected(event.api.getSelectedRows())
                  console.log(event.api.getSelectedRows())
                }
              }}
              pagination={true}
              statusBar={statusBar}
              // Hidden loading overlay
              overlayLoadingTemplate="<span></span>"
            />
          </div>
        </Container>
      </div>
    </>
  )
}

export default SentList
