import axios from "axios"
import { API_URL } from "../../configs/"
import { STORAGE_KEY } from "../../configs/app.const"
import Cookies from "universal-cookie"
const cookies = new Cookies()

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "content-type": "multipart/form-data"
  }
})

axiosInstance.interceptors.request.use(
  config => {
    const authUserToken = cookies.get(STORAGE_KEY.auth)
    if (authUserToken) {
      config.headers["Authorization"] = `Bearer ${authUserToken}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

export default axiosInstance
