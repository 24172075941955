import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { Button, Card, CardBody, CardTitle, Col, Container, Form, Input, Label, Row, FormFeedback } from "reactstrap"

//config
import { AG_GRID_KEY, API_URL_FILE_TEMPLATE_THONG_TIN_KHACH_HANG } from "configs"

//Ag-grid
import { AgGridReact } from "ag-grid-react" // React Grid Logic
import "ag-grid-enterprise"
import { LicenseManager } from "ag-grid-enterprise"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-alpine.css"

//responsive grid
import { getHeight } from "helpers/data_grid_helper"

//import react-select
import Select from "react-select"

//formik
import * as Yup from "yup"
import { useFormik } from "formik"

//xlsx
import * as XLSX from "xlsx"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//i18n
import { useTranslation } from "react-i18next"
import { withTranslation } from "react-i18next"
import withRouter from "components/Common/withRouter"

//helpers
import { convert_country_name_to_country_code } from "helpers/convert_country_code"

//json option
import { cang } from "./Option/cang.js"
import { cuakhau } from "./Option/cuakhau.js"
import { daisuquan } from "./Option/daisuquan.js"
import { khac } from "./Option/khac.js"
import { sanbay } from "./Option/sanbay.js"

import { STORAGE_KEY } from "configs/app.const"

//services
import { GetUserFee, SubmitNewPassportByExcel } from "services"
import { Link } from "react-router-dom"

import Swal from "sweetalert2"

import Cookies from "universal-cookie"

LicenseManager.setLicenseKey(AG_GRID_KEY)

const cookies = new Cookies()

const SubmitProfileByExcel = props => {
  const { t } = useTranslation()
  const gridRef = useRef()
  const [heightTable, setHeightTable] = useState(500)
  const [excelData, setExcelData] = useState([])
  const [data, setData] = useState([])
  const [optionEmergency, setOptionEmergency] = useState([])

  const optionReceive = [
    { value: "Đại sứ quán", label: "Đại sứ quán" },
    { value: "Sân bay", label: "Sân bay" },
    { value: "Cửa khẩu", label: "Cửa khẩu" },
    { value: "Cảng", label: "Cảng" },
    { value: "Khác", label: "Khác" }
  ]

  const optionYesNo = [
    { value: "", label: "Chưa chọn" },
    { value: "once", label: t("Once time") },
    { value: "many", label: t("Many times") }
  ]

  const addToGetVisaOption = () => {
    let wayToGetVisa = validationSubmit.values.cach_nhan_thi_thuc
    if (wayToGetVisa && wayToGetVisa !== "") {
      switch (wayToGetVisa) {
        case "Đại sứ quán":
          return daisuquan
        case "Sân bay":
          return sanbay
        case "Cửa khẩu":
          return cuakhau
        case "Cảng":
          return cang
        case "Khác":
          return khac
        default:
          return []
      }
    } else {
      return []
    }
  }

  useEffect(() => {
    GetUserFee("CVT")
      .then(res => {
        let data = res.data
        if (data.length === 0) {
          Swal.fire({
            title: "Lỗi",
            icon: "error",
            text: "Vui lòng liên hệ admin để kích hoạt phí dịch vụ",
            allowOutsideClick: false,
            showConfirmButton: false
          })
        } else {
          let emergency_data_array = []
          data.map((item, index) => {
            emergency_data_array.push({
              label: item.title_vn,
              value: item.id
            })
          })
          setOptionEmergency(emergency_data_array)
        }
      })
      .catch(err => {
        Swal.fire({
          title: "Lỗi",
          icon: "error",
          allowOutsideClick: false,
          showConfirmButton: false
        })
      })
  }, [])

  /** Form */
  const validationSubmit = useFormik({
    enableReinitialize: true,
    initialValues: {
      file: "",
      urgent: "",
      gia_tri_thi_thuc: "",
      stayIn: 0,
      cach_nhan_thi_thuc: "",
      noi_nhan_thi_thuc: "",
      danh_sach_khach_hang: [],
      ma_nhan_vien: "",
      phantachkhachhang: ""
    },
    validationSchema: Yup.object({
      file: Yup.mixed().required("Vui lòng chọn file khách hàng"),
      urgent: Yup.string().required(),
      gia_tri_thi_thuc: Yup.string().required(),
      cach_nhan_thi_thuc: Yup.string().required(),
      noi_nhan_thi_thuc: Yup.string().required(),
      phantachkhachhang: Yup.string().required(),
      stayIn: Yup.number().required().min(5)
    }),
    onSubmit: (values, { resetForm }) => {
      try {
        Swal.fire({
          title: t("Processing"),
          icon: "warning",
          text: "Vui lòng chờ ...",
          showConfirmButton: false,
          allowOutsideClick: false
        })
        values.danh_sach_khach_hang = JSON.stringify(data)
        values.ma_nhan_vien = cookies.get(STORAGE_KEY.code)
        SubmitNewPassportByExcel(values)
          .then(res => {
            Swal.fire({
              title: t("Success"),
              icon: "success",
              text: "Hệ thống gửi danh sách hộ chiếu thành công",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: false
            })
            resetForm()
          })
          .catch(err => {
            let response = err?.response?.data
            if (response && response?.msg && response?.msg === "Passport is sent already") {
              Swal.fire({
                title: t("Error"),
                icon: "error",
                text: "Hệ thống ghi nhận đã có hộ chiếu gửi trong ngày hôm nay",
                timer: 3000,
                showConfirmButton: true
              })
            } else {
              Swal.fire({
                title: t("Error"),
                icon: "error",
                text: t("Error. Please call admin for support"),
                timer: 3000,
                showConfirmButton: true
              })
            }
          })
      } catch (error) {
        Swal.fire({
          title: t("Error"),
          icon: "error",
          text: t("Error. Please call admin for support"),
          timer: 3000,
          showConfirmButton: true
        })
      }
    }
  })
  /** End Form */
  /**File handle */

  //read xlsx
  const handleFileChange = e => {
    const file = e.target.files[0]

    if (!file) {
      setExcelData([])
      setData([])
      return
    }

    const reader = new FileReader()

    reader.onload = event => {
      const data = event.target.result
      const workbook = XLSX.read(data, { type: "binary" })

      // Specify the sheet name
      const targetSheetName = "KhachHang" // Change this to the desired sheet name

      if (!workbook.SheetNames.includes(targetSheetName)) {
        alert("Không đúng định dạng file")
        return
      }
      validationSubmit.handleChange(e)

      const sheet = workbook.Sheets[targetSheetName]
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 })
      jsonData.splice(0, 1)
      jsonData.reverse()
      setExcelData(jsonData)
    }

    reader.readAsBinaryString(file)
  }

  function isValidDate(dateString) {
    // Check if the input is in the format "dd/mm/yyyy"
    const datePattern = /^\d{2}\/\d{2}\/\d{4}$/
    if (!datePattern.test(dateString)) {
      return false
    }

    // Parse the date components
    const [day, month, year] = dateString.split("/").map(Number)

    // Check if the date components are valid
    if (isNaN(day) || isNaN(month) || isNaN(year)) {
      return false
    }

    // Create a Date object and validate the date
    const date = new Date(year, month - 1, day)
    return date.getDate() === day && date.getMonth() === month - 1 && date.getFullYear() === year
  }

  // function isValidDateRange(fromDate, toDate) {
  //   const [fromDay, fromMonth, fromYear] = fromDate.split("/").map(Number)
  //   const [toDay, toMonth, toYear] = toDate.split("/").map(Number)

  //   const fromDateObj = new Date(fromYear, fromMonth - 1, fromDay)
  //   const toDateObj = new Date(toYear, toMonth - 1, toDay)
  //   const today = new Date()
  //   today.setHours(0, 0, 0, 0)

  //   console.log(fromDateObj, today)
  //   // Check if from date is greater than or equal to today
  //   if (fromDateObj < today) {
  //     return false
  //   }

  //   // Check if from date is less than to date
  //   if (fromDateObj >= toDateObj) {
  //     return false
  //   }

  //   return true
  // }

  function validDateNotOld(fromDate) {
    const [fromDay, fromMonth, fromYear] = fromDate.split("/").map(Number)

    const fromDateObj = new Date(fromYear, fromMonth - 1, fromDay)
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    // Check if from date is greater than or equal to today
    if (fromDateObj < today) {
      return false
    }
    return true
  }

  function validDob(dob) {
    const [fromDay, fromMonth, fromYear] = dob.split("/").map(Number)

    const fromDateObj = new Date(fromYear, fromMonth - 1, fromDay)
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    // Check if from date is greater than or equal to today
    if (fromDateObj >= today) {
      return false
    }
    return true
  }

  const validationInformation = item => {
    let result = {
      msg: "",
      field: ""
    }
    let fullname = item[0]
    let dob = item[1]
    let sex = item[2]
    let country = item[3]
    let personal_id = item[4]
    let entry_date = item[5]

    if (!fullname || !dob || !sex || !country || !entry_date || !personal_id) {
      result.msg = `Vui lòng kiểm tra lại các dòng còn thiếu`
      return result
    }

    //valid gender
    if (sex !== "Nam" && sex !== "Nữ") {
      result.msg = `Giới tính bắt buộc phải là Nam hoặc Nữ`
      result.field = "sex"
      return result
    }

    //valid dob
    if (isValidDate(dob) === false) {
      result.msg = `Ngày sinh không đúng format`
      result.field = "dob"
      return result
    }

    if (validDob(dob) === false) {
      result.msg = `Ngày sinh không được lớn hơn ngày hiện tại`
      result.field = "entry_date"
      return result
    }

    //valid country
    let quoc_tich_convert_code = convert_country_name_to_country_code(country)
    if (quoc_tich_convert_code === "") {
      result.msg = `Quốc tịch không đúng`
      result.field = "country"
      return result
    }

    //valid date entry_date
    if (isValidDate(entry_date) === false) {
      result.msg = `Ngày nhập cảnh không đúng format`
      result.field = "entry_date"
      return result
    }

    if (validDateNotOld(entry_date) === false) {
      result.msg = `Ngày nhập cảnh không được nhỏ hơn ngày hiện tại`
      result.field = "entry_date"
      return result
    }

    return result
  }

  const dateStringToDate = dateString => {
    const [day, month, year] = dateString.split("/")
    return new Date(`${year}-${month}-${day}`)
  }

  const dateToString = date => {
    // Get day, month, and year from the Date object
    var day = date.getDate()
    var month = date.getMonth() + 1 // Months are zero-based, so we add 1
    var year = date.getFullYear()

    // Pad day and month with leading zeros if necessary
    if (day < 10) {
      day = "0" + day
    }
    if (month < 10) {
      month = "0" + month
    }

    // Construct the string in dd/mm/yyyy format
    var dateString = day + "/" + month + "/" + year
    return dateString
  }

  // const handleCountSoNgayLuuTru = (entry_date, den_ngay) => {
  //   let startDate = dateStringToDate(entry_date)
  //   let endDate = dateStringToDate(den_ngay)
  //   let count = endDate - startDate + 1
  //   let totalDays = Math.ceil(count / (1000 * 60 * 60 * 24))
  //   return totalDays
  // }

  const splitFullName = fullName => {
    // Split the full name into parts based on whitespace
    const parts = fullName.split(" ")

    // Take the first part as the surname
    const surname = parts[0]

    // Take the rest of the parts and join them to form the last name
    const lastName = parts.slice(1).join(" ")

    // Return an object containing the surname and last name
    return {
      surname: surname,
      name: lastName
    }
  }

  const handleNgayNhapXuatCanh = (ngay_nhap_canh, stay_in) => {
    if (ngay_nhap_canh && stay_in > 0) {
      let entry_date = ngay_nhap_canh
      let departure_date = new Date(entry_date.setDate(entry_date.getDate() + Number(stay_in) - 1))
      return dateToString(departure_date)
    }
  }

  useEffect(() => {
    if (excelData.length > 0) {
      let temp = []
      let isError = false
      excelData.map((item, index) => {
        if (isError) return
        if (item.length > 0) {
          let { msg, field } = validationInformation(item)
          if (msg !== "") {
            Swal.fire({
              icon: "warning",
              title: t("Error"),
              text: msg
            })
            validationSubmit.setFieldValue("file", "")
            isError = true
            return
          }
          let { surname, name } = splitFullName(item[0])

          let item_convert = {
            fullname: item[0],
            surname: surname,
            name: name,
            dob: item[1],
            sex: item[2],
            country: item[3],
            personal_id: item[4],
            entry_date: item[5],
            departure_date: handleNgayNhapXuatCanh(dateStringToDate(item[5]), validationSubmit.values.stayIn),
            stayIn: validationSubmit.values.stayIn
            // departure_date: item[6],
            // stayIn: handleCountSoNgayLuuTru(item[5], item[6]),
          }
          console.log(item_convert)
          temp.push(item_convert)
        }
      })
      if (isError === false) {
        setData(temp.reverse())
      }
    }
  }, [excelData, validationSubmit.values.stayIn])

  /**End file handle */

  /**Start ag grid config */
  let gridStyle = { minHeight: 750, marginTop: 10 }
  if (heightTable) {
    gridStyle.minHeight = "auto"
    gridStyle.height = heightTable + "px"
  }

  useEffect(() => {
    const height = getHeight(62)
    setHeightTable(height)
  }, [])

  window.addEventListener("resize", function () {
    const height = getHeight(62)
    setHeightTable(height)
  })

  const defaultColDef = useMemo(() => {
    return {
      // flex: 1,
      // minWidth: 150,
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }
    }
  }, [])

  const columnDefs = [
    {
      headerName: t("Customer Name"),
      field: "fullname",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
      sortable: true
    },
    {
      headerName: t("Date of birth"),
      field: "dob",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true
    },
    {
      headerName: t("Gender"),
      field: "sex",
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      resizable: true,
      sortable: true
    },
    {
      headerName: t("Passport number"),
      field: "personal_id",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true
    },
    {
      headerName: t("Nationality"),
      field: "country",
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      resizable: true
    },
    {
      headerName: t("Number of days of stay"),
      field: "stayIn",
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      resizable: true
    },
    {
      headerName: t("Date of Entry"),
      field: "entry_date",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true
    },
    {
      headerName: t("Departure Date"),
      field: "departure_date",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true
    }
  ]

  /**End ag grid config */

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agFilteredRowCountComponent", align: "left" },
        { statusPanel: "agAggregationComponent", align: "left" }
      ]
    }
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={props.t("Normal Profile")} breadcrumbItem={props.t("Submit New Profile (excel)")} />
          <Col lg={12} className="js-height">
            <Card className="d-flex">
              <CardBody>
                <CardTitle className="mb-3">{props.t("Customer information")}</CardTitle>
                <Form
                  onSubmit={e => {
                    e.preventDefault()
                    validationSubmit.handleSubmit()
                    return false
                  }}
                >
                  <Col lg={12}>
                    <Row className="align-items-center">
                      <Col lg={4}>
                        <Label htmlFor="file" className="">
                          {props.t("Visistor file (xlsx)")} <span className="text-danger">*</span>
                        </Label>
                        {"  "}
                        <Link
                          onClick={() => {
                            fetch(API_URL_FILE_TEMPLATE_THONG_TIN_KHACH_HANG)
                              .then(response => response.blob())
                              .then(blob => {
                                // Handle the successful response here
                                // The blob will contain the binary XLSX data
                                // Save the blob as a file with the name 'export.xlsx'
                                const downloadLink = document.createElement("a")
                                downloadLink.href = URL.createObjectURL(blob)
                                downloadLink.setAttribute("download", "FileMauThuCong.xlsx")
                                downloadLink.click()
                              })
                              .catch(error => {
                                // Handle the error here
                                console.error("Error exporting XLSX:", error)
                              })
                          }}
                        >
                          <span>{props.t("Download sample")}</span>
                        </Link>
                        <Input
                          id="file"
                          name="file"
                          onChange={e => {
                            handleFileChange(e)
                            validationSubmit.handleChange(e)
                          }}
                          value={validationSubmit.values.file}
                          onBlur={validationSubmit.handleBlur}
                          type="file"
                          className="form-control"
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          invalid={validationSubmit.touched.file && validationSubmit.errors.file ? true : false}
                        />
                      </Col>
                      <Col lg={2}>
                        <Label>
                          {props.t("Urgent need")} <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="select"
                          name="urgent"
                          value={validationSubmit.values.urgent}
                          onChange={validationSubmit.handleChange}
                          onBlur={validationSubmit.handleBlur}
                          invalid={validationSubmit.errors.urgent && validationSubmit.touched.urgent}
                        >
                          <option value="">{props.t("Chưa chọn")}</option>
                          {optionEmergency.map((item, index) => {
                            return (
                              <option key={index} value={item.value}>
                                {item.label}
                              </option>
                            )
                          })}
                        </Input>
                      </Col>
                      <Col lg={2}>
                        <Label>
                          {props.t("Number of days of stay")} <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="number"
                          name="stayIn"
                          value={validationSubmit.values.stayIn}
                          onChange={validationSubmit.handleChange}
                          onBlur={validationSubmit.handleBlur}
                          invalid={validationSubmit.errors.stayIn && validationSubmit.touched.stayIn}
                        />
                      </Col>
                      <Col lg={4}>
                        <Label>
                          {props.t("Value of visa")} <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="select"
                          name="gia_tri_thi_thuc"
                          value={validationSubmit.values.gia_tri_thi_thuc}
                          onChange={validationSubmit.handleChange}
                          onBlur={validationSubmit.handleBlur}
                          invalid={
                            validationSubmit.errors.gia_tri_thi_thuc && validationSubmit.touched.gia_tri_thi_thuc
                          }
                        >
                          {optionYesNo.map((item, index) => {
                            return (
                              <option key={index} value={item.value}>
                                {item.label}
                              </option>
                            )
                          })}
                        </Input>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={12} className="mt-3">
                    <Row>
                      <Col lg={4}>
                        <Label>
                          {props.t("How to get a visa")} <span className="text-danger">*</span>
                        </Label>
                        <Select
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderColor:
                                validationSubmit.touched.cach_nhan_thi_thuc &&
                                validationSubmit.errors.cach_nhan_thi_thuc
                                  ? "red"
                                  : "#ced4da !important"
                            })
                          }}
                          name="cach_nhan_thi_thuc"
                          // defaultValue={optionReceive[0]}
                          onBlur={validationSubmit.handleBlur}
                          onChange={e => {
                            validationSubmit.setFieldValue("cach_nhan_thi_thuc", e.value)
                          }}
                          options={optionReceive}
                          placeholder={props.t("Select way to get visa")}
                        />
                        {validationSubmit.touched.wayToGetVisa && validationSubmit.errors.wayToGetVisa ? (
                          <FormFeedback type="invalid">{validationSubmit.errors.wayToGetVisa}</FormFeedback>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col lg={4}>
                        <Label>
                          {props.t("Visa receiving place")} <span className="text-danger">*</span>
                        </Label>
                        <Select
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderColor:
                                validationSubmit.touched.noi_nhan_thi_thuc && validationSubmit.errors.noi_nhan_thi_thuc
                                  ? "red"
                                  : "#ced4da !important"
                            })
                          }}
                          name="noi_nhan_thi_thuc"
                          // defaultValue={addToGetVisaOption()[0]}
                          onBlur={validationSubmit.handleBlur}
                          onChange={e => {
                            validationSubmit.setFieldValue("noi_nhan_thi_thuc", e.value)
                          }}
                          isSearchable
                          placeholder={props.t("Select add to get visa")}
                          options={addToGetVisaOption()}
                        />
                        {validationSubmit.touched.noi_nhan_thi_thuc && validationSubmit.errors.noi_nhan_thi_thuc ? (
                          <FormFeedback type="invalid">{validationSubmit.errors.noi_nhan_thi_thuc}</FormFeedback>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col lg={4} className="d-flex align-items-end gap-2">
                        <div className="d-flex flex-column w-70" style={{ width: "calc(100% - 130px)" }}>
                          <Label>
                            {props.t("Customer segmentation")} <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="phantachkhachhang"
                            value={validationSubmit.values.phantachkhachhang}
                            onBlur={validationSubmit.handleBlur}
                            onChange={validationSubmit.handleChange}
                            // placeholder={props.t("Select add to get visa")}
                            invalid={
                              validationSubmit.touched.phantachkhachhang && validationSubmit.errors.phantachkhachhang
                                ? true
                                : false
                            }
                          />
                        </div>
                        <Button type="submit" style={{ width: 120 }}>
                          {t("Send Passport")}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <div className="ag-theme-alpine" style={gridStyle}>
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowData={data}
              rowSelection={"multiple"}
              suppressRowClickSelection={true}
              colResizeDefault={true}
              pagination={true}
              // onGridReady={onGridReady}
              enableRangeSelection={true}
              onFirstDataRendered={params => {
                params.columnApi.autoSizeAllColumns()
              }}
              enableCellChangeFlash={true}
              statusBar={statusBar}
            />
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

SubmitProfileByExcel.propTypes = {
  t: PropTypes.any
}

export default withRouter(withTranslation()(SubmitProfileByExcel))
