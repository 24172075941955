import React, { useEffect, useState, createRef } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Alert,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  FormFeedback,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap"

//import react-select
import Select from "react-select"

//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

// Import Image Editor
import "tui-image-editor/dist/tui-image-editor.css"
import ImageEditor from "@toast-ui/react-image-editor"
import { dataURItoBlob } from "../../helpers/file_helper"

import * as Yup from "yup"
import { useFormik } from "formik"

import imageCompression from "browser-image-compression"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//Import CSS
import "./index.scss"

//json option
import { cang } from "./Option/cang.js"
import { cuakhau } from "./Option/cuakhau.js"
import { daisuquan } from "./Option/daisuquan.js"
import { khac } from "./Option/khac.js"
import { sanbay } from "./Option/sanbay.js"

//config
import { API_URL } from "configs"
import { STORAGE_KEY } from "configs/app.const"

import default_passport from "assets/images/default_passport.png"

import axios_formdata from "services/axios/axios_formdata"

import Swal from "sweetalert2"

import Cookies from "universal-cookie"

//services
import { SendPassport, GetUserFee } from "services"

//i18n
import { withTranslation } from "react-i18next"
import withRouter from "components/Common/withRouter"

// Tooltip Modal
import ModalTooltip from "components/Common/ModalTooltip"

// OptionOCR
import OptionOCR from "components/CommonForBoth/OptionOCR"

const cookies = new Cookies()

const SubmitProfile = props => {
  //meta title
  document.title = "VNPassport | Submit New Profile"

  const optionReceive = [
    { value: "Đại sứ quán", label: "Đại sứ quán" },
    { value: "Sân bay", label: "Sân bay" },
    { value: "Cửa khẩu", label: "Cửa khẩu" },
    { value: "Cảng", label: "Cảng" },
    { value: "Khác", label: "Khác" }
  ]

  const optionYesNo = [
    { value: "0", label: "Không" },
    { value: "1", label: "Có" }
  ]

  const optionGender = [
    { value: "M", label: "Nam" },
    { value: "F", label: "Nữ" }
  ]

  const [optionEmergency, setOptionEmergency] = useState([])

  const [error, setError] = useState("")
  const [image, setImage] = useState(null)
  const [imageValue, setImageValue] = useState(null)
  const [dataOcr, setDataOcr] = useState()

  const [modalImageEditor, setModalImageEditor] = useState(false)
  const [disabledButtonEditImage, setDisabledButtonEditImage] = useState(false)
  const toggleImageEditor = () => setModalImageEditor(!modalImageEditor)
  const refImageEditor = createRef()
  const whiteTheme = {
    "common.bi.image": "https://uicdn.toast.com/toastui/img/tui-image-editor-bi.png",
    "common.bisize.width": "251px",
    "common.bisize.height": "21px",
    "common.backgroundImage": "./img/bg.png",
    "common.backgroundColor": "#fff",
    "common.border": "1px solid #c1c1c1",

    // header
    "header.backgroundImage": "none",
    "header.backgroundColor": "transparent",
    "header.border": "0px",

    // load button
    "loadButton.backgroundColor": "#fff",
    "loadButton.border": "1px solid #ddd",
    "loadButton.color": "#222",
    "loadButton.fontFamily": "'Noto Sans', sans-serif",
    "loadButton.fontSize": "12px",

    // download button
    "downloadButton.backgroundColor": "#fdba3b",
    "downloadButton.border": "1px solid #fdba3b",
    "downloadButton.color": "#fff",
    "downloadButton.fontFamily": "'Noto Sans', sans-serif",
    "downloadButton.fontSize": "12px",

    // main icons
    "menu.normalIcon.color": "#8a8a8a",
    "menu.activeIcon.color": "#555555",
    "menu.disabledIcon.color": "#434343",
    "menu.hoverIcon.color": "#e9e9e9",
    "menu.iconSize.width": "24px",
    "menu.iconSize.height": "24px",

    // submenu icons
    "submenu.normalIcon.color": "#8a8a8a",
    "submenu.activeIcon.color": "#555555",
    "submenu.iconSize.width": "32px",
    "submenu.iconSize.height": "32px",

    // submenu primary color
    "submenu.backgroundColor": "transparent",
    "submenu.partition.color": "#e5e5e5",

    // submenu labels
    "submenu.normalLabel.color": "#858585",
    "submenu.normalLabel.fontWeight": "normal",
    "submenu.activeLabel.color": "#000",
    "submenu.activeLabel.fontWeight": "normal",

    // checkbox style
    "checkbox.border": "1px solid #ccc",
    "checkbox.backgroundColor": "#fff",

    // rango style
    "range.pointer.color": "#333",
    "range.bar.color": "#ccc",
    "range.subbar.color": "#606060",

    "range.disabledPointer.color": "#d3d3d3",
    "range.disabledBar.color": "rgba(85,85,85,0.06)",
    "range.disabledSubbar.color": "rgba(51,51,51,0.2)",

    "range.value.color": "#000",
    "range.value.fontWeight": "normal",
    "range.value.fontSize": "11px",
    "range.value.border": "0",
    "range.value.backgroundColor": "#f5f5f5",
    "range.title.color": "#000",
    "range.title.fontWeight": "lighter",

    // colorpicker style
    "colorpicker.button.border": "0px",
    "colorpicker.title.color": "#000"
  }

  const getOptionOCR = JSON.parse(localStorage.getItem("isCheckOCR"))

  async function handleImageUpload(file) {
    const imageFile = file

    const options = {
      maxSizeMB: 1
      // maxWidthOrHeight: 1024
    }
    try {
      const compressedFile = await imageCompression(imageFile, options)
      return compressedFile
    } catch (error) {
      console.log(error)
    }
  }

  const validationSubmitImage = useFormik({
    enableReinitialize: true,
    initialValues: {
      image: null
    },
    validationSchema: Yup.object({
      image: Yup.mixed().required(props.t("Required image"))
    }),
    onSubmit: async values => {
      const formData = new FormData()
      const image = await handleImageUpload(imageValue)
      formData.append("image", image, image.name)
      try {
        Swal.fire({
          title: props.t("Processing image..."),
          text: "",
          icon: "warning",
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false
        })

        let response
        if (getOptionOCR === true || getOptionOCR === null) {
          response = await axios_formdata.post(API_URL + "/swifttechai", formData)
        } else {
          response = await axios_formdata.post(API_URL + "/mindee", formData)
        }

        if (response.data) {
          let data = response.data
          setDataOcr(data)
          Swal.fire({
            title: props.t("Success"),
            text: props.t("System OCR successfully!"),
            icon: "success",
            timer: 2000 // Autoclose in 3 seconds
          })
        } else {
          throw new Error(props.t("Error. Please call admin for support"))
        }
      } catch (error) {
        console.log(error)
        Swal.fire({
          title: "Error",
          text: error.message,
          icon: "error",
          timer: 5000
        })
      }
    },
    validateOnChange: true
  })

  const handleImageChange = e => {
    validationSubmitImage.handleChange(e)
    e.preventDefault()
    setImageValue(e.target.files[0])
    const file = e.target.files[0]
    let reader = new FileReader()
    reader.onloadend = () => {
      setImage(reader.result)
    }
    if (file) {
      reader.readAsDataURL(file)
    }
  }

  const converDate = date => {
    var day = date.getDate()
    var month = date.getMonth() + 1 // Months are zero-based
    var year = date.getFullYear()

    // Add leading zeros if day or month is less than 10
    if (day < 10) {
      day = "0" + day
    }
    if (month < 10) {
      month = "0" + month
    }

    return day + "/" + month + "/" + year
  }

  const validationSubmitPp = useFormik({
    enableReinitialize: true,
    initialValues: {
      surname: "",
      name: "",
      dob: "",
      sex: "",
      country: "",
      personal_id: "",
      entry_date: "",
      departure_date: "",
      issuance_date: "",
      expridation_date: "",
      stayIn: 0,
      wayToGetVisa: "",
      addToGetVisa: "",
      phantachkhachhang: "",
      multipleEntry: "0",
      vip: "0",
      urgent: "1",
      passport_img: ""
    },

    validationSchema: Yup.object({
      surname: Yup.string().required(),
      name: Yup.string().required(),
      dob: Yup.string().required(),
      sex: Yup.string().required(),
      country: Yup.string().required(),
      personal_id: Yup.string().required(),
      entry_date: Yup.string()
        .required()
        .test(
          "check_valid_entry_date",
          "Ngày nhập cảnh phải nhỏ hơn ngày hiện tại",
          (value, { createError, resolve }) => {
            let today = new Date()
            today.setDate(today.getDate() - 1)
            if (new Date(value) < today) {
              return createError({ message: "Ngày nhập cảnh phải lớn hơn hoặc bằng ngày hôm nay" })
              // return false;
            }
            return resolve(Yup.ref("entry_date"))
          }
        ),
      departure_date: Yup.string().required(),
      stayIn: Yup.number().required().min(1),
      wayToGetVisa: Yup.string().required(),
      addToGetVisa: Yup.string().required()
    }),

    onSubmit: async (values, { setErrors, resetForm }) => {
      if (values.issuance_date !== "" && values.expridation_date !== "") {
        if (new Date(values.issuance_date) >= new Date(values.expridation_date)) {
          Swal.fire({
            title: "Error",
            icon: "error",
            text: "Ngày cấp phải nhỏ hơn ngày hết hạn",
            timer: 3000
          })
          setErrors({ expridation_date: "Ngày cấp phải nhỏ hơn ngày hết hạn" })
          return
        } else {
          values.issuance_date = converDate(values.issuance_date)
          values.expridation_date = converDate(values.expridation_date)
        }
      }
      values.dob = converDate(values.dob)
      values.departure_date = converDate(values.departure_date)
      values.entry_date = converDate(values.entry_date)
      values.ma_doi_tac = cookies.get(STORAGE_KEY.madoitac)
      values.ma_nhan_vien = cookies.get(STORAGE_KEY.code)
      console.log(values)
      Swal.fire({
        title: props.t("Processing passport..."),
        text: "",
        icon: "warning",
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false
      })
      try {
        const response = await SendPassport(values)
        resetForm()
        if (response.status === 201) {
          Swal.fire({
            title: props.t("Success"),
            text: props.t("System sent passport successfully!"),
            icon: "success",
            timer: 2000
          })
        } else {
          throw new Error(props.t("Error. Please call admin for support"))
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: "System sent passport error. Please try again",
          icon: "error",
          timer: 2000
        })
      }
    },
    validateOnChange: true,
    validateOnBlur: true
  })

  const addToGetVisaOption = () => {
    let wayToGetVisa = validationSubmitPp.values.wayToGetVisa
    if (wayToGetVisa && wayToGetVisa !== "") {
      switch (wayToGetVisa) {
        case "Đại sứ quán":
          return daisuquan
        case "Sân bay":
          return sanbay
        case "Cửa khẩu":
          return cuakhau
        case "Cảng":
          return cang
        case "Khác":
          return khac
        default:
          return []
      }
    } else {
      return []
    }
  }

  const handleNgayNhapXuatCanh = (ngay_nhap_canh, stay_in) => {
    if (ngay_nhap_canh !== "" && stay_in > 0) {
      let entry_date = new Date(ngay_nhap_canh)
      let departure_date = new Date(entry_date.setDate(entry_date.getDate() + Number(stay_in) - 1))
      validationSubmitPp.setFieldValue("departure_date", departure_date)
    }
  }

  useEffect(() => {
    let disabledButton = image ? false : true
    setDisabledButtonEditImage(disabledButton)
  }, [image])

  useEffect(() => {
    if (dataOcr === undefined) {
      return
    }
    if (dataOcr.surname !== "") {
      validationSubmitPp.setFieldError("surname", null)
      validationSubmitPp.setFieldValue("surname", dataOcr.surname)
    }
    if (dataOcr.name !== "") {
      validationSubmitPp.setFieldError("name", null)
      validationSubmitPp.setFieldValue("name", dataOcr.name)
    }
    if (dataOcr.country !== "") {
      validationSubmitPp.setFieldError("country", null)
      validationSubmitPp.setFieldValue("country", dataOcr.country)
    }
    if (dataOcr.personal_id !== "") {
      validationSubmitPp.setFieldError("personal_id", null)
      validationSubmitPp.setFieldValue("personal_id", dataOcr.id)
    }
    if (dataOcr.dob !== "") {
      validationSubmitPp.setFieldError("dob", null)
      validationSubmitPp.setFieldValue("dob", new Date(dataOcr.dob))
    }
    if (dataOcr.sex !== "") {
      validationSubmitPp.setFieldError("sex", null)
      validationSubmitPp.setFieldValue("sex", dataOcr.sex === "M" ? "M" : "F")
    }
    if (dataOcr.imageUrl !== "") {
      validationSubmitPp.setFieldValue("passport_img", dataOcr.imageUrl)
    }
    if (dataOcr.issue_date !== "") {
      validationSubmitPp.setFieldValue("issuance_date", new Date(dataOcr.issue_date))
    }
    if (dataOcr.expiry_date !== "") {
      validationSubmitPp.setFieldValue("expridation_date", new Date(dataOcr.expiry_date))
    }
  }, [dataOcr])

  useEffect(() => {
    GetUserFee("CVT").then(res => {
      let data = res.data
      if (data.length === 0) {
        Swal.fire({
          title: "Lỗi",
          icon: "error",
          text: "Vui lòng liên hệ admin để kích hoạt phí dịch vụ",
          allowOutsideClick: false,
          showConfirmButton: false
        })
      } else {
        let emergency_data_array = []
        data.map((item, index) => {
          emergency_data_array.push({
            label: item.title_vn,
            value: item.id
          })
        })
        setOptionEmergency(emergency_data_array)
      }
    })
  }, [])

  const handleSaveImageEditor = () => {
    const editorInstance = refImageEditor.current.getInstance()
    const data = editorInstance.toDataURL()
    var blob = dataURItoBlob(data)
    const file = new File([blob], imageValue?.name, {
      type: imageValue?.type,
      lastModified: new Date()
    })
    setImage(data)
    setImageValue(file)
    toggleImageEditor()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={props.t("Normal Profile")} breadcrumbItem={props.t("Submit New Profile")} />
          <Row>
            <Col lg="5">
              <Card className="d-flex">
                <CardBody>
                  <Col className="d-flex justify-content-between align-items-center">
                    <CardTitle className="mb-3">1. {props.t("Upload passport photo")}</CardTitle>
                    <OptionOCR />
                  </Col>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validationSubmitImage.handleSubmit()
                      return false
                    }}
                  >
                    {error !== "" ? <Alert color="danger">{error}</Alert> : null}
                    <p className="font-weight-normal fs-6">
                      {props.t("Ask for a straight shot - bright enough - don't cover information")}
                    </p>
                    <Col>
                      <div className="mt-3">
                        <Input
                          className="form-control choose-image"
                          id="formFile"
                          name="image"
                          value={validationSubmitImage.values.image || ""}
                          type="file"
                          accept="image/*"
                          placeholder="Enter Code"
                          onChange={handleImageChange}
                          onBlur={validationSubmitImage.handleBlur}
                          invalid={
                            validationSubmitImage.touched.image && validationSubmitImage.errors.image ? true : false
                          }
                        />
                        {image ? (
                          <Button
                            className="bx bx-x button-close"
                            type="button"
                            onClick={() => {
                              validationSubmitImage.setFieldValue("image", "")
                              validationSubmitImage.setErrors("image", null)
                              setImage(null)
                            }}
                          />
                        ) : null}
                        {validationSubmitImage.touched.image && validationSubmitImage.errors.image ? (
                          <FormFeedback type="invalid">{validationSubmitImage.errors.image}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <div className="mt-4 mb-4 attention-box">
                      <p className="font-weight-bold">{props.t("Attention")}</p>
                      <ul>
                        <li className="text-danger">
                          {props.t("Rotate the photo to read the contents of the passport")}
                        </li>
                        <li>{props.t("The information in the passport will automatically enter the next step")}</li>
                        <li>{props.t("If the image is not of good quality, please replace it with a better one")}</li>
                      </ul>
                    </div>
                    <Col>
                      <div>
                        <p className="font-weight-normal fs-6 text-center">
                          {props.t("Preview uploaded image (default is template)")}
                        </p>
                        <img className="w-100 d-block mx-auto rounded-4" src={image ? image : default_passport}></img>
                      </div>
                    </Col>
                    <Col className="d-flex justify-content-center mt-4">
                      <button
                        type="button"
                        onClick={() => setModalImageEditor(true)}
                        className="btn btn-primary me-3"
                        disabled={disabledButtonEditImage}
                      >
                        <i className="bx bx-smile font-size-16 align-middle me-2"></i> {props.t("Edit photo")}
                      </button>
                      <button type="submit" className="btn btn-danger">
                        <i className="bx bx-check-double font-size-16 align-middle me-2"></i> {props.t("OCR Passport")}
                      </button>
                    </Col>
                    {/* <Row className="justify-content-end mt-4">
                      <Button
                        className="w-auto d-block mx-auto"
                        type="submit"
                        color="dark"
                      >
                        Chỉnh sửa ảnh
                      </Button>
                      <Button
                        className="w-auto d-block mx-auto btn-secondary"
                        type="submit"
                        color="dark"
                      >
                        Làm đẹp ảnh
                      </Button>
                      <Button
                        className="w-auto d-block mx-auto"
                        type="submit"
                        color="dark"
                      >
                        {props.t("OCR Passport")}
                      </Button>
                    </Row> */}
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col lg="7">
              <Card className="d-flex">
                <CardBody>
                  <CardTitle className="mb-4">2. {props.t("Customer information")}</CardTitle>
                  <p className="font-weight-normal fs-6">
                    {props.t("Check the information taken from the passport and enter other necessary information")}
                  </p>
                  <div className="attention-box mb-3">
                    <p className="font-weight-400 mb-0 text-danger">
                      <b>{props.t("Attention")}</b>{" "}
                      {props.t(
                        "Check the information taken from the passport, if there is incorrect information, please correct it"
                      )}
                    </p>
                  </div>

                  <div className="attention-box mb-3">
                    <p className="font-weight-400 mb-0">
                      <b>{props.t("Attention")}</b>{" "}
                      {props.t("Please check the validity period on the customer's passport")}
                    </p>
                  </div>

                  <div className="attention-box mb-4">
                    <p className="font-weight-400 mb-0">
                      <b>{props.t("Attention")}</b>{" "}
                      {props.t("If the image is not of good quality, please replace it with a better one")}
                    </p>
                  </div>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validationSubmitPp.handleSubmit()
                      return false
                    }}
                  >
                    <FormGroup className="mb-4" row>
                      <div className="col-sm-6">
                        <Label htmlFor="projectname" className="">
                          {props.t("Customer Surname")} <span className="text-danger">*</span>
                        </Label>
                        <Col>
                          <Input
                            id="projectname"
                            name="surname"
                            type="text"
                            value={validationSubmitPp.values.surname}
                            onChange={validationSubmitPp.handleChange}
                            onBlur={validationSubmitPp.handleBlur}
                            className="form-control"
                            placeholder="Vd. CHEN"
                            invalid={
                              validationSubmitPp.touched.surname && validationSubmitPp.errors.surname ? true : false
                            }
                          />
                          {validationSubmitPp.touched.surname && validationSubmitPp.errors.surname ? (
                            <FormFeedback type="invalid">{validationSubmitPp.errors.surname}</FormFeedback>
                          ) : (
                            ""
                          )}
                        </Col>
                      </div>

                      <div className="col-sm-6 mt-4 mt-sm-0">
                        <Label htmlFor="projectname" className="">
                          {props.t("Customer Name")} <span className="text-danger">*</span>
                        </Label>
                        <Col>
                          <Input
                            id="projectname"
                            name="name"
                            value={validationSubmitPp.values.name}
                            onChange={validationSubmitPp.handleChange}
                            onBlur={validationSubmitPp.handleBlur}
                            type="text"
                            className="form-control"
                            placeholder="Vd. DAOFU"
                            invalid={validationSubmitPp.touched.name && validationSubmitPp.errors.name ? true : false}
                          />
                          {validationSubmitPp.touched.name && validationSubmitPp.errors.name ? (
                            <FormFeedback type="invalid">{validationSubmitPp.errors.name}</FormFeedback>
                          ) : (
                            ""
                          )}
                        </Col>
                      </div>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <div className="col-sm-6">
                        <Label>
                          {props.t("Date of birth")} <span className="text-danger">*</span>
                        </Label>
                        <DatePicker
                          name="dob"
                          dateFormat="dd/MM/yyyy"
                          placeholderText="dd/mm/yyyy"
                          className={
                            "form-control" +
                            (validationSubmitPp.touched.dob && validationSubmitPp.errors.dob ? " border-danger" : "")
                          }
                          selected={(validationSubmitPp.values.dob && new Date(validationSubmitPp.values.dob)) || ""}
                          onChange={val => {
                            // validationSubmitPp.handleChange(val && new Date(val).toDateString())
                            validationSubmitPp.setFieldValue("dob", val === null ? "" : val)
                          }}
                          onBlur={validationSubmitPp.handleBlur}
                        />
                        {validationSubmitPp.touched.dob && validationSubmitPp.errors.dob ? (
                          <FormFeedback type="invalid" style={{ display: "block" }}>
                            {validationSubmitPp.errors.dob}
                          </FormFeedback>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-sm-6 mt-4 mt-sm-0">
                        <Label>
                          {props.t("Gender")} <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="select"
                          name="sex"
                          id="exampleSelect"
                          value={validationSubmitPp.values.sex}
                          onChange={validationSubmitPp.handleChange}
                          onBlur={validationSubmitPp.handleBlur}
                          invalid={validationSubmitPp.touched.sex && validationSubmitPp.errors.sex ? true : false}
                        >
                          <option hidden label={props.t("Select a gender")} />
                          {optionGender.map((item, index) => {
                            return (
                              <option value={item.value} key={index}>
                                {item.label}
                              </option>
                            )
                          })}
                        </Input>
                        {validationSubmitPp.touched.sex && validationSubmitPp.errors.sex ? (
                          <FormFeedback type="invalid" style={{ display: "block" }}>
                            {validationSubmitPp.errors.sex}
                          </FormFeedback>
                        ) : (
                          ""
                        )}
                      </div>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <div className="col-sm-6">
                        <Label htmlFor="projectname" className="">
                          {props.t("Nationality")} <span className="text-danger">*</span>
                        </Label>
                        <Col>
                          <Input
                            id="projectname"
                            name="country"
                            value={validationSubmitPp.values.country}
                            onChange={validationSubmitPp.handleChange}
                            invalid={
                              validationSubmitPp.touched.country && validationSubmitPp.errors.country ? true : false
                            }
                            type="text"
                            className="form-control"
                            placeholder="Vd. CHN"
                          />
                          {validationSubmitPp.touched.country && validationSubmitPp.errors.country ? (
                            <FormFeedback type="invalid">{validationSubmitPp.errors.country}</FormFeedback>
                          ) : (
                            ""
                          )}
                        </Col>
                      </div>

                      <div className="col-sm-6 mt-4 mt-sm-0">
                        <Label htmlFor="projectname" className="">
                          {props.t("Passport number")} <span className="text-danger">*</span>
                        </Label>
                        <Col>
                          <Input
                            id="projectname"
                            name="personal_id"
                            value={validationSubmitPp.values.personal_id}
                            onChange={validationSubmitPp.handleChange}
                            onBlur={validationSubmitPp.handleBlur}
                            invalid={
                              validationSubmitPp.touched.personal_id && validationSubmitPp.errors.personal_id
                                ? true
                                : false
                            }
                            type="text"
                            className="form-control"
                            placeholder="Vd. EJ7774231"
                          />
                          {validationSubmitPp.touched.personal_id && validationSubmitPp.errors.personal_id ? (
                            <FormFeedback type="invalid">{validationSubmitPp.errors.personal_id}</FormFeedback>
                          ) : (
                            ""
                          )}
                        </Col>
                      </div>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <div className="col-sm-6">
                        <Label>{props.t("Issuance date")}</Label>
                        <DatePicker
                          name="issuance_date"
                          dateFormat="dd/MM/yyyy"
                          placeholderText="dd/mm/yyyy"
                          className={
                            "form-control" +
                            (validationSubmitPp.touched.issuance_date && validationSubmitPp.errors.issuance_date
                              ? " border-danger"
                              : "")
                          }
                          selected={
                            (validationSubmitPp.values.issuance_date &&
                              new Date(validationSubmitPp.values.issuance_date)) ||
                            ""
                          }
                          onChange={val => {
                            // validationSubmitPp.handleChange(val && new Date(val).toDateString())
                            validationSubmitPp.setFieldValue("issuance_date", val === null ? "" : val)
                          }}
                          onBlur={validationSubmitPp.handleBlur}
                        />
                        {validationSubmitPp.touched.issuance_date && validationSubmitPp.errors.issuance_date ? (
                          <FormFeedback type="invalid" style={{ display: "block" }}>
                            {validationSubmitPp.errors.issuance_date}
                          </FormFeedback>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="col-sm-6 mt-4 mt-sm-0">
                        <Label>{props.t("Expiration Date")}</Label>
                        <DatePicker
                          name="expridation_date"
                          dateFormat="dd/MM/yyyy"
                          placeholderText="dd/mm/yyyy"
                          className={
                            "form-control" +
                            (validationSubmitPp.touched.expridation_date && validationSubmitPp.errors.expridation_date
                              ? " border-danger"
                              : "")
                          }
                          selected={
                            (validationSubmitPp.values.expridation_date &&
                              new Date(validationSubmitPp.values.expridation_date)) ||
                            ""
                          }
                          onChange={val => {
                            // validationSubmitPp.handleChange(val && new Date(val).toDateString())
                            validationSubmitPp.setFieldValue("expridation_date", val === null ? "" : val)
                          }}
                          onBlur={validationSubmitPp.handleBlur}
                        />
                        {validationSubmitPp.touched.expridation_date && validationSubmitPp.errors.expridation_date ? (
                          <FormFeedback type="invalid" style={{ display: "block" }}>
                            {validationSubmitPp.errors.expridation_date}
                          </FormFeedback>
                        ) : (
                          ""
                        )}
                      </div>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <div className="col-sm-6">
                        <Label>
                          {props.t("Date of Entry")} <span className="text-danger">*</span>
                        </Label>
                        <DatePicker
                          name="entry_date"
                          placeholderText="dd/mm/yyyy"
                          dateFormat="dd/MM/yyyy"
                          className={
                            "form-control" +
                            (validationSubmitPp.touched.entry_date && validationSubmitPp.errors.entry_date
                              ? " border-danger"
                              : "")
                          }
                          selected={
                            (validationSubmitPp.values.entry_date && new Date(validationSubmitPp.values.entry_date)) ||
                            ""
                          }
                          onChange={val => {
                            // validationSubmitPp.handleChange(val && new Date(val).toDateString())
                            validationSubmitPp.setFieldValue("entry_date", val === null ? "" : val)
                            handleNgayNhapXuatCanh(val, validationSubmitPp.values.stayIn)
                          }}
                          onBlur={validationSubmitPp.handleBlur}
                        />
                        {validationSubmitPp.touched.entry_date && validationSubmitPp.errors.entry_date ? (
                          <FormFeedback type="invalid" style={{ display: "block" }}>
                            {validationSubmitPp.errors.entry_date}
                          </FormFeedback>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="col-sm-6 mt-4 mt-sm-0">
                        <Label>
                          {props.t("Number of days of stay")} <span className="text-danger">*</span>
                        </Label>
                        <Col>
                          <Input
                            id="projectname"
                            name="stayIn"
                            type="number"
                            className="form-control"
                            value={validationSubmitPp.values.stayIn}
                            onChange={e => {
                              validationSubmitPp.handleChange(e)
                              handleNgayNhapXuatCanh(validationSubmitPp.values.entry_date, e.target.value)
                            }}
                            onBlur={validationSubmitPp.handleBlur}
                            invalid={
                              validationSubmitPp.errors.stayIn && validationSubmitPp.touched.stayIn ? true : false
                            }
                            placeholder="0"
                          />
                          {validationSubmitPp.touched.stayIn && validationSubmitPp.errors.stayIn ? (
                            <FormFeedback type="invalid">{validationSubmitPp.errors.stayIn}</FormFeedback>
                          ) : (
                            ""
                          )}
                        </Col>
                      </div>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <div className="col-sm-6">
                        <Label>
                          {props.t("Departure Date")} <span className="text-danger">*</span>
                        </Label>
                        <DatePicker
                          disabled
                          name="departure_date"
                          placeholderText="dd/mm/yyyy"
                          dateFormat="dd/MM/yyyy"
                          className={
                            "form-control" +
                            (validationSubmitPp.touched.departure_date && validationSubmitPp.errors.departure_date
                              ? " border-danger"
                              : "")
                          }
                          selected={
                            (validationSubmitPp.values.departure_date &&
                              new Date(validationSubmitPp.values.departure_date)) ||
                            ""
                          }
                          onChange={val => {
                            // validationSubmitPp.handleChange(val && new Date(val).toDateString())
                            validationSubmitPp.setFieldValue("departure_date", val === null ? "" : val)
                          }}
                          onBlur={validationSubmitPp.handleBlur}
                        />
                        {validationSubmitPp.touched.departure_date && validationSubmitPp.errors.departure_date ? (
                          <FormFeedback type="invalid" style={{ display: "block" }}>
                            {validationSubmitPp.errors.departure_date}
                          </FormFeedback>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="col-sm-6 mt-4 mt-sm-0">
                        <Label>
                          {props.t("Multiple entry")} <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="select"
                          name="multipleEntry"
                          value={validationSubmitPp.values.multipleEntry}
                          onChange={validationSubmitPp.handleChange}
                          onBlur={validationSubmitPp.handleBlur}
                        >
                          {optionYesNo.map((item, index) => {
                            return (
                              <option key={index} value={item.value}>
                                {item.label}
                              </option>
                            )
                          })}
                        </Input>
                      </div>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <div className="col-sm-4">
                        <Label>
                          {props.t("How to get a visa")} <span className="text-danger">*</span>
                        </Label>
                        <Select
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderColor:
                                validationSubmitPp.touched.wayToGetVisa && validationSubmitPp.errors.wayToGetVisa
                                  ? "red"
                                  : "#ced4da !important"
                            })
                          }}
                          name="wayToGetVisa"
                          // defaultValue={optionReceive[0]}
                          onBlur={validationSubmitPp.handleBlur}
                          onChange={e => {
                            validationSubmitPp.setFieldValue("wayToGetVisa", e.value)
                          }}
                          options={optionReceive}
                          placeholder={props.t("Select way to get visa")}
                        />
                        {validationSubmitPp.touched.wayToGetVisa && validationSubmitPp.errors.wayToGetVisa ? (
                          <FormFeedback type="invalid">{validationSubmitPp.errors.wayToGetVisa}</FormFeedback>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="col-sm-4 mt-4 mt-sm-0">
                        <Label>
                          {props.t("Visa receiving place")} <span className="text-danger">*</span>
                        </Label>
                        <Select
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderColor:
                                validationSubmitPp.touched.addToGetVisa && validationSubmitPp.errors.addToGetVisa
                                  ? "red"
                                  : "#ced4da !important"
                            })
                          }}
                          name="addToGetVisa"
                          // defaultValue={addToGetVisaOption()[0]}
                          onBlur={validationSubmitPp.handleBlur}
                          onChange={e => {
                            validationSubmitPp.setFieldValue("addToGetVisa", e.value)
                          }}
                          isSearchable
                          placeholder={props.t("Select add to get visa")}
                          options={addToGetVisaOption()}
                        />
                        {validationSubmitPp.touched.addToGetVisa && validationSubmitPp.errors.addToGetVisa ? (
                          <FormFeedback type="invalid">{validationSubmitPp.errors.addToGetVisa}</FormFeedback>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="col-sm-4 mt-4 mt-sm-0">
                        <Label>
                          {props.t("Customer segmentation")}
                          <ModalTooltip>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt
                            ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                            voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                          </ModalTooltip>
                        </Label>
                        <Col>
                          <Input
                            id="projectname"
                            name="phantachkhachhang"
                            value={validationSubmitPp.values.phantachkhachhang}
                            onChange={validationSubmitPp.handleChange}
                            onBlur={validationSubmitPp.handleBlur}
                            type="text"
                            className="form-control"
                            placeholder=""
                          />
                        </Col>
                      </div>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <div className="col-sm-6">
                        <Label>
                          {props.t("Go to the VIP door")} <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="select"
                          name="vip"
                          value={validationSubmitPp.values.vip}
                          onChange={validationSubmitPp.handleChange}
                          onBlur={validationSubmitPp.handleBlur}
                        >
                          {optionYesNo.map((item, index) => {
                            return (
                              <option key={index} value={item.value}>
                                {item.label}
                              </option>
                            )
                          })}
                        </Input>
                      </div>

                      <div className="col-sm-6 mt-4 mt-sm-0">
                        <Label>
                          {props.t("Urgent need")} <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="select"
                          name="urgent"
                          value={validationSubmitPp.values.urgent}
                          onChange={validationSubmitPp.handleChange}
                          onBlur={validationSubmitPp.handleBlur}
                        >
                          {optionEmergency.map((item, index) => {
                            return (
                              <option key={index} value={item.value}>
                                {item.label}
                              </option>
                            )
                          })}
                        </Input>
                      </div>
                    </FormGroup>
                    <Row className="justify-content-end mt-4">
                      <Button className="w-auto d-block mx-auto" type="submit" color="dark">
                        {props.t("Send Passport")}
                      </Button>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal isOpen={image && modalImageEditor} toggle={toggleImageEditor} size="xl">
          {/*<ModalHeader toggle={toggleImageEditor}>{props.t("Edit image")}</ModalHeader>*/}
          <ModalBody>
            <ImageEditor
              ref={refImageEditor}
              useDefaultUI={true}
              includeUI={{
                loadImage: {
                  path: image ? image : null,
                  name: "image"
                },
                theme: whiteTheme,
                menu: ["resize", "crop", "flip", "rotate", "filter"],
                initMenu: "filter",
                uiSize: {
                  width: "1106px",
                  height: "700px"
                },
                menuBarPosition: "left"
              }}
              cssMaxHeight={500}
              cssMaxWidth={700}
              selectionStyle={{
                cornerSize: 20,
                rotatingPointOffset: 70
              }}
              usageStatistics={true}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleSaveImageEditor}>
              {props.t("SAVE")}
            </Button>{" "}
            <Button color="secondary" onClick={toggleImageEditor}>
              {props.t("CANCEL")}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </React.Fragment>
  )
}

SubmitProfile.propTypes = {
  t: PropTypes.any
}

export default withRouter(withTranslation()(SubmitProfile))
