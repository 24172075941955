import React, { forwardRef, useImperativeHandle, useState } from "react"
import { useTranslation } from "react-i18next"
import { Badge } from "reactstrap"

const ButtonRenderer = forwardRef((props, ref) => {
  const { t } = useTranslation()

  const [haveDetail, setHaveDetail] = useState(props.value)

  useImperativeHandle(ref, () => {
    return {
      refresh(params) {}
    }
  })

  const converTrangThai = value => {
    switch (value) {
      case t("Yes"):
        return "text-danger"
      default:
        return ""
    }
  }

  return <>{haveDetail && <span className={converTrangThai(props.value)}>{props.value}</span>}</>
})

ButtonRenderer.displayName = "MyImageRenderer"

export default ButtonRenderer
