import { Col, Row, Modal, ModalBody, Card, CardBody, Container, Input, Form, FormFeedback } from "reactstrap"
import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"

//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//Import Select
import Select from "react-select"

//i18n
import { withTranslation } from "react-i18next"
import withRouter from "components/Common/withRouter"
import Swal from "sweetalert2"

//formik
import { useFormik } from "formik"
import * as Yup from "yup"

import imageCompression from "browser-image-compression"

import { SendEvisaChinaApi, GetChinaOption, GetUserFee, ListEvisaChinaUpdateEvisa } from "services"

import { API_URL_2 } from "configs"
import { STORAGE_KEY } from "configs/app.const"

import axios_formdata from "services/axios/axios_formdata"
import Cookies from "universal-cookie"

const ModalEvisa = props => {
  let { item, isOpen, toggle, redownImage, payload, can_submit, gridRef } = props

  const [userFeeSelect, setUserFeeSelect] = useState([])
  const [urgentSelect, setUrgentSelect] = useState({})

  const [chinaOptionSelect, setChinaOptionSelect] = useState([])
  const [addToGetVisaSelect, setAddToGetVisaSelect] = useState({})

  const [giaTriThiThucSelect, setGiaTriThiThucSelect] = useState({})

  const [anhChanDung, setAnhChanDung] = useState()
  const [anhHoChieu, setAnhHoChieu] = useState()

  const [anhChanDungValue, setAnhChanDungValue] = useState()
  const [anhHoChieuValue, setAnhHoChieuValue] = useState()

  const [payloadSubmit, setPayloadSubmit] = useState(payload)

  let anhChanDungRef = useRef()
  let anhHoChieuRef = useRef()

  const cookies = new Cookies()

  const optionGender = [
    { value: true, label: props.t("Male") },
    { value: false, label: props.t("Female") }
  ]

  const optionStayIn = [
    { value: 30, label: `30 ${props.t("days")}` },
    { value: 90, label: `90 ${props.t("days")}` }
  ]

  const optionVisaType = [
    { value: "tourism", label: props.t("Travel") },
    { value: "working", label: props.t("Work") }
  ]

  const optionGiaTriThiThuc = [
    { value: false, label: props.t("Once time") },
    { value: true, label: props.t("Many times") }
  ]

  const handleChangeAnhChanDung = e => {
    const file = e.target.files[0]
    setAnhChanDungValue(file)
    let reader = new FileReader()
    reader.onloadend = () => {
      setAnhChanDung(reader.result)
    }
    if (file) {
      reader.readAsDataURL(file)
    }
  }

  const handleChangeAnhHoChieu = e => {
    const file = e.target.files[0]
    setAnhHoChieuValue(file)
    let reader = new FileReader()
    reader.onloadend = () => {
      setAnhHoChieu(reader.result)
    }
    if (file) {
      reader.readAsDataURL(file)
    }
  }

  async function handeSubmit(phan_tach_khach_hang) {
    toggle()
    if (can_submit === false) {
      Swal.fire({
        title: "Không có fake data",
        text: "Vui lòng liên hệ admin để thêm fake data cho quốc tịch này",
        timer: 3000,
        icon: "warning"
      })
      return
    } else {
      Swal.fire({
        title: "",
        text: "Hệ thống đang xử lý",
        icon: "warning",
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false
      })
      payload = payloadSubmit
      payload.phan_tach_khach_hang = phan_tach_khach_hang
      payload.ma_nhan_vien = cookies.get(STORAGE_KEY.code)
      await SendEvisaChinaApi(payload)
        .then(res => {
          Swal.fire({
            title: "Evisa sent successfully",
            icon: "success",
            timer: 3000,
            timerProgressBar: true
          })
          gridRef.current.api.forEachNode(rowNode => {
            if (item._id === rowNode.data._id) {
              let updated = rowNode.data
              updated.isSend = true
              updated.status = props.t("Sent")
              rowNode.updateData(updated)
            }
          })
        })
        .catch(err => {
          if (err?.response?.status === 400) {
            Swal.fire({
              title: "Hệ thống gửi hộ chiếu lỗi",
              icon: "error",
              text: "Vui lòng kiểm tra lại các trường thông tin",
              timerProgressBar: true
            })
          } else {
            Swal.fire({
              title: "Hệ thống gửi hộ chiếu lỗi",
              text: `System sent passport error: ${err?.response?.data?.msg}`,
              icon: "error",
              // timer: 3000,
              timerProgressBar: true
            })
          }
        })
    }
  }

  const convertDateFromStringToValidDpk = inputDate => {
    // Split the input date into day, month, and year
    const [day, month, year] = inputDate.split("/")

    // Create a new Date object with the input date
    const originalDate = new Date(`${month}/${day}/${year}`)

    // Get the year, month, and day from the Date object
    const newYear = originalDate.getFullYear()
    const newMonth = (originalDate.getMonth() + 1).toString().padStart(2, "0") // Months are 0-indexed
    const newDay = originalDate.getDate().toString().padStart(2, "0")

    // Create the converted date string in the "yyyy-mm-dd" format
    const convertedDateString = `${newYear}-${newMonth}-${newDay}`
    return convertedDateString
  }

  const convertDateFromTypeDate = date => {
    var day = date.getDate()
    var month = date.getMonth() + 1 // Months are zero-based
    var year = date.getFullYear()

    // Add leading zeros if day or month is less than 10
    if (day < 10) {
      day = "0" + day
    }
    if (month < 10) {
      month = "0" + month
    }

    return day + "/" + month + "/" + year
  }

  const handleNgayNhapXuatCanh = (ngay_nhap_canh, stay_in) => {
    if (ngay_nhap_canh !== "" && Number(stay_in) > 0) {
      let entry_date = new Date(convertDateFromStringToValidDpk(ngay_nhap_canh))
      let departure_date = new Date(entry_date.setDate(entry_date.getDate() + Number(stay_in) - 1))

      validationUpdate.setFieldValue("departure_date", convertDateFromTypeDate(departure_date))
    }
  }

  const validationSubmit = useFormik({
    initialValues: {
      phan_tach_khach_hang: ""
    },
    validationSchema: Yup.object({
      phan_tach_khach_hang: Yup.string().required()
    }),
    onSubmit: async values => {
      await handeSubmit(values.phan_tach_khach_hang)
    }
  })

  const validationUpdate = useFormik({
    initialValues: {
      id: "",
      surname: "",
      name: "",
      birth: "",
      sex: false,
      country: "",
      personal_id: "",
      entry_date: "",
      departure_date: "",
      stayIn: 0,
      vip: 0,
      wayToGetVisa: "",
      addToGetVisa: "",
      port_type: "",
      visaType: "",
      multipleEntry: false,
      fee: ""
    },
    validationSchema: Yup.object({}),
    onSubmit: async values => {
      Object.keys(values).map((key, index) => {
        if (Object.keys(item).includes(key)) {
          item[key] = values[key]
        }
      })
      item["sex_title"] = values.sex === true ? props.t("Male") : props.t("Female")
      item.multipleEntry_title = values.multipleEntry === true ? props.t("Many times") : props.t("Once time")
      item.visaType_title = values.visaType === "working" ? props.t("Work") : props.t("Travel")
      item.chinaoption[0].addToGetVisa = values.addToGetVisa
      item.fee.id_vn = values.fee.id
      item.fee.title_vn = values.fee.title_vn
      item.urgent = values.fee.title_vn
      let temp = await props.GetDataForSubmit(item)
      setPayloadSubmit(temp.payload)
      ListEvisaChinaUpdateEvisa(values).then(res => {
        props.setIsEdit(false)
      })
    }
  })

  //compress image
  async function handleImageUpload(file) {
    const imageFile = file

    const options = {
      maxSizeMB: 1
      // maxWidthOrHeight: 1024
    }
    try {
      const compressedFile = await imageCompression(imageFile, options)
      return compressedFile
    } catch (error) {
      console.log(error)
    }
  }

  async function UploadImage(file, url) {
    const formData = new FormData()
    const image = await handleImageUpload(file)
    formData.append("file", image, image.name)
    return axios_formdata.post(url, formData)
  }

  async function handleUploadImage(type) {
    if (type === "anh_chan_dung") {
      let url = `/evisa/china/update/anh_chan_dung?id=${item._id}`
      let res = await UploadImage(anhChanDungValue, url)
      if (res.data) {
        let data = res.data
        item.avatar_url = `${API_URL_2}${data.link}`
        setAnhChanDung(null)
        setAnhChanDungValue(null)
        let temp = await props.GetDataForSubmit(item)
        setPayloadSubmit(temp.payload)
      } else {
        setAnhChanDung(null)
        setAnhChanDungValue(null)
      }
    } else if (type === "anh_ho_chieu") {
      let url = `/evisa/china/update/anh_ho_chieu?id=${item._id}`
      let res = await UploadImage(anhHoChieuValue, url)
      if (res.data) {
        let data = res.data
        item.passport_img_url = `${API_URL_2}${data.link}`
        setAnhHoChieu(null)
        setAnhHoChieuValue(null)
        let temp = await props.GetDataForSubmit(item)
        setPayloadSubmit(temp.payload)
      } else {
        setAnhHoChieu(null)
        setAnhHoChieuValue(null)
      }
    } else {
      return
    }
  }

  useEffect(() => {
    if (item) {
      setPayloadSubmit(payload)
      setAnhChanDung(null)
      setAnhChanDungValue(null)
      setAnhHoChieu(null)
      setAnhChanDungValue(null)
      validationUpdate.setFieldValue("id", item._id)
      validationUpdate.setFieldValue("surname", item.surname)
      validationUpdate.setFieldValue("name", item.name)
      validationUpdate.setFieldValue("birth", item.birth)
      validationUpdate.setFieldValue("sex", Boolean(item.sex))
      validationUpdate.setFieldValue("country", item.country)
      validationUpdate.setFieldValue("personal_id", item.personal_id)
      validationUpdate.setFieldValue("entry_date", item.entry_date)
      validationUpdate.setFieldValue("departure_date", item.departure_date)
      validationUpdate.setFieldValue("stayIn", Number(item.stayIn))
      validationUpdate.setFieldValue("vip", item.vip)
      validationUpdate.setFieldValue("visaType", item.visaType)
      validationUpdate.setFieldValue("port_type", item.port_type)
      validationUpdate.setFieldValue("addToGetVisa", item.addToGetVisa)
      validationUpdate.setFieldValue("wayToGetVisa", item.wayToGetVisa)
      validationUpdate.setFieldValue("multipleEntry", Boolean(item.multipleEntry))
      setGiaTriThiThucSelect(item.multipleEntry === true ? optionGiaTriThiThuc[1] : optionGiaTriThiThuc[0])
      GetChinaOption().then(res => {
        let data = res.data
        let option = []
        data.map((value, index) => {
          if (value.port_type === item.port_type) {
            setAddToGetVisaSelect({ label: value.addToGetVisa, value: value })
          }
          option.push({ label: value.addToGetVisa, value: value })
        })
        setChinaOptionSelect(option)
      })
      GetUserFee("EVISA").then(res => {
        let data = res.data
        let option = []
        data.map((value, index) => {
          if (item.fee && Number(item.fee.id_vn) === Number(value.id)) {
            setUrgentSelect({ label: value.title_vn, value: value })
            validationUpdate.setFieldValue("fee", value)
          }
          option.push({ label: value.title_vn, value: value })
        })
        setUserFeeSelect(option)
      })
    }
  }, [item, props.isOpen])

  return (
    <React.Fragment>
      <Modal size="xl" isOpen={isOpen} toggle={toggle} className="modal-dialog modal-dialog-centered">
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
            {props.t("Detail")}
          </h5>
          <button
            onClick={() => {
              toggle()
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>
          <Container>
            <Row>
              <Col xl="12">
                <Form
                  onSubmit={e => {
                    if (props.isEdit === false) {
                      e.preventDefault()
                      validationSubmit.handleSubmit()
                      return false
                    } else {
                      e.preventDefault()
                      validationUpdate.handleSubmit()
                      return false
                    }
                  }}
                >
                  <Card className="w-100">
                    <CardBody className="d-flex justify-content-between p-0">
                      {props.isEdit === false && (
                        <>
                          <Col lg="2">
                            <Card className="pe-3">
                              <h4 className="pb-3">Ảnh chân dung:</h4>
                              <img
                                className="rounded-3 img-fluid"
                                src={anhChanDung ? anhChanDung : item.avatar_url}
                                alt="Avatar Image"
                                // style={{ maxWidth: "auto", minHeight: "200px" }
                              />
                              {props.isEdit === false && can_submit === true && (
                                <>
                                  <div className="d-flex justify-content-center">
                                    <input
                                      type="file"
                                      ref={anhChanDungRef}
                                      name="anhChanDung"
                                      hidden
                                      onChange={e => {
                                        handleChangeAnhChanDung(e)
                                      }}
                                    />
                                    <button
                                      type="button"
                                      className={`btn btn-${
                                        props.isEditAnhChanDung === true ? "danger" : "primary"
                                      } my-2 w-100`}
                                      onClick={async () => {
                                        if (props.isEditAnhChanDung === false) {
                                          props.setIsEditAnhChanDung(true)
                                          anhChanDungRef.current.click()
                                        } else if (props.isEditAnhChanDung === true) {
                                          props.setIsEditAnhChanDung(false)
                                          handleUploadImage("anh_chan_dung")
                                        }
                                      }}
                                    >
                                      {props.isEditAnhChanDung === true ? props.t("Save") : props.t("Update")}
                                    </button>
                                  </div>
                                </>
                              )}
                            </Card>
                          </Col>
                        </>
                      )}
                      <Col lg={props.isEdit === true ? "5" : "6"}>
                        <Card className="">
                          <h4 className="pb-3">Ảnh hộ chiếu:</h4>
                          <img
                            className="rounded-3 img-fluid"
                            src={anhHoChieu ? anhHoChieu : item.passport_img_url}
                            alt="Passport Image"
                            // style={{ maxWidth: "auto", minHeight: "300px" }}
                          />
                          {props.isEdit === false && can_submit === true && (
                            <>
                              <div className="d-flex justify-content-center">
                                <input
                                  type="file"
                                  ref={anhHoChieuRef}
                                  name="anhHoChieu"
                                  hidden
                                  onChange={e => {
                                    handleChangeAnhHoChieu(e)
                                  }}
                                />
                                <button
                                  type="button"
                                  className={`btn btn-${
                                    props.isEditAnhHoChieu === true ? "danger" : "primary"
                                  } my-2 w-50`}
                                  onClick={async () => {
                                    if (props.isEditAnhHoChieu === false) {
                                      props.setIsEditAnhHoChieu(true)
                                      anhHoChieuRef.current.click()
                                    } else if (props.isEditAnhHoChieu === true) {
                                      props.setIsEditAnhHoChieu(false)
                                      handleUploadImage("anh_ho_chieu")
                                    }
                                  }}
                                >
                                  {props.isEditAnhHoChieu === true ? props.t("Save") : props.t("Update")}
                                </button>
                              </div>
                            </>
                          )}
                        </Card>
                      </Col>
                      <Col lg={props.isEdit === true ? "7" : "4"} className="ps-3">
                        {props.isEdit === true && (
                          <>
                            <h4 className="pb-3">{props.t("Customer information")}:</h4>
                            <div className="d-flex justify-content-between">
                              <div className="me-2">
                                <h6>
                                  <b>{props.t("Surname")}: </b>
                                  {/* {item.name} */}
                                </h6>
                                <Input
                                  name="surname"
                                  value={validationUpdate.values.surname}
                                  onChange={validationUpdate.handleChange}
                                />
                              </div>
                              <div className="me-2">
                                <h6>
                                  <b>{props.t("Name")}: </b>
                                  {/* {item.name} */}
                                </h6>
                                <Input
                                  name="name"
                                  value={validationUpdate.values.name}
                                  onChange={validationUpdate.handleChange}
                                />
                              </div>
                              <div className="">
                                <h6>
                                  <b>{props.t("Date of birth")}: </b>
                                </h6>
                                <DatePicker
                                  name="birth"
                                  dateFormat="dd/MM/yyyy"
                                  placeholderText="dd/mm/yyyy"
                                  className={"form-control"}
                                  selected={
                                    (validationUpdate.values.birth &&
                                      new Date(convertDateFromStringToValidDpk(validationUpdate.values.birth))) ||
                                    ""
                                  }
                                  onChange={val => {
                                    validationUpdate.setFieldValue(
                                      "birth",
                                      val === null ? "" : convertDateFromTypeDate(val)
                                    )
                                  }}
                                />
                              </div>
                            </div>
                            <div className="d-flex mt-4">
                              <div className="w-25 me-2">
                                <h6>
                                  <b>{props.t("Gender")}: </b>
                                </h6>
                                <Input
                                  type="select"
                                  name="sex"
                                  value={validationUpdate.values.sex}
                                  onChange={validationUpdate.handleChange}
                                  invalid={validationUpdate.touched.sex && validationUpdate.errors.sex ? true : false}
                                >
                                  <option hidden label={props.t("Select a gender")} key={"gender" + 3} />
                                  {optionGender.map((item, index) => {
                                    return (
                                      <option value={item.value} key={"gender" + index}>
                                        {item.label}
                                      </option>
                                    )
                                  })}
                                </Input>
                              </div>
                              <div className="w-25 me-2">
                                <h6>
                                  <b>{props.t("Passport number")}: </b>
                                </h6>
                                <Input
                                  name="personal_id"
                                  value={validationUpdate.values.personal_id}
                                  onChange={validationUpdate.handleChange}
                                />
                              </div>
                              <div className="w-25 me-2">
                                <h6>
                                  <b>{props.t("Nationality")}: </b>
                                </h6>
                                <Input
                                  name="country"
                                  value={validationUpdate.values.country}
                                  onChange={validationUpdate.handleChange}
                                />
                              </div>
                              <div className="w-25">
                                <h6>
                                  <b>{props.t("Entry purpose")}:</b>{" "}
                                </h6>
                                <Input
                                  name="visaType"
                                  type="select"
                                  className="form-control"
                                  value={validationUpdate.values.visaType}
                                  onChange={validationUpdate.handleChange}
                                >
                                  {optionVisaType.map((item, index) => {
                                    return (
                                      <option value={item.value} key={index}>
                                        {item.label}
                                      </option>
                                    )
                                  })}
                                </Input>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between mt-4">
                              <div className="me-2 w-25">
                                <h6>
                                  <b>{props.t("Date of Entry")}: </b>
                                </h6>
                                <DatePicker
                                  name="entry_date"
                                  dateFormat="dd/MM/yyyy"
                                  placeholderText="dd/mm/yyyy"
                                  className={"form-control"}
                                  selected={
                                    (validationUpdate.values.entry_date &&
                                      new Date(convertDateFromStringToValidDpk(validationUpdate.values.entry_date))) ||
                                    ""
                                  }
                                  onChange={val => {
                                    validationUpdate.setFieldValue(
                                      "entry_date",
                                      val === null ? "" : convertDateFromTypeDate(val)
                                    )
                                    handleNgayNhapXuatCanh(convertDateFromTypeDate(val), validationUpdate.values.stayIn)
                                  }}
                                />
                              </div>
                              <div className="me-2 w-25">
                                <h6>
                                  <b>Thời gian lưu trú: </b>
                                </h6>
                                <Input
                                  name="stayIn"
                                  type="select"
                                  className={"form-control"}
                                  value={validationUpdate.values.stayIn}
                                  onBlur={validationUpdate.handleBlur}
                                  invalid={
                                    validationUpdate.errors.stayIn && validationUpdate.touched.stayIn ? true : false
                                  }
                                  onChange={e => {
                                    validationUpdate.handleChange(e)
                                    handleNgayNhapXuatCanh(validationUpdate.values.entry_date, e.target.value)
                                  }}
                                >
                                  {optionStayIn.map((item, index) => {
                                    return (
                                      <option value={item.value} key={"stayIn" + index}>
                                        {item.label}
                                      </option>
                                    )
                                  })}
                                </Input>
                              </div>
                              <div className="me-2 w-25">
                                <h6>
                                  <b>{props.t("Departure Date")}: </b>
                                  {/* {item.departure_date} */}
                                </h6>
                                <Input
                                  name="departure_date"
                                  className={"form-control"}
                                  disabled
                                  value={validationUpdate.values.departure_date}
                                  onChange={e => {}}
                                />
                              </div>
                              <div className="w-25">
                                <h6>
                                  <b>{props.t("Value of visa")}:</b>{" "}
                                </h6>
                                <Select
                                  name="multipleEntry"
                                  placeholder="Vui lòng chọn"
                                  options={optionGiaTriThiThuc}
                                  value={giaTriThiThucSelect}
                                  onBlur={validationUpdate.handleBlur}
                                  onChange={e => {
                                    validationUpdate.setFieldValue("multipleEntry", e.value)
                                    setGiaTriThiThucSelect(e)
                                  }}
                                />
                              </div>
                            </div>
                            <div className="d-flex mt-4">
                              <div className="me-2 w-75">
                                <h6>
                                  <b>{props.t("Visa receiving place")}:</b>{" "}
                                </h6>
                                <Select
                                  name="addToGetVisa"
                                  placeholder="Vui lòng chọn"
                                  options={chinaOptionSelect}
                                  value={addToGetVisaSelect}
                                  onBlur={validationUpdate.handleBlur}
                                  onChange={e => {
                                    validationUpdate.setFieldValue("addToGetVisa", e.label)
                                    validationUpdate.setFieldValue("port_type", e.value.port_type)
                                    validationUpdate.setFieldValue("addToGetVisa", e.value.addToGetVisa)
                                    validationUpdate.setFieldValue("wayToGetVisa", e.value.wayToGetVisa)
                                    setAddToGetVisaSelect(e)
                                  }}
                                />
                              </div>
                              <div className="w-25">
                                <h6>
                                  <b>{props.t("Urgent")}:</b>{" "}
                                </h6>
                                <Select
                                  name="urgent"
                                  placeholder="Vui lòng chọn"
                                  options={userFeeSelect}
                                  value={urgentSelect}
                                  onBlur={validationUpdate.handleBlur}
                                  onChange={e => {
                                    validationUpdate.setFieldValue("fee", e.value)
                                    setUrgentSelect(e)
                                  }}
                                />
                              </div>
                            </div>
                          </>
                        )}
                        {props.isEdit === false && (
                          <>
                            <h4 className="pb-3">{props.t("Customer information")}:</h4>
                            <h5>
                              <b>Họ và tên: </b> {item.surname + " " + item.name}
                            </h5>
                            <h5>
                              <b>Ngày sinh: </b> {item.birth}
                            </h5>
                            <h5>
                              <b>Giới tính: </b> {item.sex_title}
                            </h5>
                            <h5>
                              <b>Số hộ chiếu: </b> {item.personal_id}
                            </h5>
                            <h5>
                              <b>Quốc tịch: </b> {item.country}
                            </h5>
                            <h5>
                              <b>Ngày nhập cảnh: </b> {item.entry_date}
                            </h5>
                            <h5>
                              <b>Ngày xuất cảnh: </b> {item.departure_date}
                            </h5>
                            <h5>
                              <b>Thời gian lưu trú: </b> {item.stayIn} ngày{" "}
                            </h5>
                            <h5>
                              <b>Mục đích nhập cảnh: </b> {item.visaType_title}
                            </h5>
                            <h5>
                              <b>Giá trị thị thực: </b> {item.multipleEntry_title}
                            </h5>
                            <h5>
                              <b>Nơi nhập cảnh: </b> {item.addToGetVisa}
                            </h5>
                            <h5>
                              <b>Khẩn cấp: </b> {item?.fee?.title_vn}{" "}
                            </h5>
                            <h5>
                              <b>Vip: </b> {item.vip === true ? "Có" : "Không"}{" "}
                            </h5>
                          </>
                        )}
                        {item.isSend === false && (
                          <Card className={`d-flex flex-wrap gap-2 mt-${props.isEdit === true ? "4" : "2"}`}>
                            {props.isEdit === false && (
                              <>
                                <div className="my-3">
                                  <h4>Phân tách khách hàng:</h4>
                                  <Input
                                    name="phan_tach_khach_hang"
                                    type="text"
                                    className="form-control"
                                    value={validationSubmit.values.phan_tach_khach_hang}
                                    onChange={validationSubmit.handleChange}
                                    onBlur={validationSubmit.handleBlur}
                                    invalid={
                                      validationSubmit.touched.phan_tach_khach_hang &&
                                      validationSubmit.errors.phan_tach_khach_hang
                                        ? true
                                        : false
                                    }
                                  />
                                  {validationSubmit.touched.phan_tach_khach_hang &&
                                  validationSubmit.errors.phan_tach_khach_hang ? (
                                    <FormFeedback type="invalid">
                                      {validationSubmit.errors.phan_tach_khach_hang}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </>
                            )}
                            <Col className="d-flex justify-content-center">
                              <button
                                type="button"
                                onClick={async () => await redownImage(item._id)}
                                className="btn btn-primary me-2"
                              >
                                <i className="bx bx-smile font-size-16 align-middle me-2"></i> Tải lại ảnh
                              </button>
                              <button
                                className="btn btn-danger me-2"
                                type="button"
                                onClick={() => {
                                  if (props.isEdit === true) {
                                    props.setIsEdit(false)
                                  } else {
                                    props.setIsEdit(true)
                                  }
                                }}
                              >
                                {props.isEdit === false ? props.t("Edit") : props.t("Cancel")}
                              </button>
                              <button type="submit" className="btn btn-success">
                                <i className="bx bx-check-double font-size-16 align-middle me-2"></i>{" "}
                                {props.isEdit === false ? props.t("Send records") : props.t("Edit")}
                              </button>
                            </Col>
                          </Card>
                        )}
                      </Col>
                    </CardBody>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

ModalEvisa.propTypes = {
  t: PropTypes.any
}

export default withRouter(withTranslation()(ModalEvisa))
