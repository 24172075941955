import React from "react"
import { Navigate } from "react-router-dom"
import Cookies from "universal-cookie"
import { LoadingProvider } from "../context/loading.context"

const cookies = new Cookies()

const Authmiddleware = props => {
  if (cookies.get("authUser") === undefined) {
    return <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
  }
  return (
    <React.Fragment>
      <LoadingProvider>{props.children}</LoadingProvider>
    </React.Fragment>
  )
}

export default Authmiddleware
