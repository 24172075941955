import React, { useEffect, useState, useRef, useContext, useMemo } from "react"
import { Button, Col, Container, Row } from "reactstrap"

//Ag-grid
import { AgGridReact } from "ag-grid-react" // React Grid Logic
import "ag-grid-enterprise"
import { LicenseManager } from "ag-grid-enterprise"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-alpine.css"

//Cell renderer
import AgButtonModal from "./AgCellRendering/AgButtonModal"
import AgText from "./AgCellRendering/AgText"
import AgTextSpan from "./AgCellRendering/AgTextSpan"
import AgVip from "./AgCellRendering/AgVip"

import Modal from "./Modal"

//translation
import { useTranslation } from "react-i18next"

//Breadcrumbs
import Breadcrumb from "../../components/Common/Breadcrumb"

//config
import { AG_GRID_KEY } from "configs"

//services
import { GetChinaDataList, RedownImage } from "services"

//responsive grid
import { getHeight } from "../../helpers/data_grid_helper"

//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//import react-select
import Select from "react-select"

import moment from "moment"
import Swal from "sweetalert2"

// Loading
import LoadingContext from "context/loading.context"

LicenseManager.setLicenseKey(AG_GRID_KEY)

const ListChinaData = () => {
  const { t } = useTranslation()
  const gridRef = useRef()
  const [heightTable, setHeightTable] = useState(500)
  const [data, setData] = useState()
  const [isOpen, setIsOpen] = useState(false)
  const [item, setItem] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [selectedDate, setSelectedDate] = useState()
  const { setLoading } = useContext(LoadingContext)

  const optionDateSelect = [
    { label: "Hôm nay", value: "today" },
    { label: "Hôm qua", value: "yesterday" },
    { label: "3 ngày trước", value: "3daysago" },
    { label: "7 ngày trước", value: "7daysago" },
    { label: "15 ngày trước", value: "15daysago" },
    { label: "30 ngày trước", value: "30daysago" }
  ]

  const handleDateSelect = value => {
    let today = new Date()
    let last_date = new Date()
    last_date.setDate(today.getDate() - 1)
    setEndDate(last_date)
    switch (value) {
      case "today":
        setStartDate(today)
        setEndDate(today)
        break
      case "yesterday":
        let yesterday = new Date(today)
        yesterday.setDate(today.getDate() - 1)
        setStartDate(yesterday)
        break
      case "3daysago":
        let threedaysago = new Date(today)
        threedaysago.setDate(today.getDate() - 3)
        setStartDate(threedaysago)
        break
      case "7daysago":
        let sevendaysago = new Date(today)
        sevendaysago.setDate(today.getDate() - 7)
        setStartDate(sevendaysago)
        break
      case "15daysago":
        let fifteendaysago = new Date(today)
        fifteendaysago.setDate(today.getDate() - 15)
        setStartDate(fifteendaysago)
        break
      case "30daysago":
        let thirtydaysago = new Date(today)
        thirtydaysago.setDate(today.getDate() - 30)
        setStartDate(thirtydaysago)
        break
      default:
        setStartDate()
        setEndDate()
        setSelectedDate(null)
        break
    }
  }

  const validateStartAndEnd = () => {
    if (startDate && endDate) {
      if (startDate > endDate) {
        return true
      } else {
        return false
      }
    }
    return null
  }

  const toggle = () => {
    setIsEdit(false)
    if (isOpen) {
      setIsOpen(false)
      setItem({})
    } else {
      setIsOpen(true)
    }
  }

  /**Start ag grid config */
  let gridStyle = { minHeight: 750, marginTop: 10 }
  if (heightTable) {
    gridStyle.minHeight = "auto"
    gridStyle.height = heightTable + "px"
  }
  useEffect(() => {
    const height = getHeight(62)
    setHeightTable(height)
  }, [])

  window.addEventListener("resize", function () {
    const height = getHeight(62)
    setHeightTable(height)
  })

  const defaultColDef = useMemo(() => {
    return {
      // flex: 1,
      minWidth: 150,
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }
    }
  }, [])
  const columnDefs = [
    // {
    //   headerName: t("Status"),
    //   field: "status",
    //   resizable: true,
    //   floatingFilter: true,
    //   filter: "agTextColumnFilter",
    //   sortable: true
    // },
    {
      headerName: t("Date Created"),
      field: "createAt",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true,
      pinned: "left"
    },
    {
      headerName: t("Fullname"),
      field: "name",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true,
      pinned: "left"
    },
    {
      headerName: t("Send"),
      field: "status",
      resizable: true,
      floatingFilter: true,
      filter: "agSetColumnFilter",
      cellRendererSelector: params => {
        const send = {
          component: AgButtonModal
        }
        if (params.data) {
          return send
        }
      },
      cellRendererParams: {
        setItem: setItem,
        toggle: toggle,
        checkImage: checkImage,
        redownImage,
        color: "primary"
      },
      pinned: "left"
    },
    {
      headerName: t("Date of birth"),
      field: "birth",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true
    },
    {
      headerName: t("Gender"),
      field: "sex_title",
      resizable: true,
      floatingFilter: true,
      filter: "agMultiColumnFilter",
      sortable: true
    },
    {
      headerName: t("Passport number"),
      field: "personal_id",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true
    },
    {
      headerName: t("Urgent"),
      field: "urgent_title",
      resizable: true,
      floatingFilter: true,
      filter: "agMultiColumnFilter",
      sortable: true,
      cellRendererSelector: params => {
        const text = {
          component: AgText
        }
        if (params.data) {
          return text
        }
      }
    },
    {
      headerName: t("Value of visa"),
      field: "multipleEntry_title",
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      resizable: true,
      cellRendererSelector: params => {
        const text = {
          component: AgTextSpan
        }
        if (params.data) {
          return text
        }
      }
    },
    {
      headerName: t("Number of days of stay"),
      field: "stayIn",
      resizable: true,
      floatingFilter: true,
      filter: "agMultiColumnFilter",
      sortable: true
    },
    {
      headerName: t("Date of Entry"),
      field: "entry_date",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true
    },
    {
      headerName: t("Departure Date"),
      field: "departure_date",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true
    },
    {
      headerName: t("Nationality"),
      field: "country",
      resizable: true,
      floatingFilter: true,
      filter: "agMultiColumnFilter",
      sortable: true
    },
    {
      headerName: t("VIP"),
      field: "Vip",
      resizable: true,
      floatingFilter: true,
      filter: "agMultiColumnFilter",
      sortable: true,
      cellRendererSelector: params => {
        const text = {
          component: AgVip
        }
        if (params.data) {
          return text
        }
      }
    },
    {
      headerName: t("Visa receiving place"),
      field: "addToGetVisa",
      resizable: true,
      floatingFilter: true,
      filter: "agMultiColumnFilter",
      sortable: true,
      minWidth: 450
    }
  ]

  // const onGridReady = useCallback(params => {
  //   GetChinaDataList({}).then(res => {
  //     let temp = []
  //     let response = res.data
  //     response.map((item, index) => {
  //       item.can_submit = item.isSend && item.isSend === true ? false : true
  //       item.status =
  //         item.isSend && item.isSend === true ? t("Sent") : t("Unsent")
  //       item.createAt = moment(item.createAt).format("DD/MM/YYYY HH:mm:ss")
  //       item.sex_title = item.sex === 1 ? t("Male") : t("Female")
  //       item.urgent_title = item.convert_value?.title_vn
  //       item.urgent = item.convert_value
  //       item.Vip = item.vip === "0" ? t("No") : t("Yes")
  //       item.addToGetVisa =
  //         item.chinaoption && item.chinaoption.length > 0
  //           ? item.chinaoption[0].addToGetVisa
  //           : ""
  //       item.wayToGetVisa =
  //         item.chinaoption && item.chinaoption.length > 0
  //           ? item.chinaoption[0].wayToGetVisa
  //           : ""
  //       item.multipleEntry_title =
  //         Number(item.convert_value?.gia_tri_thi_thuc) === 1
  //           ? t("Many times")
  //           : t("Once time")
  //       temp.push(item)
  //     })
  //     setData(temp)
  //   })
  // }, [])
  /**End ag grid config */

  async function checkImage(url) {
    return new Promise(resolve => {
      const imageFound = () => {
        resolve(true)
      }

      const imageNotFound = () => {
        resolve(false)
      }

      const tester = new Image()
      tester.addEventListener("load", imageFound)
      tester.addEventListener("error", imageNotFound)
      tester.src = url
    })
  }

  async function redownImage(url) {
    Swal.fire({
      title: "",
      text: "System is re-downloading image",
      icon: "warning",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false
    })
    RedownImage({ url: url })
      .then(res => {
        Swal.close()
        toggle()
      })
      .catch(err => {
        Swal.fire({
          title: "Error",
          text: `Error at redownImage ${err.msg}`
        })
      })
  }

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agFilteredRowCountComponent", align: "left" },
        { statusPanel: "agAggregationComponent", align: "left" },
        { statusPanel: "agSelectedRowCountComponent" }
      ]
    }
  }, [])

  const formatDate = date => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")
    return `${year}-${month}-${day}`
  }

  useEffect(() => {
    let payload = {}
    if (startDate && endDate) {
      let status = validateStartAndEnd()
      if (status === false) {
        payload.startDate = formatDate(startDate)
        payload.endDate = formatDate(endDate)
      }
    }
    setLoading(true)
    GetChinaDataList(payload)
      .then(res => {
        let temp = []
        let response = res.data
        response.map((item, index) => {
          item.can_submit = item.isSend && item.isSend === true ? false : true
          item.status = item.isSend && item.isSend === true ? t("Sent") : t("Unsent")
          item.createAt = moment(item.createAt).format("DD/MM/YYYY HH:mm:ss")
          item.sex_title = item.sex === 1 ? t("Male") : t("Female")
          item.urgent_title = item.convert_value?.title_vn
          item.urgent = item.convert_value
          item.Vip = item.vip === "0" ? t("No") : t("Yes")
          item.addToGetVisa = item.chinaoption && item.chinaoption.length > 0 ? item.chinaoption[0].addToGetVisa : ""
          item.wayToGetVisa = item.chinaoption && item.chinaoption.length > 0 ? item.chinaoption[0].wayToGetVisa : ""
          item.multipleEntry_title =
            Number(item.convert_value?.gia_tri_thi_thuc) === 1 ? t("Many times") : t("Once time")
          temp.push(item)
        })
        // Deplay loading
        if (res.length > 100) {
          setLoading(false)
          setData(temp)
        } else {
          setTimeout(() => {
            setLoading(false)
            setData(temp)
          }, 500)
        }
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
      })
  }, [t, startDate, endDate])

  return (
    <>
      <div className="page-content">
        <Container className="body" fluid={true}>
          <Breadcrumb title={t("Normal Profile")} breadcrumbItem={t("Receiving List")} />
          <Modal
            isOpen={isOpen}
            toggle={toggle}
            item={item}
            redownImage={redownImage}
            gridRef={gridRef}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
          />
          <Row>
            <Col>
              <div className="js-height">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="d-flex me-3">
                      <Button
                        className="me-2"
                        onClick={() => {
                          gridRef.current.api.deselectAll()
                        }}
                      >
                        {t("Clear row selected")}
                      </Button>
                      <Button
                        className=""
                        onClick={() => {
                          gridRef.current.api.setFilterModel(null)
                        }}
                      >
                        {t("Clear filter")}
                      </Button>
                    </div>
                    <div className="d-flex me-3" style={{ height: "20px" }}>
                      <div className="vr"></div>
                    </div>
                    <div className="me-2">
                      <DatePicker
                        placeholderText="dd/mm/yyyy"
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        selected={startDate || ""}
                        onChange={e => {
                          setSelectedDate(null)
                          setStartDate(e)
                        }}
                      />
                    </div>
                    <div className="me-2">
                      <DatePicker
                        placeholderText="dd/mm/yyyy"
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        selected={endDate || ""}
                        onChange={e => {
                          setSelectedDate(null)
                          setEndDate(e)
                        }}
                      />
                    </div>
                    <div className="me-2">
                      <Select
                        menuPortalTarget={document.body}
                        styles={{
                          menu: provided => ({ ...provided, zIndex: 9999 })
                        }}
                        options={optionDateSelect}
                        value={selectedDate}
                        placeholder={t("Select time")}
                        onChange={e => {
                          setSelectedDate(e)
                          handleDateSelect(e.value)
                        }}
                      />
                    </div>
                    <Button
                      onClick={() => {
                        handleDateSelect()
                      }}
                      color="danger"
                    >
                      {" "}
                      {t("Reset")}{" "}
                    </Button>
                  </div>
                </div>
                {validateStartAndEnd() === true && (
                  <p className="font-weight-400 mb-0 text-danger pb-2 js-height">
                    {t("Check your start or end date again")}.
                  </p>
                )}
              </div>
            </Col>
          </Row>
          <div className="ag-theme-alpine" style={gridStyle}>
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowData={data}
              rowSelection={"multiple"}
              suppressRowClickSelection={true}
              colResizeDefault={true}
              pagination={true}
              // onGridReady={onGridReady}
              enableCellChangeFlash={true}
              enableRangeSelection={true}
              onFirstDataRendered={params => {
                params.columnApi.autoSizeAllColumns()
              }}
              statusBar={statusBar}
              // Hidden loading overlay
              overlayLoadingTemplate="<span></span>"
            />
          </div>
        </Container>
      </div>
    </>
  )
}

export default ListChinaData
