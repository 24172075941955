import React, { useEffect, useState, useRef, useCallback, useMemo, useContext } from "react"
import { Container, Button, Col, Row } from "reactstrap"

//Ag-grid
import { AgGridReact } from "ag-grid-react" // React Grid Logic
import "ag-grid-enterprise"
import { LicenseManager } from "ag-grid-enterprise"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-alpine.css"

//Cell rendering
// import AgImage from "./AgCellRendering/AgImage"
import AgButton from "./AgCellRendering/AgButton"
import AgUrgentText from "./AgCellRendering/AgUrgentText"
import AgValueOfVisa from "./AgCellRendering/AgValueOfVisa"
import AgMucDichNhapCanh from "./AgCellRendering/AgMucDichNC"
import AgStayIn from "./AgCellRendering/AgStayIn"
import AgCountry from "./AgCellRendering/AgCountry"
import AgVip from "./AgCellRendering/AgVip"

import moment from "moment"

//translation
import { useTranslation } from "react-i18next"

//Breadcrumbs
import Breadcrumb from "components/Common/Breadcrumb"

//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//import react-select
import Select from "react-select"

//Modal
import Modal from "./Modal"

//config
import { AG_GRID_KEY, API_URL_2 } from "configs"
import { STORAGE_KEY } from "configs/app.const"
//services
import { RedownImageEvisa, GetListEvisaChina, GetRandomAddressEvisa, GetRandomFakeInfoEvisa } from "services"

//responsive grid
import { getHeight } from "helpers/data_grid_helper"

import quoc_tich from "../Submit-New/options/quoctich.json"
import cuakhau from "../Submit-New/options/cuakhaunhapcanh.json"

import Cookies from "universal-cookie"
import Swal from "sweetalert2"

// Loading
import LoadingContext from "context/loading.context"

LicenseManager.setLicenseKey(AG_GRID_KEY)

const ListEvisaChina = () => {
  const { t } = useTranslation()
  const cookies = new Cookies()
  const gridRef = useRef()
  const [heightTable, setHeightTable] = useState(500)
  const [data, setData] = useState()
  const [isOpen, setIsOpen] = useState(false)
  const [item, setItem] = useState({})
  const [payload, setPayload] = useState({})
  const [canSubmit, setCanSubmit] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isEditAnhChanDung, setIsEditAnhChanDung] = useState(false)
  const [isEditAnhHoChieu, setIsEditAnhHoChieu] = useState(false)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [selectedDate, setSelectedDate] = useState()
  const { setLoading } = useContext(LoadingContext)

  const optionDateSelect = [
    { label: "Hôm nay", value: "today" },
    { label: "Hôm qua", value: "yesterday" },
    { label: "3 ngày trước", value: "3daysago" },
    { label: "7 ngày trước", value: "7daysago" },
    { label: "15 ngày trước", value: "15daysago" },
    { label: "30 ngày trước", value: "30daysago" }
  ]

  const handleDateSelect = value => {
    let today = new Date()
    let last_date = new Date()
    last_date.setDate(today.getDate() - 1)
    setEndDate(last_date)
    switch (value) {
      case "today":
        setStartDate(today)
        setEndDate(today)
        break
      case "yesterday":
        let yesterday = new Date(today)
        yesterday.setDate(today.getDate() - 1)
        setStartDate(yesterday)
        break
      case "3daysago":
        let threedaysago = new Date(today)
        threedaysago.setDate(today.getDate() - 3)
        setStartDate(threedaysago)
        break
      case "7daysago":
        let sevendaysago = new Date(today)
        sevendaysago.setDate(today.getDate() - 7)
        setStartDate(sevendaysago)
        break
      case "15daysago":
        let fifteendaysago = new Date(today)
        fifteendaysago.setDate(today.getDate() - 15)
        setStartDate(fifteendaysago)
        break
      case "30daysago":
        let thirtydaysago = new Date(today)
        thirtydaysago.setDate(today.getDate() - 30)
        setStartDate(thirtydaysago)
        break
      default:
        setStartDate()
        setEndDate()
        setSelectedDate(null)
        break
    }
  }

  const validateStartAndEnd = () => {
    if (startDate && endDate) {
      if (startDate > endDate) {
        return true
      } else {
        return false
      }
    }
    return null
  }

  const toggle = () => {
    setIsEdit(false)
    if (isOpen) {
      setIsOpen(false)
      setItem({})
      setPayload({})
      setCanSubmit(false)
      setIsEditAnhChanDung(false)
      setIsEditAnhHoChieu(false)
    } else {
      setIsOpen(true)
    }
  }

  const getFirstAndLastName = fullname => {
    let nameParts = fullname.split(" ")
    let first_name = nameParts[0]
    let last_name = nameParts.slice(1).join(" ")
    return { first_name, last_name }
  }

  async function checkImage(url) {
    return new Promise(resolve => {
      const imageFound = () => {
        resolve(true)
      }

      const imageNotFound = () => {
        resolve(false)
      }

      const tester = new Image()
      tester.addEventListener("load", imageFound)
      tester.addEventListener("error", imageNotFound)
      tester.src = url
    })
  }

  async function redownImage(id) {
    Swal.fire({
      title: "",
      text: "System is re-downloading image",
      icon: "warning",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false
    })
    RedownImageEvisa({ id: id })
      .then(async res => {
        // Swal.fire({
        //   title: "Images downloaded successfully",
        //   icon: "success",
        //   timer: 3000,
        //   timerProgressBar: true,
        //   showCancelButton: false,
        //   showConfirmButton: true,
        // })
        Swal.close()
        toggle()
      })
      .catch(err => {
        Swal.fire({
          title: "Error",
          text: err?.response?.data,
          icon: "error"
        })
      })
  }

  //parse from "CHN" => "China"
  const parseQuocTich = quoc_tich_iso3 => {
    const foundItem = quoc_tich.find(item => item.value === quoc_tich_iso3)

    if (foundItem) {
      return foundItem.label
    } else {
      return null // Return null or handle the case when the value is not found
    }
  }

  //parse from "Sân bay Nội Bài" => "SNB"
  const parseCuaKhau = cuakhau_label => {
    const foundItem = cuakhau.find(item => item.label === cuakhau_label)
    if (foundItem) {
      return foundItem.value
    } else {
      return null // Return null or handle the case when the value is not found
    }
  }

  const set_email_by_fullname = (ho, ten, id) => {
    let email_valid = `${ho !== "" ? `${String(ho).toLowerCase().replace(/ /g, "_")}` : ""}${
      ten !== "" ? `_${String(ten).toLowerCase().replace(/ /g, "_")}` : ""
    }${id !== "" ? `_${String(id).toLowerCase()}` : ""}@visavietnam.biz`

    return email_valid
  }

  async function GetDataForSubmit(item) {
    let can_submit = true
    let quoc_tich_label = parseQuocTich(item.country)
    let payload = {
      ho: item.surname,
      chu_dem_va_ten: item.name,
      quoc_tich: item.country,
      noi_sinh: item.country,
      ngay_thang_nam_sinh: item.birth,
      gioi_tinh: item.sex === true ? "M" : "F",
      ngay_cap: item.issue_date,
      ngay_het_han: item.expiry_date,
      so_cmnd: item.personal_id,
      ton_giao: "Không",
      da_dung_ho_chieu_khac_nhap_canh_viet_nam: "Không",
      co_nhieu_quoc_tich_khong: "Không",
      co_ho_chieu_con_gia_tri_khac_khong: "Không",
      loai_ho_chieu: "PT",
      so_ho_chieu: item.personal_id,
      co_quan_cap: quoc_tich_label,
      muc_dich_nhap_canh: item.visaType === "working" ? "39" : "2",
      muc_dich_nhap_canh_label: item.visaType === "working" ? "làm việc" : "du lịch",
      gia_tri_thi_thuc_dien_tu: item.multipleEntry === true ? "n" : "1",
      thoi_gian_nhap_canh_tu_ngay: item.entry_date,
      thoi_gian_nhap_canh_den_ngay: item.departure_date,
      thoi_gian_du_dinh_cu_tru: item.stayIn,
      cua_khau_du_dinh_nhap_canh: parseCuaKhau(item.chinaoption[0].addToGetVisa),
      cua_khau_du_dinh_xuat_canh: parseCuaKhau(item.chinaoption[0].addToGetVisa),
      ngay_du_dinh_nhap_canh: item.entry_date,
      co_quan_du_kien_lien_he_khi_vao_viet_nam: "Không",
      nghe_nghiep_hien_tai: "Nhân viên",
      ten_cong_ty: "Hongshang Material Technology Co., Ltd.",
      chuc_vu: "Nhân viên",
      dia_chi_co_quan: "Anji Wall Street Industrial Valley, Xixiangtang District, Nanning City",
      so_dien_thoai_co_quan: "0755-81766666",
      dia_chi_cu_tru_tai_viet_nam_tinh_tp: "101",
      dia_chi_cu_tru_tai_viet_nam_quan_huyen: "Quận Thanh Xuân",
      dia_chi_cu_tru_tai_viet_nam_phuong_xa: "Phường Nhân Chính",
      dia_chi_cu_tru_tai_viet_nam_chi_tiet: "44 Nguyễn Ngọc Nại, Khương Thượng, Thanh Xuân",
      dia_chi_cu_tru_tai_viet_nam_so_dien_thoai: "0369686777",
      checkbox_cam_doan_khai_bao_tam_tru: "1",
      da_tung_den_viet_nam_trong_1_nam: "Không",
      co_nguoi_than_o_viet_nam: "Không",
      kinh_phi_du_tinh: Number(item.stayIn) * 35,
      nguoi_dam_bao_kinh_phi_chuyen_di: "Tự túc",
      da_mua_bao_hiem_chua: "Không",
      hinh_thuc_chi_tra: "Tiền mặt",
      dia_diem_de_nghi: item.country,
      checkbox_xin_cam_doan: "1",
      email: set_email_by_fullname(item.surname, item.name, item.personal_id),
      otp: "",
      so_dien_thoai: "18275882992",
      dia_chi_lien_lac: "No. 19, Minzhu Road, Qingxiu District, Nanning City, Guangxi",
      noi_o_hien_nay: "",
      lien_lac_khan_cap_ho_va_ten: "SU DUO",
      lien_lac_khan_cap_noi_o_hien_nay: "No. 234 Qingnian Road, Gaotang County, Liaocheng City, Shandong Province",
      lien_lac_khan_cap_so_dien_thoai: "0771-5624382",
      lien_lac_khan_cap_quan_he: "",
      anh_chan_dung_url: item.avatar_url,
      anh_ho_chieu_url: item.passport_img_url,
      fromChina: true,
      _id: item._id,
      urgent: Number(item?.fee?.id_vn),
      ma_doi_tac: cookies.get(STORAGE_KEY.madoitac),
      vip: item.vip === true ? 1 : 0
    }

    try {
      let res = await GetRandomAddressEvisa()
      let result = res.data
      payload.dia_chi_cu_tru_tai_viet_nam_tinh_tp = result.thanhpho_value_a05
      payload.dia_chi_cu_tru_tai_viet_nam_quan_huyen = result.quan_label
      payload.dia_chi_cu_tru_tai_viet_nam_phuong_xa = result.phuong_label
      payload.dia_chi_cu_tru_tai_viet_nam_chi_tiet = result.diachi
      payload.dia_chi_cu_tru_tai_viet_nam_so_dien_thoai = result.sdt_vietnam
    } catch (error) {
      can_submit = false
      payload = {}
    }

    try {
      let res = await GetRandomFakeInfoEvisa(quoc_tich_label)
      let result = res.data
      if (result.msg) {
        can_submit = false
      } else {
        payload.so_dien_thoai = result.ttll_so_dien_thoai
        payload.dia_chi_lien_lac = result.ttll_dia_chi_lien_lac
        payload.lien_lac_khan_cap_ho_va_ten = result.ttll_lhkc_ho_va_ten
        payload.lien_lac_khan_cap_so_dien_thoai = result.ttll_lhkc_so_dien_thoai
        payload.lien_lac_khan_cap_noi_o_hien_nay = result.ttll_lhkc_noi_o_hien_nay
        payload.ten_cong_ty = result.ttnn_ten_cong_ty
        payload.so_dien_thoai_co_quan = result.ttnn_sdt_cong_ty
        payload.dia_chi_co_quan = result.ttnn_dia_chi_cong_ty
      }
    } catch (error) {
      can_submit = false
      payload = {}
    }

    return { payload, can_submit }
  }

  /**Start config ag */
  let gridStyle = { minHeight: 750, marginTop: 10 }
  if (heightTable) {
    gridStyle.minHeight = "auto"
    gridStyle.height = heightTable + "px"
  }
  useEffect(() => {
    const height = getHeight(62)
    setHeightTable(height)
  }, [])

  window.addEventListener("resize", function () {
    const height = getHeight(62)
    setHeightTable(height)
  })

  const defaultColDef = useMemo(() => {
    return {
      // flex: 1,
      minWidth: 150,
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }
    }
  }, [])
  const columnDefs = [
    {
      headerName: t("Surname"),
      field: "surname",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true,
      pinned: "left"
    },
    {
      headerName: t("Customer Name"),
      field: "name",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true,
      pinned: "left"
    },
    // {
    //   headerName: t("Status"),
    //   field: "status",
    //   resizable: true,
    //   floatingFilter: true,
    //   filter: "agTextColumnFilter",
    //   sortable: true,
    //   pinned: "left"
    // },
    {
      headerName: t("Send"),
      field: "status",
      resizable: true,
      floatingFilter: true,
      filter: "agSetColumnFilter",
      cellRendererSelector: params => {
        const send = {
          component: AgButton
        }
        if (params.data) {
          return send
        }
      },
      cellRendererParams: {
        setItem,
        toggle,
        checkImage,
        redownImage,
        GetDataForSubmit,
        setPayload,
        setCanSubmit
      },
      pinned: "left"
    },
    {
      headerName: t("Date of birth"),
      field: "birth",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true
    },
    {
      headerName: t("Gender"),
      field: "sex_title",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true
    },
    {
      headerName: t("Passport number"),
      field: "personal_id",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true
    },
    {
      headerName: t("Urgent"),
      field: "urgent",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true,
      cellRendererSelector: params => {
        const send = {
          component: AgUrgentText
        }
        if (params.data) {
          return send
        }
      }
    },
    {
      headerName: t("Value of visa"),
      field: "multipleEntry_title",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true,
      cellRendererSelector: params => {
        const send = {
          component: AgValueOfVisa
        }
        if (params.data) {
          return send
        }
      }
    },
    {
      headerName: t("Entry purpose"),
      field: "visaType_title",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true,
      cellRendererSelector: params => {
        const send = {
          component: AgMucDichNhapCanh
        }
        if (params.data) {
          return send
        }
      }
    },
    {
      headerName: t("Number of days of stay"),
      field: "stayIn",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true,
      cellRendererSelector: params => {
        const send = {
          component: AgStayIn
        }
        if (params.data) {
          return send
        }
      }
    },
    {
      headerName: t("Date of Entry"),
      field: "entry_date",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true
    },
    {
      headerName: t("Departure Date"),
      field: "departure_date",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true
    },
    {
      headerName: t("Nationality"),
      field: "country",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true,
      cellRendererSelector: params => {
        const send = {
          component: AgCountry
        }
        if (params.data) {
          return send
        }
      }
    },
    {
      headerName: "Vip",
      field: "vip",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true,
      cellRendererSelector: params => {
        const text = {
          component: AgVip
        }
        if (params.data) {
          return text
        }
      }
    },
    {
      headerName: t("Date Created"),
      field: "createAt",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true
    },
    {
      headerName: t("Visa receiving place"),
      field: "addToGetVisa",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true,
      minWidth: 350
    }
  ]
  const onGridReady = useCallback(params => {
    setLoading(true)
    GetListEvisaChina()
      .then(res => {
        let response = res.data
        let temp = []
        response.map((item, index) => {
          const { first_name, last_name } = getFirstAndLastName(item.name)
          item.createAt = moment(item.create_at).format("DD/MM/YYYY HH:mm:ss")
          item.avatar_url = `${API_URL_2}${item.avatar}`
          item.avatar = `${API_URL_2}${item.avatar}`
          item.passport_img_url = `${API_URL_2}${item.passport_img}`
          item.passport_img = `${API_URL_2}${item.passport_img}`
          item.status = item.isSend && item.isSend === true ? t("Sent") : t("Unsent")
          item.surname = first_name
          item.name = last_name
          item.birth = item.dob
          item.sex_title = item.sex === true ? t("Male") : t("Female")
          item.urgent = item?.fee?.title_vn
          item.vip = item.vip === false ? t("No") : t("Yes")
          item.addToGetVisa = item.chinaoption && item.chinaoption.length > 0 ? item.chinaoption[0].addToGetVisa : ""
          item.multipleEntry_title = item.multipleEntry === true ? t("Many times") : t("Once time")
          item.visaType_title = item.visaType === "working" ? t("Work") : t("Travel")
          temp.push(item)
        })

        // Deplay loading
        if (res.length > 100) {
          setLoading(false)
          setData(temp)
        } else {
          setTimeout(() => {
            setLoading(false)
            setData(temp)
          }, 500)
        }
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
      })
  })
  /**End config ag */

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agFilteredRowCountComponent", align: "left" },
        { statusPanel: "agAggregationComponent", align: "left" },
        { statusPanel: "agSelectedRowCountComponent" }
      ]
    }
  }, [])

  return (
    <>
      <div className="page-content">
        <Container className="body" fluid={true}>
          <Breadcrumb title={t("EVISA Profile")} breadcrumbItem={t("Receiving Evisa List")} />
          <Modal
            isOpen={isOpen}
            toggle={toggle}
            item={item}
            can_submit={canSubmit}
            payload={payload}
            redownImage={redownImage}
            gridRef={gridRef}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            isEditAnhChanDung={isEditAnhChanDung}
            isEditAnhHoChieu={isEditAnhHoChieu}
            setIsEditAnhChanDung={setIsEditAnhChanDung}
            setIsEditAnhHoChieu={setIsEditAnhHoChieu}
            GetDataForSubmit={GetDataForSubmit}
          />
          <Row>
            <Col>
              <div className="js-height">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="d-flex me-3">
                      <Button
                        className="me-2"
                        onClick={() => {
                          gridRef.current.api.deselectAll()
                        }}
                      >
                        {t("Clear row selected")}
                      </Button>
                      <Button
                        className=""
                        onClick={() => {
                          gridRef.current.api.setFilterModel(null)
                        }}
                      >
                        {t("Clear filter")}
                      </Button>
                    </div>
                    <div className="d-flex me-3" style={{ height: "20px" }}>
                      <div className="vr"></div>
                    </div>
                    <div className="me-2">
                      <DatePicker
                        placeholderText="dd/mm/yyyy"
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        selected={startDate || ""}
                        onChange={e => {
                          setSelectedDate(null)
                          setStartDate(e)
                        }}
                      />
                    </div>
                    <div className="me-2">
                      <DatePicker
                        placeholderText="dd/mm/yyyy"
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        selected={endDate || ""}
                        onChange={e => {
                          setSelectedDate(null)
                          setEndDate(e)
                        }}
                      />
                    </div>
                    <div className="me-2">
                      <Select
                        menuPortalTarget={document.body}
                        styles={{
                          menu: provided => ({ ...provided, zIndex: 9999 })
                        }}
                        options={optionDateSelect}
                        value={selectedDate}
                        placeholder={t("Select time")}
                        onChange={e => {
                          setSelectedDate(e)
                          handleDateSelect(e.value)
                        }}
                      />
                    </div>
                    <Button
                      onClick={() => {
                        handleDateSelect()
                      }}
                      color="danger"
                    >
                      {" "}
                      {t("Reset")}{" "}
                    </Button>
                  </div>
                </div>
                {validateStartAndEnd() === true && (
                  <p className="font-weight-400 mb-0 text-danger pb-2 js-height">
                    {t("Check your start or end date again")}.
                  </p>
                )}
              </div>
            </Col>
          </Row>
          <div className="ag-theme-alpine" style={gridStyle}>
            <AgGridReact
              // rowHeight={150}
              ref={gridRef}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowData={data}
              rowSelection={"multiple"}
              suppressRowClickSelection={true}
              colResizeDefault={true}
              pagination={true}
              onGridReady={onGridReady}
              enableCellChangeFlash={true}
              onFirstDataRendered={params => {
                params.columnApi.autoSizeAllColumns()
              }}
              statusBar={statusBar}
              // Hidden loading overlay
              overlayLoadingTemplate="<span></span>"
            />
          </div>
        </Container>
      </div>
    </>
  )
}

export default ListEvisaChina
