export const API_URL = process.env.API_URL || "https://quanly.visavietnam.biz/api/v1"
export const API_URL_2 = process.env.API_URL_2 || "https://quanly.visavietnam.biz/api/"
export const API_URL_PYTHON_SUBMIT_EVISA =
  process.env.API_URL_PYTHON_SUBMIT_EVISA || "https://quanly.visavietnam.biz/submit_evisa/"
export const API_URL_FILE_TEMPLATE_THONG_TIN_KHACH_HANG =
  process.env.API_URL_FILE_TEMPLATE_THONG_TIN_KHACH_HANG ||
  "https://quanly.visavietnam.biz/api/templates/FileMauNhapThuCong.xlsx"

// export const API_URL = process.env.API_URL || "http://localhost:8000/api/v1"
// export const API_URL_2 = process.env.API_URL_2 || "http://localhost:8000/"
// export const API_URL_PYTHON_SUBMIT_EVISA = process.env.API_URL_PYTHON_SUBMIT_EVISA || "http://localhost:1510/"
// export const API_URL_FILE_TEMPLATE_THONG_TIN_KHACH_HANG = process.env.API_URL_FILE_TEMPLATE_THONG_TIN_KHACH_HANG || "http://localhost:8000/templates/FileMauNhapThuCong.xlsx"

export const API_URL_PYTHON_EXPORT_EXCEL =
  process.env.API_URL_PYTHON_EXPORT_EXCEL || "https://quanly.visavietnam.biz/export_excel/api/insert_data"
export const API_URL_PYTHON_CHECK_MAHOSO =
  process.env.API_URL_PYTHON_CHECK_MAHOSO || "https://quanly.visavietnam.biz/dichvucong/tracuu"

export const AG_GRID_KEY =
  process.env.AG_GRID_KEY || "DownloadDevTools_COM_NDEwMjM0NTgwMDAwMA==59158b5225400879a12a96634544f5b6"
export const DATA_GRID_KEY =
  process.env.DATAGRID_KEY ||
  "AppName=ESPOTTECHNOLOGYJOINTSTOCKCOMPANYApp,Company=ESPOTTECHNOLOGYJOINTSTOCKCOMPANY,ExpiryDate=2024-08-18T00:00:00.000Z,LicenseDeveloperCount=1,LicenseType=single_app,Ref=ESPOTTECHNOLOGYJOINTSTOCKCOMPANYLicenseRef,Z=986226287-1272928755-234746756-2084690133-1758818143205346779"
export const EMAIL_DOMAIN_EVISA = process.env.EMAIL_DOMAIN_EVISA || "visavietnam.biz"
