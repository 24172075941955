import React, { forwardRef, useImperativeHandle, useState } from "react"
import { Badge } from "reactstrap"

const ButtonRenderer = forwardRef((props, ref) => {
  const [haveDetail, setHaveDetail] = useState(props.value)

  useImperativeHandle(ref, () => {
    return {
      refresh(params) {}
    }
  })

  const converTrangThai = value => {
    switch (value) {
      case "Khẩn trong ngày":
        return "primary"
      case "Khẩn 1 ngày":
        return "danger"
      case "Khẩn 2 ngày":
        return "info"
      case "Khẩn 3 ngày":
        return "warning"
      case "Không":
        return "success"
      case "Khẩn 4 giờ":
        return "primary"
      default:
        return ""
    }
  }

  return (
    <>
      {/* {haveDetail && (
        <span
            className={converTrangThai(props.value)}
        >
            {props.value}
        </span>
      )} */}
      {haveDetail && <Badge className={"font-size-15 badge-soft-" + converTrangThai(props.value)}>{props.value}</Badge>}
    </>
  )
})

ButtonRenderer.displayName = "MyImageRenderer"

export default ButtonRenderer
