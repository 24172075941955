import {
  Col,
  Row,
  Modal,
  ModalBody,
  Card,
  CardBody,
  Container,
  Input,
  Form,
  FormFeedback,
  ModalHeader
} from "reactstrap"

//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//Import Select
import Select from "react-select"

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import withRouter from "components/Common/withRouter"
import Swal from "sweetalert2"

//formik
import { useFormik } from "formik"
import * as Yup from "yup"

import { STORAGE_KEY } from "../../../configs/app.const"

import { SendPassport, GetChinaOption, UpdateChinaData, GetUserFee } from "services"

import Cookies from "universal-cookie"

const ModalEvisa = props => {
  let { item, isOpen, toggle, redownImage, gridRef } = props
  const cookies = new Cookies()
  const [chinaOptionSelect, setChinaOptionSelect] = useState([])
  const [addToGetVisaSelect, setAddToGetVisaSelect] = useState({})

  const [userFeeSelect, setUserFeeSelect] = useState([])
  const [urgentSelect, setUrgentSelect] = useState({})

  const optionGender = [
    { value: 1, label: props.t("Male") },
    { value: 0, label: props.t("Female") }
  ]

  const convertDateFromStringToValidDpk = inputDate => {
    // Split the input date into day, month, and year
    const [day, month, year] = inputDate.split("/")

    // Create a new Date object with the input date
    const originalDate = new Date(`${month}/${day}/${year}`)

    // Get the year, month, and day from the Date object
    const newYear = originalDate.getFullYear()
    const newMonth = (originalDate.getMonth() + 1).toString().padStart(2, "0") // Months are 0-indexed
    const newDay = originalDate.getDate().toString().padStart(2, "0")

    // Create the converted date string in the "yyyy-mm-dd" format
    const convertedDateString = `${newYear}-${newMonth}-${newDay}`
    return convertedDateString
  }

  const convertDateFromTypeDate = date => {
    var day = date.getDate()
    var month = date.getMonth() + 1 // Months are zero-based
    var year = date.getFullYear()

    // Add leading zeros if day or month is less than 10
    if (day < 10) {
      day = "0" + day
    }
    if (month < 10) {
      month = "0" + month
    }

    return day + "/" + month + "/" + year
  }

  const convertStringDateToDate = (dateString, splitBy) => {
    let dateParts = dateString.split(splitBy)
    return new Date(dateParts[2], dateParts[1] - 1, dateParts[0])
  }

  const handleNgayNhapXuatCanh = (ngay_nhap_canh, stay_in) => {
    if (ngay_nhap_canh !== "" && stay_in > 0) {
      let entry_date = new Date(ngay_nhap_canh)
      let departure_date = new Date(entry_date.setDate(entry_date.getDate() + Number(stay_in) - 1))
      validationUpdate.setFieldValue("departure_date", convertDateFromTypeDate(departure_date))
    }
  }

  async function handeSubmit(phan_tach_khach_hang) {
    try {
      toggle()
      Swal.fire({
        title: "",
        text: "Hệ thống đang xử lý",
        icon: "warning",
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false
      })
      const fullName = item.name
      const spacePos = fullName.indexOf(" ")
      const surname = fullName.slice(0, spacePos)
      const name = fullName.slice(spacePos + 1)
      let convert_value = item?.convert_value
      console.log("item when send", item)
      SendPassport({
        _id: item._id,
        surname: surname,
        name: name,
        dob: item.birth,
        sex: Number(item.sex) === 0 ? "F" : "M",
        country: item.country,
        personal_id: item.personal_id,
        entry_date: item.entry_date,
        departure_date: item.departure_date,
        stayIn: item.stayIn,
        multipleEntry: convert_value.gia_tri_thi_thuc,
        create_date: "",
        expriation_date: "",
        vip: item.vip,
        urgent: item.urgent.id_vn,
        wayToGetVisa: item.wayToGetVisa,
        addToGetVisa: item.addToGetVisa,
        ma_doi_tac: cookies.get(STORAGE_KEY.madoitac),
        ma_nhan_vien: cookies.get(STORAGE_KEY.code),
        passport_img: item.passport_img,
        fromChina: 1,
        phantachkhachhang: phan_tach_khach_hang
      })
        .then(res => {
          Swal.fire({
            title: "Hộ chiếu gửi thành công",
            icon: "success",
            timer: 3000,
            timerProgressBar: true
          })
          gridRef.current.api.forEachNode(rowNode => {
            if (item._id === rowNode.data._id) {
              let updated = rowNode.data
              updated.isSend = true
              updated.status = props.t("Sent")
              updated.can_submit = false
              rowNode.updateData(updated)
            }
          })
        })
        .catch(err => {
          Swal.fire({
            title: "Hệ thống gửi hộ chiếu lỗi",
            text: `Vui lòng kiểm tra lại các trường thông tin`,
            icon: "error",
            timer: 3000,
            timerProgressBar: true
          })
        })
    } catch (error) {
      validationSubmit.resetForm()
      Swal.fire({
        title: "Lỗi",
        text: `Vui lòng kiểm tra lại các trường thông tin (Khẩn hoặc Nơi nhận thị thực)`,
        icon: "error",
        timer: 3000,
        timerProgressBar: true
      })
    }
  }

  const validationSubmit = useFormik({
    initialValues: {
      phan_tach_khach_hang: ""
    },
    validationSchema: Yup.object({
      phan_tach_khach_hang: Yup.string().required()
    }),
    onSubmit: async values => {
      await handeSubmit(values.phan_tach_khach_hang)
    }
  })

  const validationUpdate = useFormik({
    initialValues: {
      name: "",
      birth: "",
      sex: "",
      country: "",
      personal_id: "",
      entry_date: "",
      departure_date: "",
      stayIn: 0,
      multipleEntry: 0,
      vip: "0",
      wayToGetVisa: "",
      addToGetVisa: "",
      port_type: "",
      fee: ""
    },
    validationSchema: Yup.object({}),
    onSubmit: values => {
      values.id = item._id
      if (values.port_type === "") {
        values.port_type = item.port_type
      }
      Object.keys(values).map((key, index) => {
        if (Object.keys(item).includes(key)) {
          item[key] = values[key]
        }
      })
      item["sex_title"] = Number(values["sex"]) === 1 ? props.t("Male") : props.t("Female")

      item["Vip"] = values["vip"] === "1" ? props.t("Yes") : props.t("No")
      item["urgent_title"] = values.fee.title_vn

      item["urgent"].id_vn = values.fee.id
      item["urgent"].title_vn = values.fee.title_vn

      item["urgent"].gia_tri_thi_thuc = values.multipleEntry
      item["multipleEntry_title"] = Number(values.multipleEntry) === 1 ? props.t("Many times") : props.t("Once time")

      values.urgent = item["urgent"]
      console.log("values", values)
      console.log("item", item)
      UpdateChinaData(values).then(res => {
        setUrgentSelect({})
        props.setIsEdit(false)
      })
    }
  })

  useEffect(() => {
    if (item && Object.keys(item).length > 0) {
      console.log(item)
      validationUpdate.setFieldValue("name", item.name)
      validationUpdate.setFieldValue("birth", item.birth)
      validationUpdate.setFieldValue("sex", item.sex)
      validationUpdate.setFieldValue("country", item.country)
      validationUpdate.setFieldValue("personal_id", item.personal_id)
      validationUpdate.setFieldValue("entry_date", item.entry_date)
      validationUpdate.setFieldValue("departure_date", item.departure_date)
      validationUpdate.setFieldValue("stayIn", item.stayIn)
      validationUpdate.setFieldValue("vip", item.vip)
      validationSubmit.setFieldValue("port_type", item.port_type)
      validationUpdate.setFieldValue("addToGetVisa", item.addToGetVisa)
      validationUpdate.setFieldValue("wayToGetVisa", item.wayToGetVisa)
      validationUpdate.setFieldValue("multipleEntry", Number(item.convert_value.gia_tri_thi_thuc))
      GetChinaOption().then(res => {
        let data = res.data
        let option = []
        data.map((value, index) => {
          if (value.port_type === item.port_type) {
            setAddToGetVisaSelect({ label: value.addToGetVisa, value: value })
          }
          option.push({ label: value.addToGetVisa, value: value })
        })
        setChinaOptionSelect(option)
      })

      GetUserFee("CVT").then(res => {
        let data = res.data
        let option = []
        let check_exist = false
        data.map((value, index) => {
          if (item.urgent && Number(item.urgent.id_vn) === Number(value.id)) {
            setUrgentSelect({ label: value.title_vn, value: value })
            validationUpdate.setFieldValue("fee", value)
            check_exist = true
          }
          option.push({ label: value.title_vn, value: value })
        })
        if (check_exist === false) {
          setUrgentSelect({ label: props.t("Chưa chọn"), value: 0 })
        }
        setUserFeeSelect(option)
      })
    }
  }, [item, props.isOpen])

  return (
    <React.Fragment>
      <Modal size="xl" isOpen={isOpen} toggle={toggle} className="modal-dialog">
        <ModalHeader>
          <div>
            <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
              {props.t("Detail")}
            </h5>
            <button
              onClick={() => {
                validationSubmit.setErrors({})
                validationSubmit.setTouched({})
                validationUpdate.setErrors({})
                validationUpdate.setErrors({})
                validationSubmit.resetForm()
                toggle()
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col xl="12">
                <Form
                  onSubmit={e => {
                    if (props.isEdit === false) {
                      e.preventDefault()
                      validationSubmit.handleSubmit()
                      return false
                    } else {
                      e.preventDefault()
                      validationUpdate.handleSubmit()
                      return false
                    }
                  }}
                >
                  <Card className="w-100">
                    <CardBody className="d-flex justify-content-between p-0">
                      <Col lg={props.isEdit ? 7 : 8}>
                        <Card className="">
                          <h4 className="pb-3">{props.t("Passport photo")}:</h4>
                          <img
                            className="rounded-3 img-fluid"
                            src={item.passport_img}
                            alt="Passport Image"
                            // style={{ maxWidth: "auto", minHeight: "300px" }}
                          />
                        </Card>
                      </Col>
                      <Col lg={props.isEdit ? 5 : 4} className="ps-4">
                        {props.isEdit === true && (
                          <>
                            <h4 className="pb-3">{props.t("Customer information")}:</h4>
                            <div>
                              <h5>
                                <b>{props.t("Fullname")}: </b>
                                {/* {item.name} */}
                              </h5>
                              <Input
                                name="name"
                                value={validationUpdate.values.name}
                                onChange={validationUpdate.handleChange}
                              />
                            </div>
                            <Col lg={12} className="d-flex justify-content-between mt-2">
                              <div className="col-lg-4">
                                <h5>
                                  <b>{props.t("Date of birth")}: </b>
                                </h5>
                                <DatePicker
                                  name="birth"
                                  dateFormat="dd/MM/yyyy"
                                  placeholderText="dd/mm/yyyy"
                                  className={"form-control"}
                                  selected={
                                    (validationUpdate.values.birth &&
                                      new Date(convertDateFromStringToValidDpk(validationUpdate.values.birth))) ||
                                    ""
                                  }
                                  onChange={val => {
                                    validationUpdate.setFieldValue(
                                      "birth",
                                      val === null ? "" : convertDateFromTypeDate(val)
                                    )
                                  }}
                                />
                              </div>
                              <div className="col-lg-3">
                                <h5>
                                  <b>{props.t("Gender")}: </b>
                                  {/* {item.sex === 1 ? props.t("Male") : props.t("Female")} */}
                                </h5>
                                <Input
                                  type="select"
                                  name="sex"
                                  value={validationUpdate.values.sex}
                                  onChange={validationUpdate.handleChange}
                                  invalid={validationUpdate.touched.sex && validationUpdate.errors.sex ? true : false}
                                >
                                  <option hidden label={props.t("Select a gender")} />
                                  {optionGender.map((item, index) => {
                                    return (
                                      <option value={item.value} key={index}>
                                        {item.label}
                                      </option>
                                    )
                                  })}
                                </Input>
                              </div>
                              <div className="col-lg-4">
                                <h5>
                                  <b>{props.t("Nationality")}: </b>
                                  {/* {item.country} */}
                                </h5>
                                <Input
                                  name="country"
                                  value={validationUpdate.values.country}
                                  onChange={validationUpdate.handleChange}
                                />
                              </div>
                            </Col>
                            <Col lg={12} className="d-flex justify-content-between mt-2">
                              <div className="col-lg-4 pe-2">
                                <h5>
                                  <b>{props.t("Passport number")}: </b>
                                  {/* {item.personal_id} */}
                                </h5>
                                <Input
                                  name="personal_id"
                                  value={validationUpdate.values.personal_id}
                                  onChange={validationUpdate.handleChange}
                                />
                              </div>
                              <div className="col-lg-4 pe-2">
                                <h5>
                                  <b>{props.t("Number of days of stay")}:</b>
                                </h5>
                                <Input
                                  name="stayIn"
                                  type="number"
                                  value={validationUpdate.values.stayIn}
                                  onBlur={validationUpdate.handleBlur}
                                  invalid={
                                    validationUpdate.errors.stayIn && validationUpdate.touched.stayIn ? true : false
                                  }
                                  onChange={e => {
                                    validationUpdate.handleChange(e)
                                    handleNgayNhapXuatCanh(convertStringDateToDate(validationUpdate.values.entry_date, "/"), e.target.value)
                                  }}
                                />
                              </div>
                              <div className="col-lg-4">
                                <h5>
                                  <b>{props.t("Value of visa")}:</b>
                                </h5>
                                <Input
                                  name="multipleEntry"
                                  type="select"
                                  value={validationUpdate.values.multipleEntry}
                                  onBlur={validationUpdate.handleBlur}
                                  invalid={
                                    validationUpdate.errors.multipleEntry && validationUpdate.touched.multipleEntry
                                      ? true
                                      : false
                                  }
                                  onChange={validationUpdate.handleChange}
                                >
                                  <option value={0}>{props.t("Once time")}</option>
                                  <option value={1}>{props.t("Many times")}</option>
                                </Input>
                              </div>
                            </Col>
                            <Col lg={12} className="d-flex justify-content-between mt-2">
                              <div className="col-lg-6 pe-2">
                                <h5>
                                  <b>{props.t("Date of Entry")}: </b>
                                  {/* {item.entry_date} */}
                                </h5>
                                <DatePicker
                                  name="entry_date"
                                  dateFormat="dd/MM/yyyy"
                                  placeholderText="dd/mm/yyyy"
                                  className={"form-control"}
                                  selected={
                                    (validationUpdate.values.entry_date &&
                                      new Date(convertDateFromStringToValidDpk(validationUpdate.values.entry_date))) ||
                                    ""
                                  }
                                  onChange={val => {
                                    validationUpdate.setFieldValue(
                                      "entry_date",
                                      val === null ? "" : convertDateFromTypeDate(val)
                                    )
                                    handleNgayNhapXuatCanh(val, validationUpdate.values.stayIn)
                                  }}
                                />
                              </div>
                              <div className="col-lg-6">
                                <h5>
                                  <b>{props.t("Departure Date")}: </b>
                                  {/* {item.departure_date} */}
                                </h5>
                                <DatePicker
                                  name="departure_date"
                                  dateFormat="dd/MM/yyyy"
                                  placeholderText="dd/mm/yyyy"
                                  className={"form-control"}
                                  disabled
                                  selected={
                                    (validationUpdate.values.departure_date &&
                                      new Date(
                                        convertDateFromStringToValidDpk(validationUpdate.values.departure_date)
                                      )) ||
                                    ""
                                  }
                                  onChange={val => {
                                    validationUpdate.setFieldValue(
                                      "departure_date",
                                      val === null ? "" : convertDateFromTypeDate(val)
                                    )
                                  }}
                                />
                              </div>
                            </Col>
                            <Col lg={12} className="d-flex justify-content-between mt-2">
                              <div className="col-lg-6 pe-2">
                                <h6>
                                  <b>{props.t("Urgent")}:</b>{" "}
                                </h6>
                                <Select
                                  name="urgent"
                                  placeholder="Vui lòng chọn"
                                  options={userFeeSelect}
                                  value={urgentSelect}
                                  onBlur={validationUpdate.handleBlur}
                                  onChange={e => {
                                    validationUpdate.setFieldValue("fee", e.value)
                                    setUrgentSelect(e)
                                  }}
                                />
                              </div>
                              <div className="col-lg-6">
                                <h6>
                                  <b>{props.t("Vip")}:</b>{" "}
                                </h6>
                                <Input
                                  name="vip"
                                  type="select"
                                  value={validationUpdate.values.vip}
                                  onBlur={validationUpdate.handleBlur}
                                  onChange={validationUpdate.handleChange}
                                >
                                  <option value="0">{props.t("No")}</option>
                                  <option value="1">{props.t("Yes")}</option>
                                </Input>
                              </div>
                            </Col>
                            <div className="mt-2">
                              <h5>
                                <b>{props.t("Visa receiving place")}:</b> {/* {item.addToGetVisa} */}
                              </h5>
                              <Select
                                name="port_type"
                                placeholder="Vui lòng chọn"
                                options={chinaOptionSelect}
                                value={addToGetVisaSelect}
                                onBlur={validationUpdate.handleBlur}
                                onChange={e => {
                                  validationUpdate.setFieldValue("addToGetVisa", e.label)
                                  validationUpdate.setFieldValue("port_type", e.value.port_type)
                                  validationUpdate.setFieldValue("addToGetVisa", e.value.addToGetVisa)
                                  validationUpdate.setFieldValue("wayToGetVisa", e.value.wayToGetVisa)
                                  setAddToGetVisaSelect(e)
                                }}
                              />
                            </div>
                          </>
                        )}
                        {props.isEdit === false && (
                          <>
                            <h4 className="pb-3">{props.t("Customer information")}:</h4>
                            <h5>
                              <b>{props.t("Fullname")}: </b> {item.name}
                            </h5>
                            <h5>
                              <b>{props.t("Date of birth")}: </b> {item.birth}
                            </h5>
                            <h5>
                              <b>{props.t("Gender")}: </b>{" "}
                              {Number(item.sex) === 1 ? props.t("Male") : props.t("Female")}
                            </h5>
                            <h5>
                              <b>{props.t("Passport number")}: </b> {item.personal_id}
                            </h5>
                            <h5>
                              <b>{props.t("Nationality")}: </b> {item.country}
                            </h5>
                            <h5>
                              <b>{props.t("Date of Entry")}: </b> {item.entry_date}
                            </h5>
                            <h5>
                              <b>Thời gian lưu trú: </b> {item.stayIn} ngày{" "}
                            </h5>
                            <h5>
                              <b>{props.t("Departure Date")}: </b> {item.departure_date}
                            </h5>
                            <h5>
                              <b>{props.t("Value of visa")}: </b> {item.multipleEntry_title}
                            </h5>
                            <h5>
                              <b>{props.t("Visa receiving place")}:</b> {item.addToGetVisa}
                            </h5>
                            <h5>
                              <b>{props.t("Urgency")}: </b> {item.urgent?.title_vn}{" "}
                            </h5>
                            <h5>
                              <b>Vip: </b> {item.vip === "1" ? props.t("Yes") : props.t("No")}{" "}
                            </h5>
                          </>
                        )}
                        <Card className="d-flex flex-wrap gap-2 mt-2">
                          {item.can_submit && item.can_submit === true && (
                            <>
                              {props.isEdit === false && (
                                <div className="mt-3">
                                  <h4>{props.t("Customer segmentation")}:</h4>
                                  <Input
                                    name="phan_tach_khach_hang"
                                    type="text"
                                    className="form-control"
                                    value={validationSubmit.values.phan_tach_khach_hang}
                                    onChange={validationSubmit.handleChange}
                                    onBlur={validationSubmit.handleBlur}
                                    invalid={
                                      validationSubmit.touched.phan_tach_khach_hang &&
                                      validationSubmit.errors.phan_tach_khach_hang
                                        ? true
                                        : false
                                    }
                                  />
                                  {validationSubmit.touched.phan_tach_khach_hang &&
                                  validationSubmit.errors.phan_tach_khach_hang ? (
                                    <FormFeedback type="invalid">
                                      {validationSubmit.errors.phan_tach_khach_hang}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              )}
                              <Col className="d-flex mt-2 justify-content-center">
                                <button
                                  type="button"
                                  onClick={async () => await redownImage(item.passport_img)}
                                  className="btn btn-primary me-2"
                                >
                                  <i className="bx bx-smile font-size-16 align-middle me-2"></i>{" "}
                                  {props.t("Reload photo")}
                                </button>
                                <button
                                  className="btn btn-danger me-2"
                                  type="button"
                                  onClick={() => {
                                    if (props.isEdit === true) {
                                      props.setIsEdit(false)
                                    } else {
                                      props.setIsEdit(true)
                                    }
                                  }}
                                >
                                  {props.isEdit === false ? props.t("Edit") : props.t("Cancel")}
                                </button>
                                <button type="submit" className="btn btn-success ">
                                  <i className="bx bx-check-double font-size-16 align-middle me-2"></i>{" "}
                                  {props.isEdit === false ? props.t("Send records") : props.t("Edit")}
                                </button>
                              </Col>
                            </>
                          )}
                        </Card>
                      </Col>
                    </CardBody>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

ModalEvisa.propTypes = {
  t: PropTypes.any
}

export default withRouter(withTranslation()(ModalEvisa))
