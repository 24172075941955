import React, { Fragment, useState } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { useTranslation, withTranslation, Trans } from "react-i18next"

const ModalTooltip = ({ title, modalTitle, children }) => {
  const { t, i18n } = useTranslation()

  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  return (
    <Fragment>
      <div className="modal-tooltip-button">
        <span onClick={toggle}>{title || <i className="bx bxs-help-circle font-size-16 align-middle me-2"></i>}</span>
      </div>
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <ModalHeader toggle={toggle}>{modalTitle || t("Help")}</ModalHeader>
        <ModalBody>{children}</ModalBody>
      </Modal>
    </Fragment>
  )
}

export default ModalTooltip
