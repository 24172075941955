import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// //Invoices
// import InvoicesList from "../pages/Invoices/invoices-list"
// import InvoiceDetail from "../pages/Invoices/invoices-detail"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
// import Register from "../pages/Authentication/Register"
// import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
// import Dashboard from "../pages/Dashboard/index"
// import DashboardSaas from "../pages/Dashboard-saas/index"
// import DashboardCrypto from "../pages/Dashboard-crypto/index"
// import Blog from "../pages/Dashboard-Blog/index"
// import DashboardJob from "../pages/DashboardJob/index"

//Pages
// import PagesMaintenance from "../pages/Utility/pages-maintenance"
// import PagesComingsoon from "../pages/Utility/pages-comingsoon"
import Pages404 from "../pages/Utility/pages-404"
// import Pages500 from "../pages/Utility/pages-500"

//SubmitProfile
import SubmitProfile from "pages/SubmitProfile"

//SubmitProfile by excel
import SubmitProfileByExcel from "pages/SubmitProfileByExcel"

//SentList
import SentList from "pages/SentList"

//ListChina Data
import ListChinaData from "pages/ListChinaData"

//List BCA Data
import ListBCAResult from "pages/ResultBcaList"

//List worker
import ListWorker from "pages/ListWorker"

//Evisa Submit
import EvisaSubmitNew from "pages/Evisa/Submit-New"

//Evisa List Sent
import EvisaListSent from "pages/Evisa/ListEvisaSent"

//Evisa List From China
import ListEvisaChina from "pages/Evisa/ListEvisaChina"

//Passport by MaHoSo
// import PassportByMaHoSo from "pages/PassportByMaHoSo"

//Passport submitted BCA
import SentListSubmittedBca from "pages/SentListSubmittedBCA"

//Tool check DVC
import ToolCheckDvc from "pages/Tools/Dvc"

//Tool check EVISA
import ToolCheckEvisa from "pages/Tools/Evisa"

import ToolSoftware from "pages/Tools/Software"

const authProtectedRoutes = [
  // { path: "/dashboard", component: <Dashboard /> },
  // { path: "/dashboard-saas", component: <DashboardSaas /> },
  // { path: "/dashboard-crypto", component: <DashboardCrypto /> },
  // { path: "/blog", component: <Blog /> },
  // { path: "/dashboard-job", component: <DashboardJob /> },
  { path: "/submit-profile", component: <SubmitProfile /> },
  { path: "/submit-profile/excel", component: <SubmitProfileByExcel /> },
  { path: "/sent-list", component: <SentList /> },
  { path: "/sent-list/submitted", component: <SentListSubmittedBca /> },
  { path: "/list-china-data", component: <ListChinaData /> },
  { path: "/worker/list", component: <ListWorker /> },
  // { path: "/passport/sent/:mahoso", component: <PassportByMaHoSo/>},

  // //profile
  { path: "/profile", component: <UserProfile /> },

  //Invoices
  // { path: "/invoices-list", component: <InvoicesList /> },
  // { path: "/invoices-detail/:id", component: <InvoiceDetail /> },
  // { path: "/invoices-detail", component: <InvoiceDetail /> },
  { path: "/result/bca/list", component: <ListBCAResult /> },

  //Evisa
  { path: "/evisa", component: <EvisaSubmitNew /> },
  { path: "/evisa/list", component: <EvisaListSent /> },
  { path: "/evisa/list/china", component: <ListEvisaChina /> },

  { path: "/tool/dvc", component: <ToolCheckDvc /> },
  { path: "/tool/evisa", component: <ToolCheckEvisa /> },
  { path: "/tool/software", component: <ToolSoftware /> },

  {
    path: "/",
    exact: true,
    component: <Navigate to="/submit-profile" />
  }
]

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  // { path: "/forgot-password", component: <ForgetPwd /> },
  // { path: "/register", component: <Register /> },

  // { path: "/pages-maintenance", component: <PagesMaintenance /> },
  // { path: "/pages-commingsoon", component: <PagesComingsoon /> },
  { path: "*", component: <Pages404 /> }
  // { path: "/pages-500", component: <Pages500 /> },
]

export { authProtectedRoutes, publicRoutes }
