import usFlag from "../assets/images/flags/us.jpg"
import vnFlag from "../assets/images/flags/vn.webp"

const languages = {
  en: {
    label: "English",
    flag: usFlag
  },
  vn: {
    label: "Tiếng Việt",
    flag: vnFlag
  }
}

export default languages
