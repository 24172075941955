import React from "react"
import { useTranslation } from "react-i18next"

const Loading = props => {
  const { loading } = props
  const { t } = useTranslation()
  return (
    <React.Fragment>
      {loading && (
        <div className="block-loading">
          <span className="loading">
            <i className="fas fa-circle-notch fa-spin"></i> {t("Loading...")}
          </span>
        </div>
      )}
    </React.Fragment>
  )
}
export default Loading
