import axios from "../axios"
import { STORAGE_KEY, STORAGE_KEY_TIMEOUT } from "configs/app.const"
import Cookies from "universal-cookie"

const cookies = new Cookies()

export function LoginByCode(data) {
  return axios
    .post("/user/login", {
      code: data.code
    })
    .then(response => {
      let result = response.data
      if (response.status === 200) {
        cookies.set(STORAGE_KEY.auth, result.token, { path: "/", maxAge: STORAGE_KEY_TIMEOUT.user })
        cookies.set(STORAGE_KEY.code, data.code, { path: "/", maxAge: STORAGE_KEY_TIMEOUT.user })
        return 1
      }
    })
    .catch(error => {
      return 0
    })
}

export function LoginByUsernameAndPassword(data) {
  return axios
    .post("/user/doitac/login", {
      username: data.username,
      password: data.password
    })
    .then(response => {
      let result = response.data
      if (response.status === 200) {
        cookies.set(STORAGE_KEY.auth, result.token, { path: "/", maxAge: STORAGE_KEY_TIMEOUT.user })
        cookies.set(STORAGE_KEY.madoitac, data.username, { path: "/", maxAge: STORAGE_KEY_TIMEOUT.user })
        return 1
      }
    })
    .catch(error => {
      // console.error(error.response.data.msg);
      if (error.response && error.response.data) {
        return error.response.data
      }
      return 0
    })
}

export function LoginByUsernameAndPasswordRequireStaff(data) {
  return axios.post("/user/doitac/login", data)
}

export function LoginNhanvienByCodeAndMaDoiTac(data) {
  return axios.post("/user/doitac/nhanvien/login", data)
}

export function LogOut() {
  cookies.remove(STORAGE_KEY.auth)
  cookies.remove(STORAGE_KEY.madoitac)
  cookies.remove(STORAGE_KEY.code)
  cookies.remove(STORAGE_KEY.ho_va_ten)
  cookies.remove(STORAGE_KEY.refresh_token)
}

export function GetUserName() {
  return cookies.get(STORAGE_KEY.madoitac)
}

export function GetFullname() {
  return cookies.get(STORAGE_KEY.ho_va_ten)
}

export function GetWorkerCode() {
  return cookies.get(STORAGE_KEY.code)
}
