import iso3 from "common/country-code/iso3.json"
import country_code from "common/country-code/country-code.json"

function getKeyByValue(obj, value) {
  return Object.keys(obj).find(key => obj[key] === value)
}

function getValueByKey(obj, key) {
  return obj[key]
}

export function convert_country_code_to_country_name(code) {
  try {
    const codeToISO3 = getKeyByValue(iso3, code)
    const countryNameByISO3 = getValueByKey(country_code, codeToISO3)
    if (!countryNameByISO3) return ""

    return countryNameByISO3
  } catch (error) {
    console.log(`Error at convert_country_code_to_country_name`)
    return ""
  }
}

export function convert_country_name_to_country_code(name) {
  try {
    const countryNameToISO3 = getKeyByValue(country_code, name)
    const iso3ToCode = getValueByKey(iso3, countryNameToISO3)
    if (!iso3ToCode) return ""

    return iso3ToCode
  } catch (error) {
    console.log(`Error at convert_country_name_to_country_code`)
    return ""
  }
}
