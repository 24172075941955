import React, { useEffect } from "react"
import { FormGroup, Input, Label } from "reactstrap"
import "./style.scss"

export default function OptionOCR() {
  const getOptionOCR = JSON.parse(localStorage.getItem("isCheckOCR"))
  let defaultOptionOCR = getOptionOCR === null ? true : getOptionOCR
  const [isCheckOCR, setIsCheckOCR] = React.useState(defaultOptionOCR)

  const handleToggle = () => {
    setIsCheckOCR(!isCheckOCR)
  }

  const handleClickLabel = value => {
    setIsCheckOCR(value)
  }

  const handleSaveOptionOCR = value => {
    localStorage.setItem("isCheckOCR", value)
  }

  useEffect(() => {
    handleSaveOptionOCR(isCheckOCR)
  }, [isCheckOCR])

  return (
    <>
      <FormGroup switch className="option-ocr mb-3">
        <Label onClick={() => handleClickLabel(false)}>Mindee</Label>
        <Input type="switch" role="switch" onClick={e => handleToggle(e)} checked={isCheckOCR} />
        <Label onClick={() => handleClickLabel(true)}>SwifttechAI</Label>
      </FormGroup>
    </>
  )
}
