export const STORAGE_KEY = {
  auth: "authUser",
  code: "ma_nhan_vien",
  madoitac: "ma_doi_tac",
  ho_va_ten: "ho_va_ten",
  refresh_token: "refreshToken"
}

export const STORAGE_KEY_TIMEOUT = {
  user: 21600,
  refresh_token: 3600 * 24 * 30
}
