import { initializeApp } from "firebase/app"
import { getMessaging, getToken, onMessage } from "firebase/messaging"

const firebaseConfig = {
  apiKey: "AIzaSyDCgC581KD-EDykFNQ4sPgQkfEWcrdYK4c",
  authDomain: "quanlyvisavietnam.firebaseapp.com",
  projectId: "quanlyvisavietnam",
  storageBucket: "quanlyvisavietnam.appspot.com",
  messagingSenderId: "1026332798838",
  appId: "1:1026332798838:web:2e39e894d79e780df4d457",
  measurementId: "G-RTEBBC002Q"
}

const firebaseApp = initializeApp(firebaseConfig)
const messaging = getMessaging(firebaseApp)

export const fetchToken = setTokenFound => {
  return getToken(messaging, {
    vapidKey: "BN3RV_6Th4lXrhZN78mYkoMFr69Vn5EbtDPN5K0laQDNi2JOLYwP4pvCyviSX8c2TYFt5gbcP_vQjcnu9ejLmI8"
  })
    .then(currentToken => {
      if (currentToken) {
        console.log("current token for client: ", currentToken)
        setTokenFound(true)
      } else {
        console.log("No registration token available. Request permission to generate one.")
        setTokenFound(false)
      }
    })
    .catch(err => {
      console.log("An error occurred while retrieving token. ", err)
    })
}

export const onMessageListener = () =>
  new Promise(resolve => {
    onMessage(messaging, payload => {
      resolve(payload)
    })
  })
