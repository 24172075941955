import { Row, Col, Modal, ModalBody, Container } from "reactstrap"
import React, { useEffect, useState, useMemo, useRef } from "react"
import PropTypes from "prop-types"
//i18n
import { useTranslation } from "react-i18next"
import { withTranslation } from "react-i18next"
import withRouter from "components/Common/withRouter"

//config
import { AG_GRID_KEY } from "../../../configs"

//Ag-grid
import { AgGridReact } from "ag-grid-react" // React Grid Logic
import "ag-grid-enterprise"
import { LicenseManager } from "ag-grid-enterprise"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-alpine.css"
import AgTextSpan from "../AgCellRendering/AgTextSpan"
import AgTextUrgent from "../AgCellRendering/AgTextUrgent"
import { getHeightModal } from "helpers/data_grid_helper"

LicenseManager.setLicenseKey(AG_GRID_KEY)

const ModalDetail = props => {
  let { item, isOpen, toggle } = props
  const { t } = useTranslation()
  const gridRef = useRef()
  const [heightTable, setHeightTable] = useState(500)

  /**Start ag grid config */
  const gridStyle = { minHeight: 750, marginTop: 10 }
  if (heightTable) {
    gridStyle.minHeight = "auto"
    gridStyle.height = heightTable + "px"
  }

  const updateModalHeight = () => {
    const height = getHeightModal(62)
    setHeightTable(height)
  }

  useEffect(() => {
    updateModalHeight()
  }, [])

  window.addEventListener("resize", function () {
    updateModalHeight()
  })
  const defaultColDef = useMemo(() => {
    return {
      // flex: 1,
      // minWidth: 150,
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }
    }
  }, [])
  const columnDefs = [
    {
      headerName: t("Date Created"),
      field: "createAt",
      // headerCheckboxSelection: true,
      // checkboxSelection: true,
      showDisabledCheckboxes: true,
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      resizable: true,
      sortable: true,
      pinned: "left"
    },
    {
      headerName: t("Customer Name"),
      field: "fullname",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
      sortable: true,
      pinned: "left"
    },
    {
      headerName: t("Passport number"),
      field: "personal_id",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true
    },
    {
      headerName: t("Value of visa"),
      field: "multipleEntry_title",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
      cellRendererSelector: params => {
        const text = {
          component: AgTextSpan
        }
        if (params.data) {
          return text
        }
      }
    },
    {
      headerName: t("Gender"),
      field: "sex",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
      sortable: true
    },
    {
      headerName: t("Date of birth"),
      field: "dob",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true
    },
    {
      headerName: t("Nationality"),
      field: "country",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true
    },
    {
      headerName: t("Urgent"),
      field: "urgent",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
      cellRendererSelector: params => {
        const text = {
          component: AgTextUrgent
        }
        if (params.data) {
          return text
        }
      }
    },
    {
      headerName: t("Customer segmentation"),
      field: "phantachkhachhang",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true
    },
    // {
    //   headerName: t("Issuance date"),
    //   field: "issuance_date",
    //   filter: "agTextColumnFilter",
    //   floatingFilter: true,
    //   resizable: true,
    // },
    // {
    //   headerName: t("Expiration Date"),
    //   field: "expridation_date",
    //   filter: "agTextColumnFilter",
    //   floatingFilter: true,
    //   resizable: true,
    // },
    {
      headerName: t("Number of days of stay"),
      field: "stayIn",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true
    },
    {
      headerName: t("Date of Entry"),
      field: "entry_date",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true
    },
    {
      headerName: t("Departure Date"),
      field: "departure_date",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true
    },
    // {
    //   headerName: t("How to get a visa"),
    //   field: "wayToGetVisa",
    //   filter: "agTextColumnFilter",
    //   floatingFilter: true,
    //   resizable: true,
    // },
    {
      headerName: t("Visa receiving place"),
      field: "addToGetVisa",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
      minWidth: 450
    },
    {
      headerName: t("Vip"),
      field: "vip",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true
    },
    {
      headerName: t("Staff"),
      field: "ma_nhan_vien",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true
    }
  ]

  /**End ag grid config */

  return (
    <React.Fragment>
      <Modal
        size="xl"
        isOpen={isOpen}
        toggle={toggle}
        className="modal-dialog modal-dialog-centered"
        fullscreen
        onOpened={() => {
          updateModalHeight()
        }}
      >
        <div className="modal-header js-height-modal">
          <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
            {props.t("Profile details")}: {item.so_ho_so}
          </h5>
          <button
            onClick={() => {
              toggle()
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>
          <Container className="body" fluid={true}>
            <Row className="js-height-modal">
              <div className="d-flex justify-content-between mb-3 mt-2">
                <div>
                  <h5>
                    <b>Trạng thái hồ sơ:</b>{" "}
                    <span
                      className={
                        item.trang_thai_ho_so === "Mới đăng ký"
                          ? "text-warning"
                          : item.trang_thai_ho_so === "Đang xử lý"
                            ? "text-danger"
                            : item.trang_thai_ho_so === "Đã trả kết quả"
                              ? "text-success"
                              : ""
                      }
                    >
                      {item.trang_thai_ho_so ? item.trang_thai_ho_so : ""}
                    </span>
                  </h5>
                </div>
                <div>
                  <h5>
                    <b>Ngày nộp:</b> {item.ngay_nop ? item.ngay_nop : ""}
                  </h5>
                </div>
                <div>
                  <h5>
                    <b>Ngày tiếp nhận:</b> {item.ngay_tiep_nhan ? item.ngay_tiep_nhan : ""}
                  </h5>
                </div>
                <div>
                  <h5>
                    <b>Công ty:</b> {item.ten_cong_ty ? item.ten_cong_ty : ""}
                  </h5>
                </div>
              </div>
            </Row>
            <div className="ag-theme-alpine" style={gridStyle}>
              <AgGridReact
                ref={gridRef}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                rowData={item && item.data ? item.data : []}
                rowSelection={"multiple"}
                suppressRowClickSelection={true}
                colResizeDefault={true}
                pagination={true}
                // onGridReady={onGridReady}
                onFirstDataRendered={params => {
                  // params.columnApi.autoSizeAllColumns()
                }}
                enableCellChangeFlash={true}
              />
            </div>
          </Container>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

ModalDetail.propTypes = {
  t: PropTypes.any
}

export default withRouter(withTranslation()(ModalDetail))
