import React, { forwardRef, useImperativeHandle, useState } from "react"
import { Button } from "reactstrap"
import { useTranslation } from "react-i18next"
import { GetPassportByMaHoSo } from "services"
import moment from "moment"
const ButtonRenderer = forwardRef((props, ref) => {
  const { t } = useTranslation()

  const [haveDetail, setHaveDetail] = useState(props.value)

  useImperativeHandle(ref, () => {
    return {
      refresh(params) {}
    }
  })
  return (
    <>
      {haveDetail && (
        <Button
          color={props.color}
          onClick={() => {
            GetPassportByMaHoSo(props.data.so_ho_so).then(res => {
              let data = res.data
              data.map((item, index) => {
                data[index].createAt = moment(item.createAt).format("DD/MM/YYYY HH:mm:ss")
                data[index].fullname =
                  data[index].fullname !== "" ? data[index].fullname : item.surname + " " + item.name
                data[index].sex = item.sex === true ? t("Male") : t("Female")
                data[index].urgent = item?.fee?.title_vn
                data[index].vip = item.vip === true ? t("Yes") : t("No")
                data[index].multipleEntry_title = Number(item.multipleEntry) === 1 ? "Nhiều lần" : "Một lần"
              })
              props.data.data = data
              props.setItemDetail(props.data)
              props.toggleDetail()
            })
          }}
        >
          {t("Detail")}
        </Button>
      )}
    </>
  )
})

ButtonRenderer.displayName = "MyImageRenderer"

export default ButtonRenderer
