export function getHeight(minus = 0, minHeight = 300) {
  const listHeight = document.querySelectorAll(".js-height")
  if (listHeight.length == 0) return minHeight
  let height = 0
  listHeight.forEach(item => {
    const rect = item.getBoundingClientRect()
    height += rect?.height || 0
  })

  const screenHeight = window.innerHeight
  height = screenHeight - height - minus
  return height < minHeight ? minHeight : height
}

export function getHeightModal(minus = 0, minHeight = 300) {
  const listHeight = document.querySelectorAll(".js-height-modal")
  if (listHeight.length == 0) return minHeight
  let height = 0
  listHeight.forEach(item => {
    const rect = item.getBoundingClientRect()
    height += rect?.height || 0
  })

  const screenHeight = window.innerHeight
  height = screenHeight - height - minus
  return height < minHeight ? minHeight : height
}
