export const sanbay = [
  {
    label: "SBQT Cam Ranh",
    value: "SBQT Cam Ranh"
  },
  {
    label: "SBQT Cần Thơ",
    value: "SBQT Cần Thơ"
  },
  {
    label: "SBQT Cát Bi",
    value: "SBQT Cát Bi"
  },
  {
    label: "SBQT Đà Nẵng",
    value: "SBQT Đà Nẵng"
  },
  {
    label: "SBQT Liên Khương",
    value: "SBQT Liên Khương"
  },
  {
    label: "SBQT Nội Bài",
    value: "SBQT Nội Bài"
  },
  {
    label: "SBQT Phú Bài",
    value: "SBQT Phú Bài"
  },
  {
    label: "SBQT Phú Quốc",
    value: "SBQT Phú Quốc"
  },
  {
    label: "SBQT Tân Sơn Nhất",
    value: "SBQT Tân Sơn Nhất"
  },
  {
    label: "SBQT Vân Đồn",
    value: "SBQT Vân Đồn"
  },
  {
    label: "SBQT Vinh",
    value: "SBQT Vinh"
  },
  {
    label: "SBQT Vũng Tàu",
    value: "SBQT Vũng Tàu"
  },
  {
    label: "Sân bay Đồng Hới",
    value: "Sân bay Đồng Hới"
  }
]
