import Breadcrumb from "components/Common/Breadcrumb"
import React, { useState, useMemo, useEffect, useRef, useContext } from "react"
import { Card, CardBody, Col, Container, Row } from "reactstrap"

import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import { AgGridReact } from "ag-grid-react" // React Grid Logic
import "ag-grid-enterprise"
import { LicenseManager } from "ag-grid-enterprise"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-alpine.css"

import { getHeight } from "helpers/data_grid_helper"

import { AG_GRID_KEY } from "configs"

//services
import { GetListUserLabel } from "services"

// Loading
import LoadingContext from "context/loading.context"

//set lic for ag-grid
LicenseManager.setLicenseKey(AG_GRID_KEY)

const Software = props => {
  const { t } = useTranslation()
  const [heightTable, setHeightTable] = useState(500)
  const [rowData, setRowData] = useState()
  const gridRef = useRef()
  const { setLoading } = useContext(LoadingContext)

  //ag-grid
  const defaultColDef = useMemo(() => {
    return {
      // flex: 1,
      // minWidth: 100,
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }
    }
  }, [])
  const columnDefs = [
    {
      headerName: t("Fullname"),
      field: "fullname",
      // headerCheckboxSelection: true,
      // checkboxSelection: true,
      showDisabledCheckboxes: true,
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      resizable: true,
      sortable: true,
      pinned: "left"
    },
    {
      headerName: t("Username"),
      field: "username",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
      sortable: true
    },
    {
      headerName: t("Email"),
      field: "email",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
      sortable: true
    },
    {
      headerName: t("Phone"),
      field: "phone",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
      sortable: true
    },
    {
      headerName: t("Nationality"),
      field: "country",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
      sortable: true
    }
  ]

  let gridStyle = { minHeight: 750, marginTop: 10 }

  if (heightTable) {
    gridStyle.minHeight = "auto"
    gridStyle.height = heightTable + "px"
  }

  useEffect(() => {
    const height = getHeight(62)
    setHeightTable(height)
  }, [])

  window.addEventListener("resize", function () {
    const height = getHeight(62)
    setHeightTable(height)
  })
  //ag-grid

  useEffect(() => {
    setLoading(true)
    GetListUserLabel()
      .then(res => {
        if (!res.data) return

        if (res.data.length > 100) {
          setLoading(false)
          setRowData(res.data)
        } else {
          setTimeout(() => {
            setLoading(false)
            setRowData(res.data)
          }, 500)
        }
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
      })
  }, [])

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agFilteredRowCountComponent", align: "left" },
        { statusPanel: "agAggregationComponent", align: "left" }
      ]
    }
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container className="body" fluid={true}>
          <Row>
            <Breadcrumb breadcrumbItem={t("Software Support - List partner")} title={t("Tool")} />
            {/* <Col lg={12}> */}
            <Col lg={12}>
              <Card className="js-height">
                <CardBody className="js-height">
                  <h3 className="mb-3">{t("Software Support")}</h3>
                  <Row>
                    <Col>
                      <h5>
                        {t("Download and Convert PDF CVT")} -{" "}
                        <Link
                          to="https://drive.google.com/drive/folders/16yDTDgIB1Lr5n18p_Z17c_TZEhd7Yg4m?usp=drive_link"
                          target="blank"
                        >
                          {t("Click here to download")}
                        </Link>{" "}
                        -{" "}
                        <Link
                          target="blank"
                          to="https://drive.google.com/drive/folders/1V4b--8jxbKyk-QvazZ7r64FyKZseFwxH?usp=drive_link"
                        >
                          {t("Tutorial")}
                        </Link>
                      </h5>
                    </Col>
                    <Col>
                      <h5>
                        {t("Push Profile DVC")} -{" "}
                        <Link
                          to="https://drive.google.com/drive/folders/1hozCP8z61lt0YAkYeeVb-kRMfKIqaZPq?usp=drive_link"
                          target="blank"
                        >
                          {t("Click here to download")}
                        </Link>{" "}
                        -{" "}
                        <Link
                          target="blank"
                          to="https://drive.google.com/drive/folders/1Ln87QDVOobiyOXkWvPj6EujlkLE9URB7?usp=drive_link"
                        >
                          {t("Tutorial")}
                        </Link>
                      </h5>
                    </Col>
                    <Col>
                      <h5>
                        {t("Purchase EVISA")} -{" "}
                        <Link
                          to="https://drive.google.com/drive/folders/1ftY1C23-YQ-i78t77F1ZrzzGs_07C6m7?usp=drive_link"
                          target="blank"
                        >
                          {t("Click here to download")}
                        </Link>{" "}
                        -{" "}
                        <Link
                          target="blank"
                          to="https://drive.google.com/drive/folders/10JprhOPgl1H4hiivMm-EWYf_x9eQ2Szq?usp=drive_link"
                        >
                          {t("Tutorial")}
                        </Link>
                      </h5>
                    </Col>
                    <Col>
                      <h5>
                        {t("Download and rename EVISA")} -{" "}
                        <Link
                          to="https://drive.google.com/drive/folders/1yk9kRnq1tS6DXLgNwi2Hg7XYUC1pmt89?usp=drive_link"
                          target="blank"
                        >
                          {t("Click here to download")}
                        </Link>{" "}
                        -{" "}
                        <Link
                          target="blank"
                          to="https://drive.google.com/drive/folders/1KErgUMcgiYkNCx1gYK6svi1nmR6lWbZt?usp=drive_link"
                        >
                          {t("Tutorial")}
                        </Link>
                      </h5>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg={12} className="mt-2">
              <Card>
                <CardBody>
                  <h3>{t("List partner")}</h3>
                  <div className="ag-theme-alpine" style={gridStyle}>
                    <AgGridReact
                      ref={gridRef}
                      rowData={rowData}
                      columnDefs={columnDefs}
                      defaultColDef={defaultColDef}
                      rowSelection={"multiple"}
                      suppressRowClickSelection={true}
                      colResizeDefault={true}
                      // isRowSelectable={isRowSelectable}
                      // onGridReady={onGridReady}
                      enableRangeSelection={true}
                      onFirstDataRendered={params => {
                        params.columnApi.autoSizeAllColumns()
                      }}
                      pagination={true}
                      statusBar={statusBar}
                      // Hidden loading overlay
                      overlayLoadingTemplate="<span></span>"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            {/* </Col> */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Software
