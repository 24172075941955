// import { Link } from "react-router-dom"
import React, { useEffect, useState, useRef, useCallback } from "react"

import ReactDataGrid from "@inovua/reactdatagrid-enterprise"
import "@inovua/reactdatagrid-enterprise/index.css"
import SelectFilter from "@inovua/reactdatagrid-enterprise/SelectFilter"

import { DATA_GRID_KEY } from "../../configs"
//services
import { GetNhanvienByDoitac, CreateNhanVien } from "../../services/"

//import react-select
import Select from "react-select"

//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Alert,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  FormFeedback
} from "reactstrap"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useTranslation } from "react-i18next"

// Get value ID item
const toArray = (selected, dataMap) => {
  const keysObj = selected === true ? dataMap : selected
  return Object.values(keysObj).map(key => Number(key.id))
}

const SentList = () => {
  //meta title
  document.title = "VNPassport | Sent List"
  const [dataSource, setData] = useState([])
  const [selected, setSelected] = useState({})
  const gridRef = useRef(null)

  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [message, setMessage] = useState("")
  const { t } = useTranslation()

  const optionGender = [
    { value: true, label: t("Male") },
    { value: false, label: t("Female") }
  ]

  // Geader Colums table
  const columns = [
    {
      header: t("Fullname"),
      name: "fullname",
      defaultWidth: 135,
      textAlign: "center"
    },
    {
      header: t("Code"),
      name: "code",
      defaultWidth: 135,
      textAlign: "center"
    },
    {
      header: t("Gender"),
      name: "gender",
      defaultWidth: 135,
      textAlign: "center",
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: t("All"),
        dataSource: [
          { id: "true", label: t("Male") },
          { id: "false", label: t("Female") }
        ]
      },
      render: ({ value }) => value
    },
    {
      header: t("Date of birth"),
      name: "dob",
      defaultWidth: 135,
      textAlign: "center"
    },
    {
      header: t("Phone"),
      name: "phone",
      defaultWidth: 135,
      textAlign: "center"
    },
    {
      header: t("Email"),
      name: "email",
      defaultWidth: 250,
      textAlign: "center"
    }
  ]

  // Style
  const gridStyle = { minHeight: 750, marginTop: 10 }

  // Value table
  const filterValue = [
    { name: "createAt", operator: "contains", type: "string", value: "" },
    { name: "name", operator: "contains", type: "string", value: "" },
    { name: "dob", operator: "contains", type: "string", value: "" },
    { name: "gender", operator: "inlist", type: "select", value: "" },
    { name: "country", operator: "contains", type: "string", value: "" },
    { name: "personal_id", operator: "contains", type: "string", value: "" },
    { name: "mahoso", operator: "contains", type: "string", value: "" },
    { name: "issuance_date", operator: "contains", type: "string", value: "" },
    {
      name: "expridation_date",
      operator: "contains",
      type: "string",
      value: ""
    },
    { name: "entry_date", operator: "contains", type: "string", value: "" },
    { name: "departure_date", operator: "contains", type: "string", value: "" },
    { name: "wayToGetVisa", operator: "inlist", type: "select", value: "" },
    { name: "addToGetVisa", operator: "contains", type: "string", value: "" },
    { name: "urgent", operator: "contains", type: "string", value: "" },
    {
      name: "phantachkhachhang",
      operator: "contains",
      type: "string",
      value: ""
    }
  ]

  const onSelectionChange = useCallback(({ selected, data }) => {
    // console.log(selected, data)
    if (selected === true && Array.isArray(data)) {
      if (data.length === dataSource.length) {
        setSelected(data)
      } else {
        console.log(selected)
      }
      setSelected(data)
    } else {
      setSelected(selected)
    }
  }, [])

  // Get dataMap of Reactdatagrid
  const dataMap = gridRef && gridRef.current && gridRef.current.dataMap ? gridRef.current.dataMap : null

  useEffect(
    () => {
      setIsError(false)
      setIsSuccess(false)
      setMessage("")
      GetNhanvienByDoitac().then(res => {
        let response = res.data
        let temp = []
        response.map((item, index) => {
          temp.push({
            _id: item._id,
            code: item.code,
            email: item.email,
            phone: item.phone,
            address: item.address,
            gender: item.gender && item.gender === true ? t("Male") : t("Female"),
            dob: item.dob,
            password: item.password,
            fullname: item.fullname,
            commission: item.commission
          })
        })
        setData(temp)
      })
    },
    [isSuccess],
    [t]
  )

  const validationSubmit = useFormik({
    initialValues: {
      code: "",
      fullname: "",
      gender: true,
      dob: "",
      phone: "",
      email: "",
      address: "",
      commission: 1000
    },
    validationSchema: Yup.object({
      code: Yup.string().required(),
      fullname: Yup.string().required(),
      phone: Yup.string()
        .required()
        .test("check_phone", value => {
          if (!value) return true
          let regex = /^0\d{9}$/
          return value.match(regex)
        }),
      email: Yup.string().email(),
      commission: Yup.number().min(1000).required()
    }),
    onSubmit: values => {
      CreateNhanVien(values)
        .then(result => {
          setIsSuccess(true)
          validationSubmit.resetForm()
        })
        .catch(err => {
          console.error(err?.response?.data)
          setIsError(true)
          setMessage(err?.response?.data?.msg)
        })
    }
  })

  // handle change Filter
  const onFilterValueChange = useCallback(() => {
    setSelected({})
    // handle clear checkbox
    if (gridRef && gridRef.current) {
      gridRef.current.deselectAll()
    }
  }, [])

  return (
    <>
      <div className="page-content">
        <Container className="body" fluid={true}>
          <Breadcrumbs title={t("Staff")} breadcrumbItem={t("Staff List")} />
          <Row>
            <Col lg={4}>
              <Card className="d-flex">
                <CardBody>
                  <CardTitle className="mb-3">1. {t("Create new worker")}</CardTitle>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validationSubmit.handleSubmit()
                      return false
                    }}
                  >
                    {isError ? <Alert color="danger">{message}</Alert> : null}
                    <FormGroup className="mb-4" row>
                      <div className="col-sm-6">
                        <Label htmlFor="code" className="">
                          {t("Code")} <span className="text-danger">*</span>
                        </Label>
                        <Col>
                          <Input
                            id="code"
                            name="code"
                            type="text"
                            value={validationSubmit.values.code}
                            onChange={validationSubmit.handleChange}
                            onBlur={validationSubmit.handleBlur}
                            invalid={validationSubmit.touched.code && validationSubmit.errors.code ? true : false}
                            placeholder="Code"
                          />
                          {validationSubmit.touched.code && validationSubmit.errors.code ? (
                            <FormFeedback type="invalid">{validationSubmit.errors.code}</FormFeedback>
                          ) : (
                            ""
                          )}
                        </Col>
                      </div>
                      <div className="col-sm-6">
                        <Label htmlFor="fullname" className="">
                          {t("Fullname")} <span className="text-danger">*</span>
                        </Label>
                        <Col>
                          <Input
                            id="fullname"
                            name="fullname"
                            type="text"
                            placeholder="VD. Vu Hoang Linh"
                            onChange={validationSubmit.handleChange}
                            onBlur={validationSubmit.handleBlur}
                            invalid={
                              validationSubmit.touched.fullname && validationSubmit.errors.fullname ? true : false
                            }
                          />
                          {validationSubmit.touched.fullname && validationSubmit.errors.fullname ? (
                            <FormFeedback type="invalid">{validationSubmit.errors.fullname}</FormFeedback>
                          ) : (
                            ""
                          )}
                        </Col>
                      </div>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <div className="col-sm-6">
                        <Label htmlFor="gender" className="">
                          {t("Gender")}
                        </Label>
                        <Col>
                          <Select
                            id="gender"
                            name="gender"
                            onBlur={validationSubmit.handleBlur}
                            onChange={e => {
                              validationSubmit.setFieldValue("gender", e.value)
                            }}
                            defaultValue={optionGender[0]}
                            options={optionGender}
                            placeholder={t("Select a gender")}
                          />
                        </Col>
                      </div>
                      <div className="col-sm-6">
                        <Label htmlFor="dob" className="">
                          {t("Date of birth")}
                        </Label>
                        <Col>
                          <DatePicker
                            id="dob"
                            name="dob"
                            dateFormat="dd/MM/yyyy"
                            placeholderText="dd/mm/yyyy"
                            className={`form-control ${
                              validationSubmit.touched.dob && validationSubmit.errors.dob ? "border-danger" : ""
                            }`}
                            selected={(validationSubmit.values.dob && new Date(validationSubmit.values.dob)) || ""}
                            onChange={val => {
                              validationSubmit.setFieldValue("dob", val === null ? "" : val)
                            }}
                            onBlur={validationSubmit.handleBlur}
                          />
                          {validationSubmit.touched.dob && validationSubmit.errors.dob ? (
                            <FormFeedback type="invalid" style={{ display: "block" }}>
                              {validationSubmit.errors.dob}
                            </FormFeedback>
                          ) : (
                            ""
                          )}
                        </Col>
                      </div>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <div className="col-sm-6">
                        <Label htmlFor="phone" className="">
                          {t("Phone")} <span className="text-danger">*</span>
                        </Label>
                        <Col>
                          <Input
                            id="phone"
                            name="phone"
                            type="text"
                            placeholder="VD. 0345291510"
                            value={validationSubmit.values.phone}
                            onChange={validationSubmit.handleChange}
                            onBlur={validationSubmit.handleBlur}
                            invalid={validationSubmit.errors.phone && validationSubmit.touched.phone ? true : false}
                          />
                          {validationSubmit.touched.phone && validationSubmit.errors.phone ? (
                            <FormFeedback type="invalid" style={{ display: "block" }}>
                              {validationSubmit.errors.phone}
                            </FormFeedback>
                          ) : (
                            ""
                          )}
                        </Col>
                      </div>
                      <div className="col-sm-6">
                        <Label htmlFor="email" className="">
                          {t("Email")}
                        </Label>
                        <Col>
                          <Input
                            id="email"
                            name="email"
                            placeholder="VD. contact@visavietnam.biz"
                            value={validationSubmit.values.email}
                            onChange={validationSubmit.handleChange}
                            onBlur={validationSubmit.handleBlur}
                            invalid={validationSubmit.errors.email && validationSubmit.touched.email ? true : false}
                          />
                          {validationSubmit.touched.email && validationSubmit.errors.email ? (
                            <FormFeedback type="invalid">{validationSubmit.errors.email}</FormFeedback>
                          ) : (
                            ""
                          )}
                        </Col>
                      </div>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <div className="col-sm-6">
                        <Label htmlFor="address" className="">
                          {t("Address")}
                        </Label>
                        <Col>
                          <Input
                            id="address"
                            name="address"
                            type="text"
                            placeholder="90 Nguyen Tuan"
                            onChange={validationSubmit.handleChange}
                            onBlur={validationSubmit.handleBlur}
                            value={validationSubmit.values.address}
                          />
                        </Col>
                      </div>
                      <div className="col-sm-6">
                        <Label htmlFor="commission" className="">
                          {t("Commission")} <span className="text-danger">*</span>
                        </Label>
                        <Col>
                          <Input
                            id="commission"
                            name="commission"
                            type="number"
                            placeholder=""
                            step={1000}
                            value={validationSubmit.values.commission}
                            onChange={validationSubmit.handleChange}
                            onBlur={validationSubmit.handleBlur}
                            invalid={
                              validationSubmit.errors.commission && validationSubmit.touched.commission ? true : false
                            }
                          />
                          {validationSubmit.touched.commission && validationSubmit.errors.commission ? (
                            <FormFeedback type="invalid">{validationSubmit.errors.commission}</FormFeedback>
                          ) : (
                            ""
                          )}
                        </Col>
                      </div>
                    </FormGroup>

                    <Row className="justify-content-end mt-4">
                      <Button className="w-auto d-block mx-auto" type="submit" color="dark">
                        {t("Create")}
                      </Button>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col lg={8}>
              <div>
                {/* <Button onClick={exportCsv}>Export csv</Button> */}
                <ReactDataGrid
                  licenseKey={DATA_GRID_KEY}
                  idProperty="id"
                  style={gridStyle}
                  columns={columns}
                  dataSource={dataSource}
                  pagination
                  defaultFilterValue={filterValue}
                  editable={true}
                  // checkboxColumn={checkboxColumn}
                  // checkboxColumn
                  onSelectionChange={onSelectionChange}
                  onFilterValueChange={onFilterValueChange}
                  // selected={selected}
                  handle={ref => (gridRef.current = ref ? ref.current : null)}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

SentList.propTypes = {
  t: PropTypes.any
}

export default withRouter(withTranslation()(SentList))
