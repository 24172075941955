import axios from "axios"
import { API_URL } from "../../configs/"
import { STORAGE_KEY } from "../../configs/app.const"
import Cookies from "universal-cookie"
import { jwtDecode } from "jwt-decode"

const cookies = new Cookies()

const isTokenExpired = token => {
  const decodedToken = jwtDecode(token)
  const now = Date.now()
  const expTime = decodedToken.exp * 1000 - 60000 // giam exp so voi thuc te 60s
  return now > expTime ? true : false
}

const axiosInstanceRefreshToken = axios.create({
  baseURL: API_URL,
  headers: {
    "content-type": "application/x-www-form-urlencoded"
  }
})

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "content-type": "application/x-www-form-urlencoded"
  }
})

axiosInstance.interceptors.request.use(
  async config => {
    const accessToken = cookies.get(STORAGE_KEY.auth)
    const refreshToken = cookies.get(STORAGE_KEY.refresh_token)
    const ma_nhan_vien = cookies.get(STORAGE_KEY.code)
    const madoitac = cookies.get(STORAGE_KEY.madoitac)
    const ho_va_ten = cookies.get(STORAGE_KEY.ho_va_ten)
    if (accessToken && isTokenExpired(accessToken) && refreshToken) {
      console.log("Refresh token")
      const response = await axiosInstanceRefreshToken.post("/auth/refresh-token", { refreshToken })
      const { token } = response.data
      cookies.set(STORAGE_KEY.auth, token, { path: "/", maxAge: STORAGE_KEY_TIMEOUT.user })
      cookies.set(STORAGE_KEY.code, ma_nhan_vien, { path: "/", maxAge: STORAGE_KEY_TIMEOUT.user })
      cookies.set(STORAGE_KEY.madoitac, madoitac, { path: "/", maxAge: STORAGE_KEY_TIMEOUT.user })
      cookies.set(STORAGE_KEY.ho_va_ten, ho_va_ten, { path: "/", maxAge: STORAGE_KEY_TIMEOUT.user })
      config.headers["Authorization"] = `Bearer ${token}`
    } else if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  res => {
    return res
  },
  async error => {
    console.log(error)
    if (error.response && error.response.status === 401) {
      if (window.location.pathname !== "/login") {
        LogOut()
        window.location.href = "/login"
      }
    }
    return Promise.reject(error)
  }
)

export default axiosInstance
